/**
 * Created by kimchangduk on 2017-04-21.
 */

import React from "react";
import { createBrowserHistory } from "history";
import { Urls, Consts } from "./constants";
import { setAppHistory } from "./Utils";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignUpPage from "./containers/SignUpPage";
import LoginPage from "./containers/LoginPage";
import ApiPage from "./containers/ApiPage";
import ProfilePage from "./containers/ProfilePage";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import ThemeDefault from "./theme-default";
import MobileLogin from "./containers/v1/MobileLogin";
import HelpPage from "./containers/HelpPage";

import App from "./containers/App";
import OAuthCallbackPage from "./containers/OAuthCallbackPage";

import EmailValidationPage from "./containers/user/EmailValidationPage";
import RedirectUrl from "./containers/RedirectUrl";
import SearchResultPage from "./containers/SearchResultPage";
import MainPage from "./containers/MainPage";
import WaitAuthChange from "./containers/WaitAuthChange";
import SettingTabPage from "./containers/SettingTabPage";
import KakaoChat from "./containers/KakaoChat";
import ProjectFeedTimelinePage from "./containers/ProjectFeedTimelinePage";
import MobileSubscribesPage from "./containers/MobileSubscribesPage";
import DiscoverPage from "./containers/DiscoverPage";
import SubscriptionSearchResultPage from "./containers/SubscriptionSearchResultPage";
import ProjectFeedPage from "./containers/ProjectFeedPage";
import NotificationFeeds from "./containers/NotificationFeeds";
import RssBuildFeedPage from "./containers/RssBuildFeedPage";
import ContactPage from "./containers/ContactPage";
import RssListPage from "./containers/RssListPage";
import RssBuilderWithXPathPage from "./containers/RssBuilderWithXPathPage";
import { HistoryRouter } from "./HistoryRouter";

export default (
  <HistoryRouter>
    <MuiThemeProvider muiTheme={ThemeDefault}>
      <App>
        <Routes>
          {/*for mobile app*/}
          <Route path={Urls.SETTING} exact={true} element={<SettingTabPage />} />
          <Route path={Urls.MOBILE_LOGIN} exact element={<MobileLogin />} />
          <Route path={Urls.MOBILE_SUBSCRIBE_LIST} exact element={<MobileSubscribesPage />} />

          <Route path={Urls.WAIT_AUTH_CHANGE} exact={true} element={<WaitAuthChange />} />
          <Route path={Urls.LOGIN} exact={true} element={<LoginPage />} />
          <Route path={Urls.SIGNUP} exact={true} element={<SignUpPage />} />
          <Route path="/user/:username/validate" exact={true} element={<EmailValidationPage />} />
          <Route path="/oauth2/:id" element={<OAuthCallbackPage />} />

          <Route path="/" exact={true} element={<MainPage />} />
          <Route path="/search/:query" exact={true} element={<SearchResultPage />} />

          {/*for timeline item link*/}
          <Route path="/link" exact={true} element={<RedirectUrl />} />

          <Route path="/profile" exact={true} element={<ProfilePage />} />
          <Route path="/kakaoChat" exact={true} element={<KakaoChat />} />
          <Route path="/contact" exact={true} element={<ContactPage />} />
          <Route path="/help" exact={true} element={<HelpPage />} />
          <Route path="/api" exact={true} element={<ApiPage />} />
          <Route path={Urls.DISCOVER} exact element={<DiscoverPage />} />
          <Route path="/rss/build" element={<RssBuilderWithXPathPage />} />
          <Route path="/rss/build_old" element={<RssBuildFeedPage />} />
          <Route path="/rss/:uuid/edit" element={<RssBuilderWithXPathPage editMode={true} />} />
          <Route path="/rss/list" element={<RssListPage />} />

          <Route path="/:urlKey/feeds/:feeds" exact={true} element={<NotificationFeeds />} />
          <Route path="/subscription/search/:query" exact={true} element={<SubscriptionSearchResultPage />} />
          <Route path="/:urlKey/search/:searchWord" element={<ProjectFeedTimelinePage />} />
          <Route path="/:urlKey/feed/:id" element={<ProjectFeedPage />} />
          <Route path="/:urlKey" element={<ProjectFeedTimelinePage />} />
        </Routes>
      </App>
    </MuiThemeProvider>
  </HistoryRouter>
);
