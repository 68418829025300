/**
 * Created by kimchangduk on 2017-04-30.
 */

import {ActionTypes} from '../constants';

export function showSnackbar(message, autoHideDuration = undefined) {
    return (dispatch) => {
        return dispatch({type: ActionTypes.SNACKBAR_OEPN, message, autoHideDuration});
    };
}

export function hideSnackbar() {
    return (dispatch) => {
        return dispatch({type: ActionTypes.SNACKBAR_CLOSE});
    };
}