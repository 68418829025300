import React, { Component } from "react";
import { connect } from "react-redux";
import { showSnackbar } from "../actions/globalSnackbar";
import { Strings, Urls } from "../constants";
import RssBuilderIframe from "../components/RssBuilderIframe";
import withRouter, { withRouterProps } from "../withRouter";
import Loader from "../components/Loader";
import { fetchRss, Generator } from "../Api";
import { FAILED_TO_FETCH_RSS } from "../constants/Strings";

interface Props {
  edit?: boolean;
  actions: any;
  editMode: boolean;
  match: any;
}
interface State {
  urlInput: string;
  iframeUrl: string;
  step2Enabled: boolean;
  waiting: boolean;
  generator?: Generator;
}

class RssBuilderWithXPathPage extends Component<withRouterProps<Props>, State> {
  static DispatchToProps: any = (dispatch: any, ownProps: any) => {
    return {
      actions: {
        alert: (message: string) => {
          dispatch(showSnackbar(message));
        },
      },
    };
  };

  static defaultProps = {
    edit: true,
  };

  constructor(props: withRouterProps<Props>) {
    super(props);
    if (props.editMode) {
      this.state = {
        urlInput: "",
        iframeUrl: "",
        step2Enabled: false,
        waiting: true,
      };
    } else {
      this.state = {
        urlInput: "",
        iframeUrl: "",
        step2Enabled: false,
        waiting: false,
      };
    }
  }

  componentDidMount() {
    if (this.props.editMode) {
      this.fetchEditRss();
    }
  }

  fetchEditRss = async () => {
    const uuid = this.props.params?.uuid;
    if (!uuid) {
      window.alert(FAILED_TO_FETCH_RSS);
      this.props.navigate(-1);
      return;
    }
    let generator: Generator;
    try {
      const response = await fetchRss(uuid);
      generator = response.data;
    } catch (error) {
      console.error(error);
      window.alert(FAILED_TO_FETCH_RSS);
      this.props.navigate(-1);
      return;
    }

    this.setState({
      waiting: false,
      urlInput: generator.url,
      iframeUrl: generator.url,
      step2Enabled: true,
      generator,
    });
  };

  onHtmlLoadUrlClick = () => {
    const { urlInput: url } = this.state;
    this.setState({
      step2Enabled: false,
    });
    window.setTimeout(() => {
      this.setState({
        iframeUrl: url,
        step2Enabled: true,
      });
    });
  };

  Step2 = () => {
    return (
      <div className="step step2">
        <h3>반복아이템 추출</h3>
        <RssBuilderIframe
          editMode={this.props.editMode}
          iframeUrl={this.state.iframeUrl}
          iframeStyle={{ height: 500, width: "100%" }}
          onRegisterSuccess={() => {
            this.props.navigate(Urls.RSS_LIST);
          }}
          generator={this.state.generator}
        />
      </div>
    );
  };

  render() {
    if (this.state.waiting) {
      return (
        <Loader
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        />
      );
    }

    return (
      <div style={styles.container} className="default-page build-rss-page">
        <div className="step step1">
          <h3>{Strings.STEP1_TITLE}</h3>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.onHtmlLoadUrlClick();
            }}
          >
            <div className="input-label">Site URL:</div>
            <div className="input-div">
              <input
                value={this.state.urlInput}
                onChange={(e) => {
                  this.setState({ urlInput: e.target.value });
                }}
                name="urlInput"
                placeholder="https://www.reddit.com/"
              />
            </div>
            <button type="submit" className={this.state.urlInput ? "active" : ""} disabled={!this.state.urlInput}>
              {Strings.LOAD_URL}
            </button>
          </form>
        </div>
        {this.state.step2Enabled && <this.Step2 />}
      </div>
    );
  }
}

const styles = {
  container: {},
  editorStyle: {
    width: "100%",
  },
  marginRight25: {
    marginRight: 25,
  },
  buttonProgress: {
    verticalAlign: "middle",
    marginRight: 5,
    top: -3,
  },
};

export default connect(null, RssBuilderWithXPathPage.DispatchToProps)(withRouter(RssBuilderWithXPathPage));
