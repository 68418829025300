/**
 * Created by kimchangduk on 2018-01-13.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  get: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
      urlKey: "",
      requestPos: null,
      nextPos: null,
    },
    dataSource: null,
  },
  subscribe: {
    state: {
      request: Consts.REQUEST_NONE,
      id: "",
      errorMessage: "",
    },
  },
  unsubscribe: {
    state: {
      request: Consts.REQUEST_NONE,
      id: "",
      errorMessage: "",
    },
  },
  rank: {
    state: {
      request: Consts.REQUEST_NONE,
    },
    dataSource: null,
  },
};

export default function keywordReducer(state = initialState, action) {
  switch (action.type) {
    //<editor-fold desc="keyword search">
    case ActionTypes.GET_KEYWORD_SEARCH_REQUEST: {
      const getUpdateValue = {
        state: {
          request: { $set: Consts.REQUEST_WAITING },
          requestPos: { $set: action.pos },
          urlKey: { $set: action.urlKey },
        },
      };

      if (action.pos === null) {
        getUpdateValue.dataSource = { $set: null };
      }
      return update(state, { get: getUpdateValue });
    }
    case ActionTypes.GET_KEYWORD_SEARCH_SUCCESS: {
      if (action.urlKey !== state.get.state.urlKey || action.pos !== state.get.state.requestPos) break;

      const data = action.data;
      const getUpdateValue = {
        state: {
          request: { $set: Consts.REQUEST_SUCCESS },
        },
      };

      if (data) {
        if (data.data && data.data.length > 0) {
          getUpdateValue.state.nextPos = { $set: data.data[data.data.length - 1].result.id };
        } else {
          getUpdateValue.state.nextPos = { $set: null };
        }

        if (action.pos && state.get.dataSource && state.get.dataSource.data) {
          // 더불러오기 실행시
          getUpdateValue.dataSource = {
            data: { $push: data.data },
          };
        } else {
          // 검색 첫번째 페이지
          getUpdateValue.dataSource = { $set: data };
        }
      }
      return update(state, { get: getUpdateValue });
    }
    case ActionTypes.GET_KEYWORD_SEARCH_FAILURE:
      if (action.urlKey !== state.get.state.urlKey || action.pos !== state.get.state.requestPos) break;

      return update(state, {
        get: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="keyword rank">
    case ActionTypes.GET_KEYWORD_RANK_REQUEST:
      return update(state, { rank: { state: { request: { $set: Consts.REQUEST_WAITING } } } });
    case ActionTypes.GET_KEYWORD_RANK_SUCCESS:
      return update(state, { rank: { state: { request: { $set: Consts.REQUEST_SUCCESS } }, dataSource: { $set: action.data } } });
    case ActionTypes.GET_KEYWORD_RANK_FAILURE:
      return update(state, { rank: { state: { request: { $set: Consts.REQUEST_FAILURE } } } });
    //</editor-fold>
  }
  return state;
}
