/**
 * Created by kimchangduk on 2018-02-24.
 */

import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Consts, Strings} from '../constants';
import FeedItem from "./FeedItem";
import './FeedItemList.scss';
import Cookies from 'js-cookie';
import {setFeedLayerFeeds} from "../actions/feedContentLayer";
import {getAppHistory} from "../Utils";

class FeedItemList extends React.Component {
    static StateToProps = (state) => {
        return {closeWaiting: state.feedContentLayer.closeWaiting};
    };

    static DispatchToProps = (dispatch) => {
        return {
            actions: {
                setFeedLayerFeeds: (data, keepCurrent) => {
                    dispatch(setFeedLayerFeeds(data, keepCurrent));
                }
            }
        };
    };

    static propTypes = {
        actions: PropTypes.object,
        feeds: PropTypes.array,
        style: PropTypes.object,
        viewMode: PropTypes.string,
        showViewModeSelector: PropTypes.bool,
        onViewModeChangeRequest: PropTypes.func,
        closeWaiting: PropTypes.bool,
    };

    static defaultProps = {
        viewMode: undefined,
        showViewModeSelector: true,
        onViewModeChangeRequest: undefined
    };

    constructor(props) {
        super(props);
        this.state = {
            viewMode: Cookies.get(Consts.COOKIE_KEY_FEED_ITEM_VIEW_TYPE) ? Cookies.get(Consts.COOKIE_KEY_FEED_ITEM_VIEW_TYPE) : Consts.FeedItemViewTypes.THUMBNAIL
        };
    }

    componentWillReceiveProps(nextProps) {
        if (getAppHistory().location.state && getAppHistory().location.state.feedLayerOpen &&
            this.props.feeds !== nextProps.feeds && !nextProps.closeWaiting) {

            this.props.actions.setFeedLayerFeeds(nextProps.feeds, true);
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.viewMode !== this.props.viewMode && nextProps.viewMode !== undefined) {
            Cookies.set(Consts.COOKIE_KEY_FEED_ITEM_VIEW_TYPE, nextProps.viewMode);
        } else if (nextState.viewMode !== this.state.viewMode) {
            Cookies.set(Consts.COOKIE_KEY_FEED_ITEM_VIEW_TYPE, nextState.viewMode);
        }
    }

    setViewMode = (mode) => {
        if (this.props.viewMode === undefined && this.props.onViewModeChangeRequest === undefined) {
            if (this.state.viewMode === Consts.FeedItemViewTypes.THUMBNAIL) {
                this.setState({viewMode: Consts.FeedItemViewTypes.TITLE_ONLY});
            } else {
                this.setState({viewMode: Consts.FeedItemViewTypes.THUMBNAIL});
            }
        }

        if (this.props.onViewModeChangeRequest)
            this.props.onViewModeChangeRequest(mode);
    };

    render() {
        const viewMode = this.props.viewMode === undefined && this.props.onViewModeChangeRequest === undefined ? this.state.viewMode : this.props.viewMode;
        return (<div style={this.props.style}>
            {
                this.props.showViewModeSelector ?
                    <div>
                        <div style={styles.buttons}>
                            <button className={`margin-right-5${viewMode === Consts.FeedItemViewTypes.THUMBNAIL ? ' primary' : ''}`} onClick={() => {
                                this.setViewMode(Consts.FeedItemViewTypes.THUMBNAIL);
                            }}>
                                {Strings.THUMBNAIL}
                            </button>
                            <button className={`${viewMode === Consts.FeedItemViewTypes.TITLE_ONLY ? ' primary' : ''}`} onClick={() => {
                                this.setViewMode(Consts.FeedItemViewTypes.TITLE_ONLY);
                            }}>
                                {Strings.TEXT}
                            </button>
                        </div>
                    </div> : undefined
            }
            <div className="margin-top-10">
                {
                    this.props.feeds ? this.props.feeds.map((item, key) => {
                        return <FeedItem dataSource={item} key={key} viewMode={viewMode} feedLayerDataSource={this.props.feeds}/>;
                    }) : undefined
                }
            </div>
        </div>);
    }
}

const styles = {
    marginTop: 10,
    buttons: {
        textAlign: 'right'
    }
};

export default connect(FeedItemList.StateToProps, FeedItemList.DispatchToProps)(FeedItemList);