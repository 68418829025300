import React from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { ActionTypes, Consts, Strings, Urls } from "../constants";
import RssFieldSelector from "../components/RssFieldSelector";
import axios from "axios";
import "./RssBuildFeedPage.scss";
import AceEditor from "react-ace";
import "brace/mode/html";
import "brace/theme/github";
import "brace/snippets/javascript";
import { showSnackbar } from "../actions/globalSnackbar";
import queryString from "querystring";
import { getAppHistory, getMessageFromResponse } from "../Utils";
import { CircularProgress } from "material-ui";
import LoaderPage from "./LoaderPage";

class RssBuildFeedPage extends React.Component {
  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        alert: (message) => {
          dispatch(showSnackbar(message));
        },
      },
    };
  };

  static propTypes = {
    actions: PropTypes.object,
    editMode: PropTypes.bool,
    match: PropTypes.object,
  };

  // eslint-disable-next-line react/sort-comp
  state = {
    urlInput: "",
    urlInputRequest: "",
    loadedHtml: "",
    ruleInput: "",
    ruleInputRequiredError: false,
    parseResult: null,
    rssTitle: "",
    rssDescription: "",
    rssTitleRequiredError: false,
    rssDescriptionRequiredError: false,
    rssFields: {
      [rssFieldKeys.title]: null,
      [rssFieldKeys.link]: null,
      [rssFieldKeys.thumbnail]: null,
      [rssFieldKeys.content]: null,
    },
    rssTitleFieldReuiredError: false,
    rssLinkFieldReuiredError: false,
    loadedHtmlWaiting: false,
    rssGenLoadWaiting: false,
    extractRegexWaiting: false,
    openNotihubWaiting: false,
    saveWaiting: false,
    rssUrl: null,
  };

  constructor(props) {
    super(props);
    const history = getAppHistory();
    if (history && history.location && history.location.state && history.location.state.url) {
      this.state.urlInput = history.location.state.url;
    }
  }

  componentDidMount() {
    if (this.props.editMode) {
      this.loadRssGenerator(this.getUuid());
    }
  }

  onLoadUrlClick = () => {
    if (this.state.loadedHtmlWaiting) {
      return;
    }
    this.loadUrlHTML();
  };

  loadUrlHTML = (url) => {
    if (url === undefined) {
      url = this.state.urlInput;
    }
    this.setState({ urlInputRequest: url, loadedHtml: "", loadedHtmlWaiting: true });
    axios
      .get("/v1/rssgen/html", { params: { url } })
      .then((response) => {
        this.setState({ loadedHtml: response.data, loadedHtmlWaiting: false });
        if (this.state.ruleInput) {
          this.extractRegex(this.state.ruleInput);
        }
      })
      .catch((error) => {
        alert(getMessageFromResponse(error.response, Strings.FAILED_TO_LOAD_HTML));
        this.setState({ loadedHtmlWaiting: false });
      });
  };

  onInputChange = (e) => {
    const updateValue = { [e.target.name]: e.target.value };

    switch (e.target.name) {
      case "rssTitle":
        updateValue.rssTitleRequiredError = false;
        break;
      case "rssDescription":
        updateValue.rssDescriptionRequiredError = false;
        break;
      case "ruleInput": {
        updateValue.ruleInputRequiredError = false;
        const parameterLength = this.getParameterLength(e.target.value);
        const rssFieldUpdate = {};
        for (let key of Object.keys(rssFieldKeys)) {
          if (this.state.rssFields[key] !== null && this.state.rssFields[key] !== undefined && this.state.rssFields[key] >= parameterLength) {
            rssFieldUpdate[key] = { $set: null };
          }
        }
        updateValue.rssFields = update(this.state.rssFields, rssFieldUpdate);
        break;
      }
    }

    this.setState(updateValue);
  };

  onExtract = () => {
    this.extractRegex(this.state.ruleInput);
  };

  extractRegex = (rule) => {
    if (this.state.extractRegexWaiting) {
      return;
    }

    this.setState({ extractRegexWaiting: true });
    axios
      .get("/v1/rssgen/rule2regex", { params: { rule } })
      .then((response) => {
        this.parse(response.data);
        this.setState({ extractRegexWaiting: false });
      })
      .catch((error) => {
        this.setState({ extractRegexWaiting: false });
      });
  };

  onTextareaClick = () => {
    const ruleInput = this.refs.ruleInput;
    if (ruleInput) {
      ruleInput.focus();
    }
  };

  parse = (regexStr) => {
    const regex = new RegExp(regexStr, "g");
    const html = this.state.loadedHtml;
    const results = [];
    let item;
    while ((item = regex.exec(html))) {
      results.push(item);
    }
    this.setState({ parseResult: results });
  };

  getParameterLength = (ruleInput = undefined) => {
    if (ruleInput === undefined) {
      ruleInput = this.state.ruleInput;
    }

    if (!ruleInput) {
      return 0;
    }
    const matches = ruleInput.match(/\{%\}/g);
    return matches ? matches.length : 0;
  };

  onSubmit = (e) => {
    if (this.state.saveWaiting) {
      return;
    }

    if (!this.checkSubmitValidation()) {
      return;
    }

    let rule = this.state.ruleInput;
    const regex = /\{%\}/g;
    let parseResult = null;
    let nth = 0;
    while ((parseResult = regex.exec(rule))) {
      for (let key of Object.keys(this.state.rssFields)) {
        const replaceNumber = this.state.rssFields[key];
        if (nth === replaceNumber) {
          rule = rule.substring(0, parseResult.index) + `{%:${key}}` + rule.substring(parseResult.index + 3);
          break;
        }
      }
      nth++;
    }
    const parameter = {
      title: this.state.rssTitle,
      description: this.state.rssDescription,
      url: this.state.urlInput,
      rule: rule,
    };
    let url;

    this.setState({ saveWaiting: true });
    if (this.props.editMode) {
      url = `/v1/rssgen/${this.getUuid()}?${queryString.stringify(parameter)}`;
      axios
        .put(url)
        .then((response) => {
          this.setState({ saveWaiting: false });
          this.props.actions.alert(Strings.SUCCESS_TO_SAVE);
          getAppHistory().back();
        })
        .catch((error) => {
          this.setState({ saveWaiting: false });
          this.props.actions.alert(getMessageFromResponse(error.response, Strings.FAILED_TO_EDIT_RSS));
        });
    } else {
      url = `/v1/rssgen?${queryString.stringify(parameter)}`;
      axios
        .post(url)
        .then((response) => {
          axios
            .get("/v1/project/rss", { params: { link: response.data.rssurl } })
            .then((_) => {
              this.setState({ saveWaiting: false });
              getAppHistory().replace(Urls.getRssEditLink(response.data.uuid));
            })
            .catch((error) => {
              this.setState({ saveWaiting: false });
              this.props.actions.alert(Strings.PROJECT_NOT_FOUND);
            });
        })
        .catch((error) => {
          this.setState({ saveWaiting: false });
          this.props.actions.alert(getMessageFromResponse(error.response, Strings.FAILED_TO_GENERATE_RSS));
        });
    }
  };

  onRssFieldChange = (e) => {
    const updateValue = {
      rssFields: update(this.state.rssFields, {
        [e.target.name]: {
          $set: e.target.value === null || e.target.value === undefined || e.target.value === "" ? null : parseInt(e.target.value),
        },
      }),
    };

    switch (e.target.name) {
      case rssFieldKeys.title:
        updateValue.rssTitleFieldReuiredError = false;
        break;
      case rssFieldKeys.link:
        updateValue.rssLinkFieldReuiredError = false;
        break;
    }

    this.setState(updateValue);
  };

  /**
   * key = undefined 이면 모든 rss field key에 대하여 check
   * @param key
   * @returns {boolean}
   */
  checkRssFieldKeyError(key) {
    if (key === undefined) {
      for (let rssFieldKey of Object.keys(rssFieldKeys)) {
        if (this.checkRssFieldKeyError(rssFieldKey)) {
          return true;
        }
      }
      return false;
    }

    const rssFieldValue = this.state.rssFields[key];
    if (rssFieldValue === null || rssFieldValue === undefined) {
      return false;
    }
    for (let rssFieldKey of Object.keys(rssFieldKeys)) {
      if (key === rssFieldKey) {
        continue;
      }
      if (this.state.rssFields[rssFieldKey] === rssFieldValue) {
        return true;
      }
    }
    return false;
  }

  checkSubmitValidation = () => {
    let valid = true;
    if (!this.state.ruleInput) {
      valid = false;
      this.setState({ ruleInputRequiredError: true });
    }
    if (!this.state.rssTitle) {
      valid = false;
      this.setState({ rssTitleRequiredError: true });
    }
    if (!this.state.rssDescription) {
      valid = false;
      this.setState({ rssDescriptionRequiredError: true });
    }
    if (this.state.rssFields[rssFieldKeys.title] === undefined || this.state.rssFields[rssFieldKeys.title] === null) {
      valid = false;
      this.setState({ rssTitleFieldReuiredError: true });
    }
    if (this.state.rssFields[rssFieldKeys.link] === undefined || this.state.rssFields[rssFieldKeys.link] === null) {
      valid = false;
      this.setState({ rssLinkFieldReuiredError: true });
    }

    return valid;
  };

  loadRssGenerator(uuid) {
    this.setState({ rssGenLoadWaiting: true });
    axios
      .get(`/v1/rssgen/my`)
      .then((response) => {
        const updateValue = { rssGenLoadWaiting: false };
        if (response.data) {
          const rssGen = response.data.find((a) => a.uuid === uuid);
          const ruleReplaceRegex = /\{%(?:\}|(?::(.+?))\})/g;
          updateValue.urlInput = rssGen.url;
          updateValue.rssTitle = rssGen.title;
          updateValue.rssUrl = rssGen.rssurl;
          updateValue.rssDescription = rssGen.description;
          updateValue.ruleInput = rssGen.rule.replace(ruleReplaceRegex, "{%}");
          updateValue.rssFields = {
            [rssFieldKeys.title]: null,
            [rssFieldKeys.link]: null,
            [rssFieldKeys.thumbnail]: null,
            [rssFieldKeys.content]: null,
          };
          let parseResult = null;
          let index = 0;
          while ((parseResult = ruleReplaceRegex.exec(rssGen.rule))) {
            if (parseResult[1] !== undefined) {
              updateValue.rssFields[parseResult[1]] = index;
            }
            index++;
          }
          this.setState(updateValue);
          this.loadUrlHTML(rssGen.url);
        }
      })
      .catch((error) => {
        this.setState({ rssGenLoadWaiting: false });
      });
  }

  openRss = () => {
    window.open(Urls.getRssPage(this.getUuid()));
  };

  openNotihubProject = () => {
    if (this.state.openNotihubWaiting) {
      return;
    }

    this.setState({ openNotihubWaiting: true });
    axios
      .get("/v1/project/rss", { params: { link: this.state.rssUrl } })
      .then((response) => {
        this.setState({ openNotihubWaiting: false });
        getAppHistory().push(Urls.getProjectFeedPage(response.data.urlKey));
      })
      .catch((error) => {
        this.setState({ openNotihubWaiting: false });
        this.props.actions.alert(Strings.PROJECT_NOT_FOUND);
      });
  };

  getUuid = () => {
    return this.props.match.params.uuid;
  };

  render() {
    let parameterLength = this.getParameterLength();
    const generateButtonEnabled =
      this.state.loadedHtml &&
      this.state.ruleInput &&
      !this.checkRssFieldKeyError() &&
      this.state.rssFields[rssFieldKeys.title] !== null &&
      this.state.rssFields[rssFieldKeys.title] !== undefined &&
      this.state.rssFields[rssFieldKeys.link] !== null &&
      this.state.rssFields[rssFieldKeys.link] !== undefined &&
      this.state.rssTitle &&
      this.state.rssDescription;
    const selectedRssFields = [];
    for (let key of Object.keys(rssFieldKeys)) {
      const rssFieldKey = rssFieldKeys[key];
      if (this.state.rssFields[rssFieldKey] !== null && this.state.rssFields[rssFieldKey] !== undefined) {
        selectedRssFields.push(this.state.rssFields[rssFieldKey]);
      }
    }

    const buttonProgress = <CircularProgress color="#ffffff" size={20} style={styles.buttonProgress} />;

    return (
      <div style={styles.container} className="default-page build-rss-page">
        {this.state.rssGenLoadWaiting ? <LoaderPage /> : undefined}
        <div className="step step1">
          <h3>{Strings.STEP1_TITLE}</h3>
          <div>
            <div className="input-label">Site URL:</div>
            <div className="input-div">
              <input value={this.state.urlInput} onChange={this.onInputChange} name="urlInput" placeholder="https://notihub.net/" />
              {/*<input value={this.state.encodingInput} onChange={this.onInputChange} name="encodingInput" placeholder="Encoding (default:Auto)"/>*/}
            </div>
            <button className={this.state.urlInput ? "active" : ""} onClick={this.state.urlInput ? this.onLoadUrlClick : undefined}>
              {this.state.loadedHtmlWaiting ? buttonProgress : undefined}
              <span style={this.state.loadedHtmlWaiting ? styles.marginRight25 : undefined}>{Strings.LOAD_URL}</span>
            </button>
          </div>
        </div>
        {this.state.loadedHtml
          ? [
              <div key={0} className="step step2">
                <h3>{Strings.STEP2_TITLE}</h3>
                <div>
                  HTML of "{this.state.urlInputRequest}"
                  <div className="loaded-html">
                    <AceEditor
                      style={styles.editorStyle}
                      showGutter
                      showPrintMargin
                      highlightActiveLine
                      readonly
                      value={this.state.loadedHtml}
                      mode="html"
                      theme="github"
                      name="brace-editor"
                      editorProps={{ $blockScrolling: true }}
                    />
                  </div>
                  <div className="rule-input">
                    <textarea
                      ref="ruleInput"
                      className={this.state.ruleInputRequiredError ? "shake error-box" : ""}
                      value={this.state.ruleInput}
                      name="ruleInput"
                      minLength={6}
                      onChange={this.onInputChange}
                    />
                    {!this.state.ruleInput ? (
                      <div className={`placeholder ${this.state.ruleInputRequiredError ? "shake" : ""}`} onClick={this.onTextareaClick}>
                        <p className="margin-bottom-5">{Strings.TYPE_RSS_RULE}</p>
                        {'<li class="google">'}
                        <br />
                        <pre>{'    <a href="https://google.com">Google</a>'}</pre>
                        {"</li>"}
                        <div className="description">
                          <p>Rule: {'<li class="google">{|}{_}<a href="{%}">{%}</a>'}</p>
                        </div>
                      </div>
                    ) : undefined}
                    <div className="hint">
                      <i className="far fa-lightbulb" />
                      <strong>{"{*}"}</strong>: skip, <strong>{"{%}"}</strong>: parameter, <strong>{"{_}"}</strong>: white-space, <strong>{"{|}"}</strong>: line
                      break
                    </div>
                  </div>
                  <button className={this.state.ruleInput ? "active" : ""} onClick={this.state.ruleInput ? this.onExtract : undefined}>
                    {this.state.extractRegexWaiting ? buttonProgress : undefined}
                    <span style={this.state.extractRegexWaiting ? styles.marginRight25 : undefined}>{Strings.EXTRACT}</span>
                  </button>
                  {this.state.parseResult ? (
                    <div className="parse-result">
                      {this.state.parseResult.map((item, key) => {
                        return (
                          <div key={key}>
                            {item.map((item, key) => {
                              if (key === 0) {
                                return undefined;
                              }
                              const style = {
                                color: Consts.FIELD_COLORS[(key - 1) % Consts.FIELD_COLORS.length],
                              };
                              return (
                                <div key={key}>
                                  <span style={style}>&#123;%{key}&#125;</span>: {item}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  ) : undefined}
                </div>
              </div>,
              <div key={1} className="step step3">
                <h3>{Strings.STEP3_TITLE}</h3>
                <div>
                  <RssFieldSelector
                    selectedRssFieldIndexes={selectedRssFields}
                    error={this.checkRssFieldKeyError(rssFieldKeys.title) || this.state.rssTitleFieldReuiredError}
                    value={this.state.rssFields[rssFieldKeys.title]}
                    name={rssFieldKeys.title}
                    onChange={this.onRssFieldChange}
                    length={parameterLength}
                    label="title*"
                  />
                  <RssFieldSelector
                    selectedRssFieldIndexes={selectedRssFields}
                    error={this.checkRssFieldKeyError(rssFieldKeys.link) || this.state.rssLinkFieldReuiredError}
                    value={this.state.rssFields[rssFieldKeys.link]}
                    name={rssFieldKeys.link}
                    onChange={this.onRssFieldChange}
                    length={parameterLength}
                    label="link*"
                  />
                  <RssFieldSelector
                    selectedRssFieldIndexes={selectedRssFields}
                    error={this.checkRssFieldKeyError(rssFieldKeys.thumbnail)}
                    value={this.state.rssFields[rssFieldKeys.thumbnail]}
                    name={rssFieldKeys.thumbnail}
                    onChange={this.onRssFieldChange}
                    length={parameterLength}
                    label="thumbnail"
                  />
                  <RssFieldSelector
                    selectedRssFieldIndexes={selectedRssFields}
                    error={this.checkRssFieldKeyError(rssFieldKeys.content)}
                    value={this.state.rssFields[rssFieldKeys.content]}
                    name={rssFieldKeys.content}
                    onChange={this.onRssFieldChange}
                    length={parameterLength}
                    label="content"
                  />
                </div>
              </div>,
              <div key={2} className="step step4">
                <h3>{Strings.STEP4_TITLE}</h3>
                <div>
                  <div className="row">
                    <span>{Strings.RSS_TITLE}*</span>
                    <input
                      onChange={this.onInputChange}
                      name="rssTitle"
                      value={this.state.rssTitle}
                      type="text"
                      className={this.state.rssTitleRequiredError ? "shake error-box" : ""}
                    />
                  </div>
                  <div className="row">
                    <span>{Strings.RSS_DESCRIPTION}*</span>
                    <input
                      onChange={this.onInputChange}
                      name="rssDescription"
                      value={this.state.rssDescription}
                      type="text"
                      className={this.state.rssDescriptionRequiredError ? "shake error-box" : ""}
                    />
                  </div>
                </div>
                <button onClick={this.onSubmit} className={generateButtonEnabled ? "active margin-bottom-10" : "margin-bottom-10"}>
                  {this.state.saveWaiting ? buttonProgress : undefined}
                  <span style={this.state.saveWaiting ? styles.marginRight25 : undefined}>{this.props.editMode ? Strings.SAVE : Strings.GENERATE_RSS}</span>
                </button>
                {this.props.editMode ? (
                  <button onClick={this.openRss} className={"active margin-bottom-10"}>
                    {Strings.VIEW_RSS}
                  </button>
                ) : undefined}
                {this.props.editMode ? (
                  <button onClick={this.openNotihubProject} className={"active margin-bottom-10"}>
                    {this.state.openNotihubWaiting ? buttonProgress : undefined}
                    <span style={this.state.openNotihubWaiting ? styles.marginRight25 : undefined}>{Strings.OPEN_IN_NOTIHUB}</span>
                  </button>
                ) : undefined}
              </div>,
            ]
          : undefined}
      </div>
    );
  }
}

const styles = {
  container: {},
  editorStyle: {
    width: "100%",
  },
  marginRight25: {
    marginRight: 25,
  },
  buttonProgress: {
    verticalAlign: "middle",
    marginRight: 5,
    top: -3,
  },
};
const rssFieldKeys = {
  title: "title",
  link: "link",
  thumbnail: "thumbnail",
  content: "content",
};

export default connect(null, RssBuildFeedPage.DispatchToProps)(RssBuildFeedPage);
