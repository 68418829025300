import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";

class ImageView extends React.Component {
  static propTypes = {
    style: PropTypes.object,
    onClick: PropTypes.func,
    url: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  static defaultProps = {
    width: 300,
    height: 300,
  };

  render() {
    const style = {
      backgroundImage: this.props.url ? `url('${this.props.url}')` : "",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: "inline-block",
      width: this.props.width,
      height: this.props.height,
    };
    return <div className={this.props.className} style={[style, this.props.style]} onClick={this.props.onClick} />;
  }
}

export default Radium(ImageView);
