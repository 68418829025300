/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-04-23.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Consts, Strings } from "../constants";
import LoaderPage from "./LoaderPage";
import TextField from "../components/TextField";
import RaisedButton from "material-ui/RaisedButton";
import { signup } from "../actions/register";
import { showSnackbar } from "../actions/globalSnackbar";
import DialogManager from "../dialogs/DialogManager";
import EmailVerificationRequireDialog from "../dialogs/EmailVerificationRequireDialog";
import axios from "axios";
import { Checkbox } from "material-ui";

class SignUpPage extends React.Component {
  static StateToProps = (state, ownProps) => {
    return {
      registerState: state.register.state,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        signup: (username, email, password) => {
          dispatch(signup(username, email, password));
        },
        alert: (text) => {
          dispatch(showSnackbar(text));
        },
      },
    };
  };

  static propTypes = {
    dispatch: PropTypes.func,
    actions: PropTypes.object,
    registerState: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
  };

  state = {
    username: "",
    email: "",
    password: "",
    verifyPassword: "",
    verifyPasswordError: "",
    agreement: "",
    termsChecked: false,
  };

  componentDidMount() {
    axios.get("/v1/agreement").then((response) => {
      this.setState({ agreement: response.data });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (Consts.hasChangedRequestWaitingToSuccess(this.props.registerState, nextProps.registerState)) {
      DialogManager.push(EmailVerificationRequireDialog);
      if (this.props.location && this.props.location.state && this.props.location.state.redirectionPathname) {
        this.props.history.push(this.props.location.state.redirectionPathname);
      } else {
        this.props.history.push("/");
      }
    } else if (Consts.hasChangedRequestWaitingToFailure(this.props.registerState, nextProps.registerState)) {
      this.props.actions.alert(nextProps.registerState.errorMessage);
    }
  }

  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSignUpClick = () => {
    if (!this.state.termsChecked) {
      this.props.actions.alert(Strings.ACCEPT_TERMS_REQUIRED);
      return;
    }

    if (this.state.password !== this.state.verifyPassword) {
      this.setState({ verifyPasswordError: Strings.NOT_EQUALS });
      return;
    }
    this.props.actions.signup(this.state.username, this.state.email, this.state.password);
  };

  onVerifyPasswordFocus = () => {
    this.setState({ verifyPasswordError: "" });
  };

  onVerifyPasswordBlur = () => {
    this.setState({ verifyPasswordError: this.state.password !== this.state.verifyPassword ? Strings.NOT_EQUALS : "" });
  };

  onTermsCheck = (e, checked) => {
    this.setState({ termsChecked: checked });
  };

  render() {
    return (
      <div className="default-page">
        <TextField name="username" floatingLabelText={Strings.USERNAME} value={this.state.username} onChange={this.onInputChange} fullWidth />
        <TextField name="email" type="email" floatingLabelText={Strings.EMAIL} value={this.state.email} onChange={this.onInputChange} fullWidth />
        <TextField
          name="password"
          type="password"
          floatingLabelText={Strings.PASSWORD}
          value={this.state.password}
          onChange={this.onInputChange}
          inputStyle={styles.passwordInput}
          fullWidth
        />
        <TextField
          name="verifyPassword"
          type="password"
          floatingLabelText={Strings.VERIFY_PASSWORD}
          value={this.state.verifyPassword}
          onChange={this.onInputChange}
          inputStyle={styles.passwordInput}
          fullWidth
          errorText={this.state.verifyPasswordError}
          onFocus={this.onVerifyPasswordFocus}
          onBlur={this.onVerifyPasswordBlur}
        />
        {this.state.agreement ? (
          <div>
            <Checkbox label={Strings.ACCEPT_TERMS} style={styles.agreeCheckbox} checked={this.state.termsChecked} onCheck={this.onTermsCheck} />
            <div dangerouslySetInnerHTML={{ __html: this.state.agreement }} style={styles.agreement} />
          </div>
        ) : undefined}
        <RaisedButton label={Strings.SIGN_UP} primary={true} onClick={this.onSignUpClick} fullWidth={true} />
        {this.props.registerState.request === Consts.REQUEST_WAITING ? <LoaderPage /> : undefined}
      </div>
    );
  }
}

const styles = {
  passwordInput: {
    fontFamily: "Roboto, sans-serif",
  },
  agreement: {
    height: Consts.IS_MOBILE ? 170 : 300,
    padding: 20,
    color: "rgb(102, 102, 102)",
    fontSize: 13,
    background: "rgb(245, 245, 245)",
    border: "1px solid rgb(229, 229, 229)",
    overflow: "auto",
    marginBottom: 5,
  },
  agreeCheckbox: {
    textAlign: "left",
    margin: "10px 0",
  },
};

export default connect(SignUpPage.StateToProps, SignUpPage.DispatchToProps)(SignUpPage);
