/**
 * Created by kimchangduk on 2017-05-03.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';

class ErrorMessage extends React.Component {
    static propTypes = {
        errorMessage: PropTypes.node.isRequired
    };

    render() {
        return (<div className="default-page text-align-center padding-20 margin-top-10">
            {this.props.errorMessage}
        </div>);
    }
}

export default ErrorMessage;