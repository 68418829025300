/**
 * Created by kimchangduk on 2018-03-16.
 */

import React from "react";
import Radium from "radium";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Consts, Urls } from "../constants";
import { searchSubscription } from "../actions/search";
import FeedItemList from "../components/FeedItemList";

class SubscriptionSearchResultPage extends React.Component {
  static StateToProps = (state) => {
    return {
      searchState: state.search.state,
      searchDataSource: state.search.dataSource,
    };
  };

  static DispatchToProps = (dispatch) => {
    return {
      actions: {
        search: (word, lastFeedId = undefined) => {
          dispatch(searchSubscription(word, lastFeedId));
        },
      },
    };
  };

  static propTypes = {
    match: PropTypes.object,
    actions: PropTypes.object,

    searchState: PropTypes.object,
    searchDataSource: PropTypes.array,
  };

  componentDidMount() {
    this.search();
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.match.params.query !== nextProps.match.params.query &&
      // 뒤로가기시 match.params.query가 디코딩되어서 들어올때 처리
      decodeURIComponent(this.props.match.params.query) !== nextProps.match.params.query
    ) {
      this.search(nextProps.match.params.query.trim());
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchState.request !== this.props.searchState.request && this.props.searchState.request === Consts.REQUEST_SUCCESS) {
      this.checkNeedToLoadMore();
    }
  }

  search(query) {
    if (query === undefined) {
      query = this.props.match.params.query.trim();
    }
    query = decodeURIComponent(query);
    this.props.actions.search(query);
  }

  renderSearchResults = () => {
    const query = decodeURIComponent(this.props.match.params.query);
    return (
      <div>
        {this.props.searchState.word === query && this.props.searchDataSource ? (
          <div>
            <p style={[styles.searchResultHeader, styles.matchingFeeds]}>MATCHING FEEDS</p>
            <div className="padding-left-10">
              <FeedItemList showViewModeSelector={true} feeds={this.props.searchDataSource} />
            </div>
          </div>
        ) : undefined}
      </div>
    );
  };

  onScroll = (e) => {
    this.checkNeedToLoadMore();
  };

  checkNeedToLoadMore = () => {
    const element = this.refs.container;
    const query = decodeURIComponent(this.props.match.params.query);
    if (!element) {
      return;
    }

    if (element.scrollHeight - element.clientHeight - 100 <= element.scrollTop) {
      if (!this.props.searchState.last && this.props.searchState.request === Consts.REQUEST_SUCCESS) {
        const lastItem = this.props.searchDataSource ? this.props.searchDataSource[this.props.searchDataSource.length - 1] : undefined;
        this.props.actions.search(query, lastItem ? lastItem.id : undefined);
      }
    }
  };

  render() {
    const searchFailed = this.props.searchState.request !== Consts.REQUEST_WAITING && (!this.props.searchDataSource || this.props.searchDataSource.length <= 0);

    return (
      <div style={styles.container} onScroll={this.onScroll} ref="container">
        <div className="center-align-page">{searchFailed ? <p>{this.props.searchState.errorMessage}</p> : this.renderSearchResults()}</div>
      </div>
    );
  }
}

const styles = {
  container: {
    height: "100%",
    overflowY: "auto",
  },
  searchResultHeader: {
    userSelect: "none",
    fontWeight: "bold",
    marginBottom: 10,
    fontSize: 13,
    color: "#757575",
  },
  marginTop30: { marginTop: 30 },
  matchingFeeds: {
    transform: "translateY(37px)",
  },
};

export default connect(SubscriptionSearchResultPage.StateToProps, SubscriptionSearchResultPage.DispatchToProps)(Radium(SubscriptionSearchResultPage));
