/* eslint-disable no-useless-escape */
/**
 * Created by kimchangduk on 2017-04-13.
 */

export const PROJECT_PAGE = "project-page";
export const COOKIE_KEY_FCM_TOKEN = "fcm_token";
export const COOKIE_KEY_HOW_TO_USE_NEVER_SHOW = "HOW_TO_USE_NEVER_SHOW";
export const COOKIE_KEY_FEED_ITEM_VIEW_TYPE = "COOKIE_KEY_FEED_ITEM_VIEW_TYPE";
export const COOKIE_KEY_DRAWER_FIXED = "DRAWER_FIXED";

export const OS_WEB = "Web";
export const OS_ANDROID = "Android";
export const OS_IOS = "iOS";

export const IS_MOBILE = (function (a) {
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      a
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      a.substr(0, 4)
    )
  );
})(navigator.userAgent || navigator.vendor || window.opera);

export const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
export const IS_IOS_WEBVIEW = (() => {
  const standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);
  return ios && !standalone && !safari;
})();

export const IS_INTERNET_EXPLORER_AGENT = (function (agent) {
  return (navigator.appName == "Netscape" && navigator.userAgent.search("Trident") != -1) || agent.indexOf("msie") != -1;
})(navigator.userAgent.toLowerCase());

export const API_URL = "WEBPACK_API_URL";
export const DEFAULT_TITLE = process.env.NODE_ENV === "production" ? "NotiHub" : "NotiHub DEV"; // index.ejs의 title이랑 일치해야함

//<editor-fold desc="변경시 style.scss 같이 변경필요">
export const APPBAR_HEIGHT = 57;
export const GNB_HEIGHT = 36;
export const PRIMARY_COLOR = "#24292e";
export const SECONDARY_COLOR = "#ff4081";
export const BLUE_COLOR = "#2196f3";
export const DISABLED_TEXT_COLOR = PRIMARY_COLOR;
export const TAB_PAGER_HEIGHT = 60;
//</editor-fold>

export const BYTE = 1;
export const KB = 1024;
export const MB = 1048576;
export const PROFILE_IMAGE_SIZE = 10 * MB;

export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_MANAGER = "ROLE_MANAGER";
export const ROLE_API_VIEWER = "ROLE_API_VIEWER";

export const REQUEST_NONE = "REQUEST_NONE";
export const REQUEST_WAITING = "REQUEST_WAITING";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const REQUEST_FAILURE = "REQUEST_FAILURE";

export const LOG_TYPE_ERROR = "ERROR";

export const TIMELINE_TYPE_ALL = "TIMELINE_TYPE_ALL";
export const TIMELINE_TYPE_MY = "TIMELINE_TYPE_MY";

export const hasChangedRequestWaitingToSuccess = (before, after) => {
  if (typeof before === "object") {
    before = before.request;
  }
  if (typeof after === "object") {
    after = after.request;
  }
  return before === REQUEST_WAITING && after === REQUEST_SUCCESS;
};
export const hasChangedRequestWaitingToFailure = (before, after) => {
  if (typeof before === "object") {
    before = before.request;
  }
  if (typeof after === "object") {
    after = after.request;
  }
  return before === REQUEST_WAITING && after === REQUEST_FAILURE;
};

export const SOURCE_VISIBILITY_PUBLIC = "PUBLIC";
export const SOURCE_VISIBILITY_PRIVATE = "PRIVATE";

export const MODE_NORMAL = "NORMAL";
export const MODE_DEVELOPER = "DEVELOPER";

export const TRANSLATION_LANG_CODE_KOREAN = "ko";
export const TRANSLATION_LANG_CODE_ENGLISH = "en";
export const TRANSLATION_LANG_CODE_JAPANESE = "ja";
export const TRANSLATION_LANG_CODE_CHINESE = "zh-CN";

export const HttpMethods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
  OPTION: "OPTION",
};

export const ReportTypes = {
  NEWS: "NEWS",
  POST: "POST",
};

export const TagOrProject = {
  TAG: "TAG",
  PROJECT: "PROJECT",
};

export const NewsDataType = {
  ONLY_NEWS: "ONLY_NEWS",
  WITH_KEYWORD: "WITH_KEYWORD",
};

export const getRegexGroupRegex = () => /[^\\](\([^\?].*?[^\\]\))/g;

export const STATE_KEY_ALLOW_DOWNLOAD_POPUP = "STATE_KEY_ALLOW_DOWNLOAD_POPUP";

export const FeedItemViewTypes = {
  THUMBNAIL: "THUMBNAIL",
  TITLE_ONLY: "TITLE_ONLY",
};

export const FIELD_COLORS = ["#61bd4f", "#ffab4a", "#eb5a46", "#c377e0", "#0079bf", "#00c2e0", "#ff80ce"];
export const UNDEFINED_FOLDER_NAME = "UNDEFINED_FOLDER_NAME";
