/**
 * Created by kimchangduk on 6/8/2018.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {SortableElement} from "react-sortable-hoc";
import {Consts, Strings} from "../constants";
import DragHandle from "./DragHandle";
import {MenuItem} from "material-ui";
import KeyboardArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import KeyboardArrowRight from 'material-ui/svg-icons/hardware/keyboard-arrow-right';
import DragHandleIcon from "material-ui/svg-icons/editor/drag-handle";

class FolderSortableItem extends React.Component {
    static propTypes = {
        folderName: PropTypes.string,
        opened: PropTypes.bool,
        onToggle: PropTypes.func,
    };

    render() {
        const {folderName, onToggle, opened} = this.props;
        return (<li className="folder-sortable position-relative">
            {
                Consts.IS_MOBILE ?
                    <MenuItem
                        style={styles.folderNameMenu}
                        innerDivStyle={styles.folderNameInnerDiv}
                        onClick={() => {
                            if (onToggle) {
                                onToggle(folderName);
                            }
                        }}
                        leftIcon={opened ?
                            <KeyboardArrowDown style={styles.folderIcon}/> :
                            <KeyboardArrowRight style={styles.folderIcon}/>
                        }>
                        {
                            folderName === Consts.UNDEFINED_FOLDER_NAME ?
                                Strings.UNCLASSIFIED : folderName
                        }
                        <DragHandle>
                            <DragHandleIcon style={styles.handle}/>
                        </DragHandle>
                    </MenuItem> :

                    <MenuItem
                        style={styles.folderNameMenu}
                        innerDivStyle={styles.folderNameInnerDiv}
                        leftIcon={
                            opened ?
                                <KeyboardArrowDown
                                    style={styles.folderIcon}
                                    onClick={() => {
                                        if (onToggle) {
                                            onToggle(folderName);
                                        }
                                    }}/> :
                                <KeyboardArrowRight
                                    style={styles.folderIcon}
                                    onClick={() => {
                                        if (onToggle) {
                                            onToggle(folderName);
                                        }
                                    }}/>
                        }>
                        <DragHandle>
                            <div>
                                {
                                    folderName === Consts.UNDEFINED_FOLDER_NAME ?
                                        Strings.UNCLASSIFIED : folderName
                                }
                            </div>
                        </DragHandle>
                    </MenuItem>
            }
        </li>);
    }
}

const styles = {
    folderNameMenu: Consts.IS_MOBILE ? {
        position: 'relative',
        color: 'rgb(0, 0, 0)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'bold',

    } : {
        color: 'rgba(0, 0, 0, 0.63)',
        lineHeight: '30px',
        minHeight: 30,
        fontSize: 13,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'bold'
    },
    folderNameInnerDiv: Consts.IS_MOBILE ? {
        padding: '0 0 0 25px'
    } : {
        padding: '0 0 0 20px'
    },
    folderIcon: Consts.IS_MOBILE ? {
        margin: 0, left: 0, top: 13,
        fill: 'rgb(0, 0, 0)'
    } : {
        margin: 0, left: -3, top: 4,
        fill: 'rgb(150, 150, 150)'
    },
    handle: {
        position: 'absolute',
        right: 10,
        top: '50%',
        transform: 'translateY(-50%)'
    }
};

export default SortableElement(({folderName, opened, onToggle}) => {
    return <FolderSortableItem
        folderName={folderName}
        opened={opened}
        onToggle={onToggle}
    />;
});