/* eslint-disable react/no-danger */
import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./FeedContentLayer.scss";
import { openFeedLayer, closeFeedLayer, setFeedLayerIndex } from "../actions/feedContentLayer";
import { Urls, Consts, Strings } from "../constants";
import { getRegisteredTime } from "../constants/Strings";
import ShareIcon from "material-ui/svg-icons/social/share";
import CloseIcon from "material-ui/svg-icons/navigation/close";
import LeftIcon from "material-ui/svg-icons/hardware/keyboard-arrow-left";
import RightIcon from "material-ui/svg-icons/hardware/keyboard-arrow-right";
import { getAppHistory } from "../Utils";
import DialogManager from "../dialogs/DialogManager";
import ShareDialog from "../dialogs/ShareDialog";
import ReactSwipeEvents from "react-swipe-events";
import { IconButton } from "material-ui";
import withRouter from "../withRouter";

class FeedContentLayer extends React.Component {
  static StateToProps = (state) => {
    return {
      feeds: state.feedContentLayer.feeds,
      feed: state.feedContentLayer.feeds ? state.feedContentLayer.feeds[state.feedContentLayer.index] : null,
      index: state.feedContentLayer.index,
      closeWaiting: state.feedContentLayer.closeWaiting,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        closeFeedLayer: () => {
          dispatch(closeFeedLayer());
        },
        setFeedLayerIndex: (index) => {
          dispatch(setFeedLayerIndex(index));
        },
      },
    };
  };

  static propTypes = {
    actions: PropTypes.object,
    feeds: PropTypes.array,
    feed: PropTypes.object,
    index: PropTypes.number,
    closeWaiting: PropTypes.bool,
    location: PropTypes.object,
  };

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyDown);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.location !== nextProps.location &&
      (!nextProps.location.state || (nextProps.location.state.feedLayerOpen !== true && !nextProps.location.state.openFeed))
    ) {
      this.props.actions.closeFeedLayer();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e) => {
    switch (e.keyCode) {
      //right
      case 39:
        this.moveRight();
        break;

      //left
      case 37:
        this.moveLeft();
        break;
      default:
        break;
    }
  };

  onBackgroundTouch = (e) => {
    if (e.target === this.refs.container) {
      getAppHistory().back();
    }
  };

  moveProjectPage = () => {
    if (this.props.location.pathname !== Urls.getProjectFeedPage(this.props.feed.project.urlKey, false)) {
      getAppHistory().push(Urls.getProjectFeedPage(this.props.feed.project.urlKey));
    } else {
      this.props.actions.closeFeedLayer();
    }
  };

  onSwiped = (e, originalX, originalY, endX, endY, deltaX, deltaY) => {
    if (deltaX === 0 || Math.abs(deltaX) < 50) {
      return;
    }
    if (Math.abs(deltaY / deltaX) < 0.5) {
      deltaX > 0 ? this.moveLeft() : this.moveRight();
    }
  };

  moveLeft = () => {
    if (this.props.feed && this.props.index > 0) {
      this.props.actions.setFeedLayerIndex(this.props.index - 1);
    }
  };

  moveRight = () => {
    if (this.props.feed && this.props.index < this.props.feeds.length - 1) {
      this.props.actions.setFeedLayerIndex(this.props.index + 1);
    }
  };

  onShareClick = () => {
    DialogManager.push(ShareDialog, {
      url: Urls.getShareUrl(this.props.feed.project.urlKey, this.props.feed.id),
    });
  };

  render() {
    const { feed, index, feeds } = this.props;
    if (feed && this.props.location.state && this.props.location.state.feedLayerOpen) {
      const publishedDate = feed.published ? feed.published : feed.updated;
      return (
        <div
          className={`feed-content-layer ${Consts.IS_MOBILE ? "mobile" : ""} ${this.props.closeWaiting ? "closing" : ""}`}
          ref="container"
          onClick={this.onBackgroundTouch}
        >
          <div className="scrollable">
            <ReactSwipeEvents onSwiped={this.onSwiped}>
              <div>
                <div>
                  <a className="title" target="_blank" href={feed.link}>
                    {feed.title}
                  </a>
                  <p className="by">
                    <span className="project-name" onClick={this.moveProjectPage}>
                      {feed.project.title}
                    </span>
                    {feed.author ? ` by ${feed.author}` : ""} / <span>{getRegisteredTime(publishedDate)}</span>
                  </p>
                  {this.props.feed.image && (!this.props.feed.content || !this.props.feed.content.includes(this.props.feed.image)) ? (
                    <img className="feed-img" src={this.props.feed.image} />
                  ) : undefined}
                  <div className="content" dangerouslySetInnerHTML={{ __html: this.props.feed.content }} />
                  {feed.link ? (
                    <a className="title" target="_blank" href={feed.link}>
                      <button className="visit-btn">{Strings.VISIT_WEBSITE}</button>
                    </a>
                  ) : undefined}
                </div>
                <CloseIcon
                  className="close-btn"
                  style={styles.iconButtonIcon}
                  onClick={() => {
                    getAppHistory().back();
                  }}
                />
                <ShareIcon className="share-btn" onClick={this.onShareClick} />
              </div>
            </ReactSwipeEvents>
            {index > 0 ? (
              <IconButton className="left-button" iconStyle={styles.iconButtonIcon} style={styles.arrowButton} onClick={this.moveLeft}>
                <LeftIcon color={"rgba(0, 0, 0, 0.6)"} />
              </IconButton>
            ) : undefined}
            {feeds && feeds.length - 1 > index ? (
              <IconButton className="right-button" iconStyle={styles.iconButtonIcon} style={styles.arrowButton} onClick={this.moveRight}>
                <RightIcon color={"rgba(0, 0, 0, 0.6)"} />
              </IconButton>
            ) : undefined}
          </div>
        </div>
      );
    }
    return null;
  }
}

const styles = {
  iconButtonIcon: {
    width: 38,
    height: 38,
  },
  arrowButton: {
    width: 38,
    height: 38,
    padding: 0,
    border: "none",
    boxShadow: "none",
    position: "absolute",
  },
};

export default connect(FeedContentLayer.StateToProps, FeedContentLayer.DispatchToProps)(Radium(withRouter(FeedContentLayer)));
