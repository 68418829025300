import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import LoaderPage from "./LoaderPage";
import EmailVerificationRequireDialog from "../dialogs/EmailVerificationRequireDialog";
import {Consts, Urls} from "../constants";
import DialogManager from "../dialogs/DialogManager";
import {showSnackbar} from "../actions/globalSnackbar";
import queryString from "query-string";
import withRouter from '../withRouter';

class WaitAuthChange extends React.Component {
    static StateToProps = (state) => {
        return {
            loginRequest: state.user.state.loginRequest,
            user: state.user.dataSource,
            errorMessage: state.user.state.loginErrorMessage
        };
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {
                alert: (text) => {
                    dispatch(showSnackbar(text));
                }
            }
        };
    };

    static propTypes = {
        actions: PropTypes.object,
        loginRequest: PropTypes.string,
        user: PropTypes.object,
        errorMessage: PropTypes.string,
        location: PropTypes.object
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.loginRequest !== nextProps.loginRequest && nextProps.loginRequest === Consts.REQUEST_SUCCESS) {
            const {redirect} = queryString.parse(nextProps.location.search);
            if (redirect)
                this.props.navigate(redirect);
            else
                this.props.navigate('/');
        } else if (Consts.hasChangedRequestWaitingToFailure(this.props.loginRequest, nextProps.loginRequest)) {
            this.props.actions.alert(nextProps.errorMessage);
            this.props.navigate('/');
        }
    }

    render() {
        return <LoaderPage/>;
    }
}

const styles = {
    container: {}
};

export default connect(WaitAuthChange.StateToProps, WaitAuthChange.DispatchToProps)(withRouter(WaitAuthChange));