import React from "react";
import { findDOMNode } from "react-dom";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { SortableContainer } from "react-sortable-hoc";
import SubscriptionSortableItem from "./SubscriptionSortableItem";
import { Urls, Strings, Consts } from "../constants/index";
import { editSubscriptionOrder } from "../actions/subscribe";
import { connect } from "react-redux";
import Radium from "radium";
import FolderSortableItem from "./FolderSortableItem";
import "./SubscriptionSortableList.scss";

class SubscriptionSortableList extends React.Component {
  static StateToProps = (state) => {
    return {
      mySubscribesRequest: state.subscribe.mySubscribes.state.request,
      editSubscriptions: state.subscribe.editSubscriptions.state.request,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        editSubscriptionOrder: (subscribeIds, folderIds, orders) => {
          dispatch(editSubscriptionOrder(subscribeIds, folderIds, orders));
        },
      },
    };
  };

  static getSubscriptionArray = (subscriptionGroups, state) => {
    const result = [];
    for (let folderName of Object.keys(subscriptionGroups)) {
      result.push({
        type: SortableItemType.folder,
        folderName: folderName,
        value: null,
      });

      for (let i = 0; i < subscriptionGroups[folderName].length; i++) {
        result.push({
          type: SortableItemType.subscription,
          folderName: folderName,
          value: subscriptionGroups[folderName][i],
        });
      }
    }
    return result;
  };

  static propTypes = {
    mySubscribesRequest: PropTypes.string,
    editSubscriptions: PropTypes.string,
    subscriptions: PropTypes.object,
    actions: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = { openStates: {} };
    if (props.subscriptions) {
      for (let folderName of Object.keys(props.subscriptions)) {
        this.state.openStates[folderName] = true;
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    let changed = false;
    const updateState = {};
    if (!state.subscriptionItems) {
      updateState.subscriptionItems = SubscriptionSortableList.getSubscriptionArray(props.subscriptions, state);
    }

    if (props.subscriptions) {
      const openStates = state.openStates;
      for (let folderName of Object.keys(props.subscriptions)) {
        if (openStates[folderName] === undefined) {
          changed = true;
          openStates[folderName] = true;
        }
      }
      if (changed) {
        updateState.openStates = openStates;
      }
    }
    return updateState;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.subscriptions !== nextProps.subscriptions) {
      this.setState({
        subscriptionItems: SubscriptionSortableList.getSubscriptionArray(nextProps.subscriptions, nextState),
      });
      return true;
    }
    if (
      this.state.subscriptionItems !== nextState.subscriptionItems ||
      this.state.openStates !== nextState.openStates ||
      this.props.mySubscribesRequest !== nextProps.mySubscribesRequest ||
      this.props.editSubscriptions !== nextProps.editSubscriptions
    ) {
      return true;
    }
    return false;
  }

  enterFolderMovingMode = () => {
    const sortableNode = findDOMNode(this.refs.sortableList);
    if (sortableNode) {
      sortableNode.classList.add("folder-moving");
    }
  };

  exitFolderMovingMode = () => {
    const sortableNode = findDOMNode(this.refs.sortableList);
    if (sortableNode) {
      sortableNode.classList.remove("folder-moving");
    }
  };

  onFolderToggle = (folderName) => {
    const newOpenState = update(this.state.openStates, {
      [folderName]: { $set: !this.state.openStates[folderName] },
    });
    this.setState({
      openStates: newOpenState,
    });
  };

  onSortStart = ({ node, index, collection }) => {
    const sortableItem = this.state.subscriptionItems[index];
    if (sortableItem && sortableItem.type === SortableItemType.folder) {
      this.enterFolderMovingMode();
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const item = this.state.subscriptionItems[oldIndex];
    if (item && item.type === SortableItemType.folder) {
      this.exitFolderMovingMode();
    }

    if (oldIndex === newIndex) {
      return;
    }
    const sortableItems = update(this.state.subscriptionItems, {});
    switch (item.type) {
      case SortableItemType.subscription:
        this.onSubscriptionSortEnd(oldIndex, newIndex, sortableItems, item);
        break;
      case SortableItemType.folder:
        this.onFolderSortEnd(oldIndex, newIndex, sortableItems, item);
        break;
    }
  };

  onSubscriptionSortEnd(oldIndex, newIndex, sortableItems, item) {
    sortableItems.splice(oldIndex, 1);
    sortableItems.splice(newIndex, 0, item);
    let folderName = newIndex > 0 ? sortableItems[newIndex - 1].folderName : null;
    if (folderName === null) {
      return;
    }

    item.folderName = folderName;

    //<editor-fold desc="item.value.subscriptionFolder Object 설정">
    if (folderName === Consts.UNDEFINED_FOLDER_NAME) {
      item.value.subscriptionFolder = null;
    } else {
      let folderItem = this.state.subscriptionItems.find((item) => {
        return item.type === SortableItemType.subscription && item.value.subscriptionFolder && item.value.subscriptionFolder.name === folderName;
      });
      if (folderItem) {
        item.value.subscriptionFolder = update(folderItem.value.subscriptionFolder, {});
      }
    }
    //</editor-fold>

    const subscribeIds = [],
      folderIds = [],
      orders = [];
    for (let item of sortableItems.filter((item) => item.type === SortableItemType.subscription)) {
      const order = orders.length;
      subscribeIds.push(item.value.id);
      folderIds.push(item.value.subscriptionFolder ? item.value.subscriptionFolder.id : null);
      orders.push(order);
      item.value.order = order;
    }

    this.setState({ subscriptionItems: sortableItems });
    this.props.actions.editSubscriptionOrder(subscribeIds, folderIds, orders);
  }

  onFolderSortEnd = (oldIndex, newIndex, sortableItems, item) => {
    const folderItemCount = sortableItems.filter((a) => a.folderName === item.folderName).length;
    let nextFolderItem = null;
    for (let i = newIndex + (oldIndex < newIndex ? 1 : 0); i < sortableItems.length; i++) {
      if (sortableItems[i].type === SortableItemType.folder) {
        nextFolderItem = sortableItems[i];
        break;
      }
    }

    const slicedItems = sortableItems.splice(oldIndex, folderItemCount);
    if (!nextFolderItem) {
      for (let item of slicedItems) {
        sortableItems.push(item);
      }
    } else {
      const index = sortableItems.findIndex((item) => item === nextFolderItem);
      Array.prototype.splice.apply(sortableItems, [index, 0].concat(slicedItems));
    }
    let order = 0;
    for (let item of sortableItems) {
      if (item.type === SortableItemType.subscription) {
        item.value.order = order++;
      }
    }

    this.setState({ subscriptionItems: sortableItems });

    const subscriptions = sortableItems.filter((a) => a.type === SortableItemType.subscription);
    const subscriptionIds = subscriptions.map((item) => item.value.id);
    const folderIds = subscriptions.map((item) => (item.value.subscriptionFolder ? item.value.subscriptionFolder.id : null));
    const orders = subscriptions.map((item) => item.value.order);
    this.props.actions.editSubscriptionOrder(subscriptionIds, folderIds, orders);
  };

  render() {
    const SortableList = SortableContainer(({ items }) => {
      const disabled = this.props.mySubscribesRequest === Consts.REQUEST_WAITING || this.props.editSubscriptions === Consts.REQUEST_WAITING;
      return (
        <ul className="subscription-sortable-list">
          {items.map((item, key) => {
            switch (item.type) {
              case SortableItemType.folder:
                return (
                  <FolderSortableItem
                    key={item.type + item.folderName}
                    index={key}
                    folderName={item.folderName}
                    opened={this.state.openStates[item.folderName]}
                    disabled={disabled}
                    onToggle={this.onFolderToggle}
                  />
                );
              case SortableItemType.subscription:
                return (
                  <SubscriptionSortableItem
                    key={item.value.id}
                    visible={this.state.openStates[item.folderName]}
                    index={key}
                    disabled={disabled}
                    value={item.value}
                  />
                );
            }
          })}
        </ul>
      );
    });

    return (
      <div>
        <SortableList
          ref="sortableList"
          items={this.state.subscriptionItems}
          onSortStart={this.onSortStart}
          onSortEnd={this.onSortEnd}
          useDragHandle={true}
          type="vertical"
          lockAxis="y"
          helperClass="drag"
        />
      </div>
    );
  }
}

const SortableItemType = {
  folder: "FOLDER",
  subscription: "SUBSCRIPTION",
};

export default connect(SubscriptionSortableList.StateToProps, SubscriptionSortableList.DispatchToProps)(Radium(SubscriptionSortableList));
