import React from 'react';
import PropTypes from 'prop-types';

class TagList extends React.Component {
    static propTypes = {
        data: PropTypes.array
    };

    render() {
        return <div style={styles.container}>
            {
                this.props.data ? this.props.data.map((item, key) => {
                    return <div key={key} style={styles.tag}>
                        #{item.name}
                    </div>;
                }) : undefined
            }
        </div>;
    }
}

const styles = {
    container: {
        position: 'relative'
    },
    tag: {
        boxShadow: 'inset 0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        marginRight: 5,
        padding: '2px 5px',
        fontSize: 13,
        borderRadius: 4,
        color: '#9e9e9e',
        cursor: 'pointer'
    }
};

export default TagList;