/**
 * Created by kimchangduk on 2017-04-28.
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {Urls, Consts} from '../constants';
import PropTypes from 'prop-types';
import ImageView from "./ImageView";
import TagList from "./TagList";
import SubscribeButton from "./SubscribeButton";
import "./ProjectListItem.scss";

class ProjectListItem extends React.Component {
    static propTypes = {
        data: PropTypes.object,
        style: PropTypes.object,
        className: PropTypes.string
    };

    render() {
        const data = this.props.data;
        return (
            <div className={`project-list-item ${this.props.className ? this.props.className : ''}`} style={this.props.style}>
                <div className="left">
                    <Link to={Urls.getProjectFeedPage(data.urlKey)}>
                        <ImageView className="thumbnail" url={data.image} width={Consts.IS_MOBILE ? 80 : 100} height={Consts.IS_MOBILE ? 80 : 100}/>
                    </Link>
                </div>
                <div className="right">
                    <div className="title">
                        <Link to={Urls.getProjectFeedPage(data.urlKey)}>
                            {data.title}
                        </Link>
                    </div>
                    <p className="subscribers">{data.subscribersCount} followers</p>
                    <p className="description">
                        {data.description}
                    </p>
                    <TagList data={data.tags}/>
                    <SubscribeButton
                        subscribing={data.subscribing}
                        subscribe={data.subscribe}
                        style={Consts.IS_MOBILE ? undefined : styles.subscribeButton}
                        isButtonStyle={!Consts.IS_MOBILE}
                        optionMenuRightAlign={!Consts.IS_MOBILE}
                        urlKey={data.urlKey}/>
                </div>
            </div>
        );
    }
}

const styles = {
    title: {
        color: '#0366d6',
        lineHeight: '24px',
        fontWeight: 600,
        margin: 0,
        paddingRight: 35,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    description: {
        margin: 0,
        paddingTop: 2
    },
    rightInfo: {
        position: 'absolute',
        top: 13,
        right: 10,
        color: '#444',
        fontSize: 12,
        userSelect: 'none'
    },
    rightInfoIcon: {
        fontSize: 12,
        marginRight: 5,
        color: '#a7a7a7'
    },
    avatar: {
        top: 10,
        borderRadius: 3
    },
    secondaryText: {
        height: 'auto'
    },
    subscribeButton: {
        position: 'absolute',
        right: 0,
        top: 0
    }
};

export default ProjectListItem;