/**
 * Created by kimchangduk on 2017-04-27.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Urls, Consts } from "../constants";
import LoaderPage from "./LoaderPage";
import FeedTimeline from "./FeedTimeline";
import SubscribePopup from "../components/SubscribePopup";
import { getAppHistory } from "../Utils";
import { hasChangedRequestWaitingToSuccess } from "../constants/Consts";
import withRouter from "../withRouter";

class MainPage extends React.Component {
  static StateToProps = (state, ownProps) => {
    return {
      isLogin: state.user.state.isLogin,
      mySubscribesDataSource: state.subscribe.mySubscribes.dataSource,
      mySubscribesRequest: state.subscribe.mySubscribes.state.request,
    };
  };

  static propTypes = {
    isLogin: PropTypes.bool,
    actions: PropTypes.object,
    location: PropTypes.object,
    mySubscribesDataSource: PropTypes.array,
    mySubscribesRequest: PropTypes.string,
  };

  componentDidMount() {
    if (
      this.props.isLogin === false ||
      (this.props.mySubscribesRequest === Consts.REQUEST_SUCCESS && (!this.props.mySubscribesDataSource || this.props.mySubscribesDataSource.length <= 0))
    ) {
      window.setTimeout(() => {
        this.props.navigate(Urls.DISCOVER, { state: this.props.location.state, replace: true });
      }, 0);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (nextProps.isLogin !== this.props.isLogin && !nextProps.isLogin) ||
      (hasChangedRequestWaitingToSuccess(this.props.mySubscribesRequest, nextProps.mySubscribesRequest) &&
        (!nextProps.mySubscribesDataSource || nextProps.mySubscribesDataSource.length === 0))
    ) {
      this.props.navigate(Urls.DISCOVER, { state: nextProps.location.state, replace: true });
    }
  }

  render() {
    if (this.props.isLogin === null) {
      return [<LoaderPage key={0} />];
    } else {
      return [
        <FeedTimeline key={0} />,
        this.props.location?.state && this.props.location?.state?.target ? (
          <SubscribePopup
            key={1}
            linkTitle={this.props.location.state.title}
            link={this.props.location.state.target}
            onRequestClose={() => {
              this.props.navigate(this.props.location.pathname, { state: {}, replace: true });
            }}
          />
        ) : undefined,
      ];
    }
  }
}

export default connect(MainPage.StateToProps)(withRouter(MainPage));
