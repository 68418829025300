/**
 * Created by kimchangduk on 2017-04-30.
 */

import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";
import Loader from "../components/Loader";
import { Consts } from "../constants";

class LoaderPage extends React.Component {
  static propTypes = {
    zIndex: PropTypes.number,
  };

  static defaultProps = {
    zIndex: 9999,
  };

  render() {
    return (
      <div className="text-align-center loader-page fade-in" style={[styles.container, { zIndex: this.props.zIndex }]}>
        <Loader style={styles.loader} />
      </div>
    );
  }
}

const styles = {
  container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    transformStyle: "preserve-3d",
    backgroundColor: "rgba(255,255,255,0.5)",
    animation: "fadein 600ms 1",
    opacity: 1,
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "perspective(1px) translateY(-50%) translateX(-50%)",
  },
};

export default Radium(LoaderPage);
