/**
 * Created by kimchangduk on 2017-04-13.
 */
import {combineReducers} from 'redux';
import userReducer from './user';
import registerReducer from './register';
import projectReducer from './project';
import globalSnackbarReducer from './globalSnackbar';
import projectNewsReducer from './projectNews';
import boardReducer from './board';
import timelineReducer from './timeline';
import keywordReducer from './keyword';
import feedReducer from './feed';
import subscribeReducer from "./subscribe";
import searchReducer from "./search";
import feedContentLayerReducer from "./feedContentLayer";

export default combineReducers({
    user: userReducer,
    register: registerReducer,
    project: projectReducer,
    globalSnackbar: globalSnackbarReducer,
    projectNews: projectNewsReducer,
    board: boardReducer,
    timeline: timelineReducer,
    keyword: keywordReducer,
    feed: feedReducer,
    subscribe: subscribeReducer,
    search: searchReducer,
    feedContentLayer: feedContentLayerReducer
});
