/**
 * Created by kimchangduk on 2018-03-16.
 */

import React from "react";
import Radium from "radium";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Consts, Urls, Strings } from "../constants";
import { getProjectWithRss, searchProject } from "../actions/project";
import { getAppHistory, isURL } from "../Utils";
import LoaderPage from "./LoaderPage";
import { hasChangedRequestWaitingToFailure, hasChangedRequestWaitingToSuccess } from "../constants/Consts";
import { search } from "../actions/search";
import ProjectList from "../components/ProjectList";
import FeedItemList from "../components/FeedItemList";
import { RaisedButton } from "material-ui";
import AddIcon from "material-ui/svg-icons/content/add";
import withRouter from "../withRouter";

class SearchResultPage extends React.Component {
  static StateToProps = (state) => {
    return {
      projectState: state.project.project.state,
      projectDataSource: state.project.project.dataSource,

      searchProjectState: state.project.search.state,
      searchProjectDataSource: state.project.search.dataSource,

      searchState: state.search.state,
      searchDataSource: state.search.dataSource,
      feedContentLayerIndex: state.feedContentLayer.index,
    };
  };

  static DispatchToProps = (dispatch) => {
    return {
      actions: {
        getProjectWithRss: (url) => {
          dispatch(getProjectWithRss(url));
        },
        searchProject: (keyword) => {
          dispatch(searchProject(keyword));
        },
        search: (word, lastFeedId = undefined) => {
          dispatch(search(null, word, lastFeedId));
        },
      },
    };
  };

  static propTypes = {
    actions: PropTypes.object,
    projectState: PropTypes.object,
    projectDataSource: PropTypes.object,

    searchProjectState: PropTypes.object,
    searchProjectDataSource: PropTypes.array,

    searchState: PropTypes.object,
    searchDataSource: PropTypes.array,

    feedContentLayerIndex: PropTypes.number,
  };

  componentDidMount() {
    this.search();
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.params.query !== nextProps.params.query &&
      // 뒤로가기시 match.params.query가 디코딩되어서 들어올때 처리
      decodeURIComponent(this.props.params.query) !== nextProps.params.query
    ) {
      this.search(nextProps.params.query);
    }

    if (hasChangedRequestWaitingToSuccess(this.props.projectState.request, nextProps.projectState.request)) {
      getAppHistory().push(Urls.getProjectFeedPage(nextProps.projectDataSource.urlKey));
    }

    if (hasChangedRequestWaitingToFailure(this.props.projectState.request, nextProps.projectState.request)) {
      const query = nextProps.params.query;
      this.props.actions.searchProject(decodeURIComponent(query));
      this.props.actions.search(decodeURIComponent(query));
    }

    if (this.props.feedContentLayerIndex !== nextProps.feedContentLayerIndex && nextProps.feedContentLayerIndex >= 0) {
      if (
        nextProps.searchDataSource &&
        nextProps.searchDataSource.length - 2 <= nextProps.feedContentLayerIndex &&
        !nextProps.searchState.last &&
        nextProps.searchState.request === Consts.REQUEST_SUCCESS &&
        nextProps.searchDataSource.length > 0
      ) {
        const query = decodeURIComponent(nextProps.params.query);
        this.props.actions.search(query, nextProps.searchState.page + 1);
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchState.request !== this.props.searchState.request && this.props.searchState.request === Consts.REQUEST_SUCCESS) {
      this.checkNeedToLoadMore();
    }
  }

  getSearchQuery = () => {
    let query = this.props.params.query;
    query = decodeURIComponent(query).trim();
    return query;
  };

  search(query) {
    if (query === undefined) {
      query = this.props.params.query;
    }

    query = decodeURIComponent(query).trim();
    if (isURL(query)) {
      this.props.actions.getProjectWithRss(query);
    } else {
      this.props.actions.searchProject(query);
      this.props.actions.search(query);
    }
  }

  renderSearchResults = () => {
    const query = this.getSearchQuery();
    return (
      <div>
        {this.props.searchProjectState.request === Consts.REQUEST_SUCCESS &&
        this.props.searchProjectState.keyword === query &&
        this.props.searchProjectDataSource &&
        this.props.searchProjectDataSource.length > 0 ? (
          <div className="margin-bottom-30">
            <p style={styles.searchResultHeader}>MATCHING SOURCES</p>
            <div className="padding-left-10">
              <ProjectList dataSource={this.props.searchProjectDataSource} />
            </div>
          </div>
        ) : undefined}
        {isURL(this.getSearchQuery()) ? (
          <RaisedButton onClick={this.onRssBuildLinkClick} icon={<AddIcon />} label={Strings.RSS_GENERATOR} fullWidth primary />
        ) : undefined}
        {this.props.searchState.word === query && this.props.searchDataSource ? (
          <div>
            <p style={[styles.searchResultHeader, styles.matchingFeeds]}>MATCHING FEEDS</p>
            <div className="padding-left-10">
              <FeedItemList showViewModeSelector={true} feeds={this.props.searchDataSource} />
            </div>
          </div>
        ) : undefined}
      </div>
    );
  };

  onScroll = (e) => {
    this.checkNeedToLoadMore();
  };

  checkNeedToLoadMore = () => {
    const element = this.refs.container;
    const query = decodeURIComponent(this.props.params.query);
    if (!element) {
      return;
    }

    if (element.scrollHeight - element.clientHeight - 100 <= element.scrollTop) {
      if (!this.props.searchState.last && this.props.searchState.request === Consts.REQUEST_SUCCESS) {
        const lastItem = this.props.searchDataSource ? this.props.searchDataSource[this.props.searchDataSource.length - 1] : undefined;
        this.props.actions.search(query, lastItem ? lastItem.id : undefined);
      }
    }
  };

  onRssBuildLinkClick = () => {
    const query = this.getSearchQuery();
    const state = {};
    if (query && isURL(query)) {
      state.url = query;
    }

    const history = getAppHistory();
    history.push(Urls.RSS_BUILD, state);
  };

  render() {
    if (this.props.projectState.request === Consts.REQUEST_WAITING || this.props.searchProjectState.request === Consts.REQUEST_WAITING) {
      return <LoaderPage />;
    }

    const searchFailed =
      (this.props.searchProjectState.request === Consts.REQUEST_FAILURE ||
        (this.props.searchProjectDataSource && this.props.searchProjectDataSource.length <= 0)) &&
      this.props.searchState.request !== Consts.REQUEST_WAITING &&
      (!this.props.searchDataSource || this.props.searchDataSource.length <= 0);

    return (
      <div style={styles.container} onScroll={this.onScroll} ref="container">
        <div className="center-align-page">
          {searchFailed ? (
            <div className="text-align-center">
              <p>{this.props.searchState.errorMessage}</p>
              {isURL(this.getSearchQuery()) ? (
                <RaisedButton onClick={this.onRssBuildLinkClick} icon={<AddIcon />} label={Strings.RSS_GENERATOR} fullWidth primary />
              ) : undefined}
            </div>
          ) : (
            this.renderSearchResults()
          )}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    height: "100%",
    overflowY: "auto",
  },
  searchResultHeader: {
    userSelect: "none",
    fontWeight: "bold",
    marginBottom: 10,
    fontSize: 13,
    color: "#757575",
  },
  marginTop30: { marginTop: 30 },
  matchingFeeds: {
    transform: "translateY(37px)",
  },
};

export default connect(SearchResultPage.StateToProps, SearchResultPage.DispatchToProps)(withRouter(Radium(SearchResultPage)));
