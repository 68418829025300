/**
 * Created by kimchangduk on 2017-04-23.
 */

import axios from 'axios';
import {ActionTypes, Strings} from '../constants';
import {getMessageFromResponse} from '../Utils';
import {getUser} from '../actions/user';
import {processHttpError} from '../actions/commons';

export function signup(username, email, password) {
    return (dispatch) => {
        dispatch({type: ActionTypes.SIGNUP_REQUEST});
        return axios.post('/v1/account', {username, email, password}).then(response => {
            dispatch({type: ActionTypes.SIGNUP_SUCCESS});
            getUser()(dispatch);
        }).catch(error => {
            processHttpError(dispatch, error);
            let errorMessage = getMessageFromResponse(error.response, Strings.FAIL_TO_SIGN_UP);
            dispatch({type: ActionTypes.SIGNUP_FAILURE, errorMessage: errorMessage});
        });
    };
}