/**
 * Created by kimchangduk on 2017-05-22.
 */

import React from "react";
import Radium from "radium";
import { connect } from "react-redux";
import { showSnackbar } from "../actions/globalSnackbar";
import { Consts, Strings } from "../constants";
import PropTypes from "prop-types";
import IconButton from "material-ui/IconButton";
import DeleteIcon from "material-ui/svg-icons/action/delete-forever";
import { dataURLToBlob } from "blob-util";

class ImageInput extends React.Component {
  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        showSnackbar: (text) => {
          dispatch(showSnackbar(text));
        },
      },
    };
  };

  static propTypes = {
    defaultImage: PropTypes.string,
    image: PropTypes.string,
    actions: PropTypes.object,
    onChange: PropTypes.func,
  };

  showSnackbar(text) {
    this.props.actions.showSnackbar(text);
  }

  profileClick = () => {
    this.refs.profileInput.click();
  };

  onProfileInputChange = (e) => {
    const files = e.target.files;
    if (!files && files.length === 0) return;
    const file = files[0];
    e.target.value = "";

    if (file.size > Consts.PROFILE_IMAGE_SIZE) {
      const availableSize = Consts.PROFILE_IMAGE_SIZE / Consts.KB;
      this.showSnackbar(Strings.getLargeImageFileError(availableSize));
      return;
    }

    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      const dataURL = e.target.result;

      dataURLToBlob(dataURL)
        .then((blob) => {
          this.callOnChange(file, dataURL);
        })
        .catch(() => {
          this.showSnackbar(Strings.FAILED_TO_READ_FILE);
        });
    };
    fileReader.readAsDataURL(file);
  };

  callOnChange(file, url) {
    if (this.props.onChange) this.props.onChange(file, url);
  }

  onDeleteProfileClick = () => {
    this.callOnChange(null, null);
  };

  render() {
    return (
      <div style={styles.profileContainer}>
        <div
          onClick={this.profileClick}
          style={[styles.profileImage, { backgroundImage: `url("${this.props.image ? this.props.image : this.props.defaultImage}")` }]}
        >
          <input style={styles.profileInput} type="file" ref="profileInput" onChange={this.onProfileInputChange} accept="image/*" />
        </div>
        {this.props.image ? (
          <IconButton onClick={this.onDeleteProfileClick} iconStyle={styles.deleteIcon} style={styles.deleteButton}>
            <DeleteIcon />
          </IconButton>
        ) : undefined}
      </div>
    );
  }
}

const styles = {
  profileContainer: {
    position: "relative",
    display: "inline-block",
    margin: "auto",
  },
  profileInput: {
    display: "none",
  },
  deleteIcon: {
    width: 25,
    height: 25,
  },
  deleteButton: {
    position: "absolute",
    right: 5,
    top: 5,
    width: 32,
    height: 32,
    padding: 0,
    background: "#FFFFFF",
    border: "1px solid rgba(0,0,0,0.4)",
    borderRadius: "100%",
  },
  profileImage: {
    position: "relative",
    width: 150,
    height: 150,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "inline-block",
    borderRadius: 10,
    border: "1px solid #cccccc",
    cursor: "pointer",
  },
};

export default connect(null, ImageInput.DispatchToProps)(Radium(ImageInput));
