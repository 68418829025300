import GA4React from "ga-4-react";

const GA_ENABLED = true;

const measureId = "G-5B6X5G9T8H";
const ga4react = new GA4React(measureId);

class GA {
  initialized = false;
  userId = null;
  initialize = () => {
    if (GA_ENABLED) {
      console.info("ga4 initialized");
      ga4react.initialize().then((ga4) => {
        if (this.userId !== null) {
          this.setUserId(this.userId);
        }
        this.initialized = true;
        ga4.pageview(window.location.pathname + window.location.search);
      });
    }
  };

  logPage = () => {
    if (GA_ENABLED && this.initialized) {
      ga4react.pageview(window.location.pathname + window.location.search);
    }
  };

  setUserId = (userId) => {
    this.userId = userId;
    if (GA_ENABLED && this.initialized) {
      ga4react.gtag("config", measureId, {
        user_id: userId,
      });
    }
  };
}

const singleton = new GA();

export default singleton;
