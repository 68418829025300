/**
 * Created by kimchangduk on 2017-04-23.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Consts, Strings, Urls } from "../constants";
import DialogManager from "../dialogs/DialogManager";
import { showSnackbar } from "../actions/globalSnackbar";
import EmailVerificationRequireDialog from "../dialogs/EmailVerificationRequireDialog";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { EmailAuthProvider, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { auth } from "../firebase";

class LoginPage extends React.Component {
  static StateToProp = (state, ownProps) => {
    return {
      loginRequest: state.user.state.loginRequest,
      user: state.user.dataSource,
      errorMessage: state.user.state.loginErrorMessage,
    };
  };

  static DispatchToProps = (dispatch) => {
    return {
      actions: {
        alert: (text) => {
          dispatch(showSnackbar(text));
        },
      },
    };
  };

  static propTypes = {
    errorMessage: PropTypes.string,
    loginRequest: PropTypes.string,
    location: PropTypes.object,
    history: PropTypes.object,
    actions: PropTypes.object,
    user: PropTypes.object,
    dispatch: PropTypes.func,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.loginRequest !== nextProps.loginRequest && nextProps.loginRequest === Consts.REQUEST_SUCCESS) {
      if (nextProps.user && !nextProps.user.emailVerified) {
        DialogManager.push(EmailVerificationRequireDialog);
        this.props.history.push(Urls.PROFILE);
      } else if (this.props.location && this.props.location.state && this.props.location.state.redirectionPathname) {
        this.props.history.push(this.props.location.state.redirectionPathname, this.props.location.state.redirectionState);
      } else {
        this.props.history.push("/");
      }
    } else if (Consts.hasChangedRequestWaitingToFailure(this.props.loginRequest, nextProps.loginRequest)) {
      this.props.actions.alert(nextProps.errorMessage);
    }
  }

  render() {
    if (this.props.location && this.props.location.state && this.props.location.state.redirectionPathname) {
      uiConfig.signInSuccessUrl = `${Urls.WAIT_AUTH_CHANGE}?redirect=${encodeURIComponent(this.props.location.state.redirectionPathname)}`;
    } else {
      uiConfig.signInSuccessUrl = Urls.WAIT_AUTH_CHANGE;
    }

    return (
      <div onKeyDown={this.onKeyDown} style={styles.container}>
        <div style={styles.firebaseAuth}>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </div>
      </div>
    );
  }
}

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/v1/login?type=firebase",
  // We will display Google and Facebook as auth providers.
  signInOptions: [EmailAuthProvider.PROVIDER_ID, GoogleAuthProvider.PROVIDER_ID, FacebookAuthProvider.PROVIDER_ID],
};

const styles = {
  container: {
    position: "absolute",
    top: Consts.APPBAR_HEIGHT,
    left: 0,
    right: 0,
    bottom: Consts.IS_MOBILE ? Consts.TAB_PAGER_HEIGHT : 0,
  },
  firebaseAuth: {
    position: "absolute",
    margin: "auto",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
};

export default connect(LoginPage.StateToProp, LoginPage.DispatchToProps)(LoginPage);
