/**
 * Created by kimchangduk on 2018-03-27.
 */

import React from 'react';
import PropTypes from 'prop-types';
import "./NotFoundPage.scss";
import {Link} from "react-router-dom";
import {Strings, Consts} from '../constants';

class NotFoundPage extends React.Component {
    static propTypes = {type: PropTypes.number};
    static defaultProps = {type: 1};

    render() {
        return (<div className={`page-404 ${Consts.IS_MOBILE ? 'mobile' : ''}`}>
            <div className="container">
                <div className="boo-wrapper">
                    <div className="boo">
                        <div className="face"/>
                    </div>
                    <div className="shadow"/>

                    <h1>404</h1>
                    <p>
                        {Strings.PAGE_404_SUBTITLE}
                    </p>
                    <Link className="homepage-button" to="/">{Strings.PAGE_404_BUTTON}</Link>
                </div>
            </div>
        </div>);
    }
}

export default NotFoundPage;