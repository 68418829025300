/**
 * Created by kimchangduk on 2017-04-24.
 */
import { ActionTypes, Strings } from "../constants";
import axios from "axios";
import { callNotificationPermission, getMessageFromResponse } from "../Utils";
import { processHttpError } from "../actions/commons";
import queryString from "query-string";

export function getProjectWithRss(rssUrl) {
  return (dispatch) => {
    const requestedAt = Date.now();
    dispatch({ type: ActionTypes.GET_PROJECT_REQUEST, requestedAt });
    axios
      .get("/v1/project/rss", { params: { link: rssUrl } })
      .then((response) => {
        dispatch({ type: ActionTypes.GET_PROJECT_SUCCESS, data: response.data, requestedAt });
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.GET_PROJECT_FAILURE,
          error: error,
          errorMessage: getMessageFromResponse(error.response, Strings.FAILED_TO_GET_PROJECT),
          requestedAt,
        });
      });
  };
}

export function getProjectWithUrlKey(urlKey) {
  const requestedAt = Date.now();
  console.log(requestedAt);
  return (dispatch) => {
    dispatch({
      type: ActionTypes.GET_PROJECT_REQUEST,
      requestedAt,
      urlKey,
    });
    axios
      .get("/v1/project", { params: { urlKey } })
      .then((response) => {
        dispatch({
          type: ActionTypes.GET_PROJECT_SUCCESS,
          data: response.data,
          urlKey,
          requestedAt,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.GET_PROJECT_FAILURE,
          error: error,
          errorMessage: getMessageFromResponse(error.response, Strings.FAILED_TO_GET_PROJECT),
          requestedAt,
          urlKey,
        });
      });
  };
}

export function searchProject(keyword) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SEARCH_PROJECT_REQUEST, keyword });
    axios
      .get("/v1/project/search", { params: { keyword } })
      .then((response) => {
        dispatch({ type: ActionTypes.SEARCH_PROJECT_SUCCESS, data: response.data, keyword });
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.SEARCH_PROJECT_FAILURE,
          error: error,
          errorMessage: getMessageFromResponse(error.response, Strings.NO_SEARCH_RESULT),
          keyword,
        });
      });
  };
}
