/**
 * Created by kimchangduk on 2017-04-24.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  state: {
    request: Consts.REQUEST_NONE,
    error: null,
    errorMessage: "",
    urlKey: -1,
    lastFeedId: -1,
    word: null,
    last: false,
  },
  dataSource: null,
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SEARCH_REQUEST: {
      const updateValue = {
        state: {
          request: { $set: Consts.REQUEST_WAITING },
          errorMessage: { $set: null },
          error: { $set: null },

          word: { $set: action.word },
          urlKey: { $set: action.urlKey },
          lastFeedId: { $set: action.lastFeedId },
          last: { $set: false },
        },
      };

      if (state.state.word !== action.word || state.state.urlKey !== action.urlKey) {
        updateValue.dataSource = { $set: null };
      }
      return update(state, updateValue);
    }

    case ActionTypes.SEARCH_SUCCESS: {
      if (action.word !== state.state.word || action.lastFeedId !== state.state.lastFeedId || action.urlKey !== state.state.urlKey) {
        return state;
      }
      const updateValue = { state: { request: { $set: Consts.REQUEST_SUCCESS } } };
      const data = action.data;
      if (action.lastFeedId !== undefined && state.dataSource) {
        updateValue.dataSource = { $push: data };
      } else {
        updateValue.dataSource = { $set: data };
      }

      return update(state, updateValue);
    }

    case ActionTypes.SEARCH_FAILURE: {
      if (action.word !== state.state.word || action.lastFeedId !== state.state.lastFeedId || action.urlKey !== state.state.urlKey) {
        return state;
      }

      const updateValue = {
        state: {
          request: { $set: Consts.REQUEST_FAILURE },
          error: { $set: action.error },
          errorMessage: { $set: action.errorMessage },
        },
      };

      if (action.error && action.error.response && action.error.response.status === 404) {
        updateValue.state.last = { $set: true };
        updateValue.state.request = { $set: Consts.REQUEST_SUCCESS };
      }

      return update(state, updateValue);
    }

    default:
      return state;
  }
}
