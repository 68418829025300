import axios from "axios";
import {Consts, ActionTypes, Strings} from "../constants";
import {getMessageFromResponse} from "../Utils";
import {processHttpError} from "./commons";
import queryString from 'query-string';

/**
 * /v1/feed/all
 * @param lastFeedId
 * @returns {Function}
 */
export function getAllFeeds(lastFeedId = 0) {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_FEED_TOTAL_LIST_REQUEST, lastFeedId});
        axios.get('/v1/feed/all', {params: {id: lastFeedId}}).then(response => {
            dispatch({type: ActionTypes.GET_FEED_TOTAL_LIST_SUCCESS, data: response.data, lastFeedId});
        }).catch(error => {
            dispatch({
                type: ActionTypes.GET_FEED_TOTAL_LIST_FAILURE,
                errorMessage: getMessageFromResponse(error.response, Strings.FAILED_TO_GET_FEEDS),
                error,
                lastFeedId
            });
        });
    };
}

/**
 * /v1/feed/subscribes
 * @param lastFeedId
 * @returns {Function}
 */
export function getSubscribingFeeds(lastFeedId = undefined) {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_FEED_TOTAL_LIST_REQUEST, lastFeedId: lastFeedId});
        const parameter = lastFeedId !== undefined ? {params: {id: lastFeedId}} : undefined;
        axios.get('/v1/feed/subscribes', parameter).then(response => {
            dispatch({type: ActionTypes.GET_FEED_TOTAL_LIST_SUCCESS, data: response.data, lastFeedId: lastFeedId});
        }).catch(error => {
            dispatch({
                type: ActionTypes.GET_FEED_TOTAL_LIST_FAILURE,
                errorMessage: getMessageFromResponse(error.response, Strings.FAILED_TO_GET_FEEDS),
                error,
                lastFeedId
            });
        });
    };
}

/**
 * /v1/feed/project
 * @param urlKey
 * @param lastFeedId
 * @returns {Function}
 */
export function getProjectFeeds(urlKey, lastFeedId = undefined) {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_PROJECT_FEED_LIST_REQUEST, lastFeedId, urlKey});
        const parameter = {params: {urlKey}};
        if (lastFeedId !== undefined) {
            parameter.params.id = lastFeedId;
        }
        axios.get('/v1/feed/project', parameter).then(response => {
            dispatch({type: ActionTypes.GET_PROJECT_FEED_LIST_SUCCESS, data: response.data, lastFeedId, urlKey});
        }).catch(error => {
            dispatch({
                type: ActionTypes.GET_PROJECT_FEED_LIST_FAILURE,
                lastFeedId: lastFeedId,
                urlKey,
                error,
                errorMessage: getMessageFromResponse(error.response, Strings.FAILED_TO_GET_FEEDS)
            });
        });
    };
}

/**
 * /v1/feed?id=...
 * @param ids
 * @returns {Function}
 */
export function getFeedsById(ids) {
    return dispatch => {
        dispatch({
            type: ActionTypes.GET_FEEDS_BY_ID_REQUEST,
            ids
        });
        axios.get(`/v1/feed?${queryString.stringify({id: ids})}`).then(response => {
            dispatch({
                type: ActionTypes.GET_FEEDS_BY_ID_SUCCESS,
                data: response.data,
                ids
            });
        }).catch(error => {
            dispatch({
                type: ActionTypes.GET_FEEDS_BY_ID_FAILURE,
                error: error,
                errorMessage: getMessageFromResponse(error.response, Strings.FAILED_TO_GET_FEEDS),
                ids
            });
        });
    };
}