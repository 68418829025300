import {Consts} from './constants';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {grey900} from 'material-ui/styles/colors';

const themeDefault = getMuiTheme({
    palette: {},
    appBar: {
        height: Consts.APPBAR_HEIGHT,
        color: Consts.PRIMARY_COLOR
    },
    tabs: {
        backgroundColor: '#f9f9f9',
        selectedTextColor: 'rgba(77,63,54)',
        textColor: 'rgba(212,212,212)'
    },
    drawer: {
        width: 230,
        color: grey900
    },
    textField: {
        focusColor: '#3F51B5',
        disabledTextColor: Consts.DISABLED_TEXT_COLOR
    },
    raisedButton: {
        primaryColor: Consts.PRIMARY_COLOR,
    },
    fontFamily: 'Nanum Gothic',
    checkbox: {
        checkedColor: '#3F51B5'
    }
});


export default themeDefault;