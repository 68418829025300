/**
 * Created by kimchangduk on 2017-04-23.
 */

//<editor-fold desc="User">
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';
//</editor-fold>

//<editor-fold desc="Project">
export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAILURE = 'GET_PROJECT_FAILURE';

export const SEARCH_PROJECT_REQUEST = 'SEARCH_PROJECT_REQUEST';
export const SEARCH_PROJECT_SUCCESS = 'SEARCH_PROJECT_SUCCESS';
export const SEARCH_PROJECT_FAILURE = 'SEARCH_PROJECT_FAILURE';
//</editor-fold>

//<editor-fold desc="GlobalSnackbar">
export const SNACKBAR_OEPN = 'SNACKBAR_OEPN';
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE';
//</editor-fold>

//<editor-fold desc="Project News List">
export const CLEAR_PROJECT_NEWS_PAGE = 'CLEAR_PROJECT_NEWS_PAGE';
export const GET_PROJECT_NEWS_PAGE_REQUEST = 'GET_PROJECT_NEWS_PAGE_REQUEST';
export const GET_PROJECT_NEWS_PAGE_SUCCESS = 'GET_PROJECT_NEWS_PAGE_SUCCESS';
export const GET_PROJECT_NEWS_PAGE_FAILURE = 'GET_PROJECT_NEWS_PAGE_FAILURE';

export const GET_PROJECT_NEWS_DETAIL_PAGE_REQUEST = 'GET_PROJECT_NEWS_DETAIL_PAGE_REQUEST';
export const GET_PROJECT_NEWS_DETAIL_PAGE_SUCCESS = 'GET_PROJECT_NEWS_DETAIL_PAGE_SUCCESS';
export const GET_PROJECT_NEWS_DETAIL_PAGE_FAILURE = 'GET_PROJECT_NEWS_DETAIL_PAGE_FAILURE';

export const GET_PROJECT_NEWS_DETAIL_PAGE_NEXT_REQUEST = 'GET_PROJECT_NEWS_DETAIL_PAGE_NEXT_REQUEST';
export const GET_PROJECT_NEWS_DETAIL_PAGE_NEXT_SUCCESS = 'GET_PROJECT_NEWS_DETAIL_PAGE_NEXT_SUCCESS';
export const GET_PROJECT_NEWS_DETAIL_PAGE_NEXT_FAILURE = 'GET_PROJECT_NEWS_DETAIL_PAGE_NEXT_FAILURE';

export const GET_PROJECT_NEWS_DETAIL_PAGE_PREV_REQUEST = 'GET_PROJECT_NEWS_DETAIL_PAGE_PREV_REQUEST';
export const GET_PROJECT_NEWS_DETAIL_PAGE_PREV_SUCCESS = 'GET_PROJECT_NEWS_DETAIL_PAGE_PREV_SUCCESS';
export const GET_PROJECT_NEWS_DETAIL_PAGE_PREV_FAILURE = 'GET_PROJECT_NEWS_DETAIL_PAGE_PREV_FAILURE';

export const DELETE_PROJECT_NEWS_BY_ID_REQUEST = 'DELETE_PROJECT_NEWS_BY_ID_REQUEST';
export const DELETE_PROJECT_NEWS_BY_ID_SUCCESS = 'DELETE_PROJECT_NEWS_BY_ID_SUCCESS';
export const DELETE_PROJECT_NEWS_BY_ID_FAILURE = 'DELETE_PROJECT_NEWS_BY_ID_FAILURE';

export const DELETE_PROJECT_NEWS_REQUEST = 'DELETE_PROJECT_NEWS_REQUEST';
export const DELETE_PROJECT_NEWS_SUCCESS = 'DELETE_PROJECT_NEWS_SUCCESS';
export const DELETE_PROJECT_NEWS_FAILURE = 'DELETE_PROJECT_NEWS_FAILURE';
//</editor-fold>

//<editor-fold desc="Board">
export const GET_BOARD_POST_LIST_REQUEST = 'GET_BOARD_POST_LIST_REQUEST';
export const GET_BOARD_POST_LIST_SUCCESS = 'GET_BOARD_POST_LIST_SUCCESS';
export const GET_BOARD_POST_LIST_FAILURE = 'GET_BOARD_POST_LIST_FAILURE';

export const GET_BOARD_POST_VIEW_REQUEST = 'GET_BOARD_POST_VIEW_REQUEST';
export const GET_BOARD_POST_VIEW_SUCCESS = 'GET_BOARD_POST_VIEW_SUCCESS';
export const GET_BOARD_POST_VIEW_FAILURE = 'GET_BOARD_POST_VIEW_FAILURE';

export const POST_COMMENT_REQUEST = 'POST_COMMENT_REQUEST';
export const POST_COMMENT_SUCCESS = 'POST_COMMENT_SUCCESS';
export const POST_COMMENT_FAILURE = 'POST_COMMENT_FAILURE';

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';
//</editor-fold>

//<editor-fold desc="Timeline">
export const GET_TIMELINE_REQUEST = 'GET_TIMELINE_REQUEST';
export const GET_TIMELINE_SUCCESS = 'GET_TIMELINE_SUCCESS';
export const GET_TIMELINE_FAILURE = 'GET_TIMELINE_FAILURE';

export const POLL_TIMELINE_UPDATE_REQUEST = 'POLL_TIMELINE_UPDATE_REQUEST';
export const POLL_TIMELINE_UPDATE_SUCCESS = 'POLL_TIMELINE_UPDATE_SUCCESS';
export const POLL_TIMELINE_UPDATE_FAILURE = 'POLL_TIMELINE_UPDATE_FAILURE';

export const POLL_TAG_TIMELINE_UPDATE_REQUEST = 'POLL_TAG_TIMELINE_UPDATE_REQUEST';
export const POLL_TAG_TIMELINE_UPDATE_SUCCESS = 'POLL_TAG_TIMELINE_UPDATE_SUCCESS';
export const POLL_TAG_TIMELINE_UPDATE_FAILURE = 'POLL_TAG_TIMELINE_UPDATE_FAILURE';

export const GET_TAG_TIMELINE_REQUEST = 'GET_TAG_TIMELINE_REQUEST';
export const GET_TAG_TIMELINE_SUCCESS = 'GET_TAG_TIMELINE_SUCCESS';
export const GET_TAG_TIMELINE_FAILURE = 'GET_TAG_TIMELINE_FAILURE';

export const GET_NEWS_TIMELINE_REQUEST = 'GET_NEWS_TIMELINE_REQUEST';
export const GET_NEWS_TIMELINE_SUCCESS = 'GET_NEWS_TIMELINE_SUCCESS';
export const GET_NEWS_TIMELINE_FAILURE = 'GET_NEWS_TIMELINE_FAILURE';

export const GET_NEWS_TIMELINE_NEXT_REQUEST = 'GET_NEWS_TIMELINE_NEXT_REQUEST';
export const GET_NEWS_TIMELINE_NEXT_SUCCESS = 'GET_NEWS_TIMELINE_NEXT_SUCCESS';
export const GET_NEWS_TIMELINE_NEXT_FAILURE = 'GET_NEWS_TIMELINE_NEXT_FAILURE';

export const GET_NEWS_TIMELINE_PREV_REQUEST = 'GET_NEWS_TIMELINE_PREV_REQUEST';
export const GET_NEWS_TIMELINE_PREV_SUCCESS = 'GET_NEWS_TIMELINE_PREV_SUCCESS';
export const GET_NEWS_TIMELINE_PREV_FAILURE = 'GET_NEWS_TIMELINE_PREV_FAILURE';
//</editor-fold>

//<editor-fold desc="Keyword">
export const GET_KEYWORD_RANK_REQUEST = 'GET_KEYWORD_RANK_REQUEST';
export const GET_KEYWORD_RANK_SUCCESS = 'GET_KEYWORD_RANK_SUCCESS';
export const GET_KEYWORD_RANK_FAILURE = 'GET_KEYWORD_RANK_FAILURE';

export const GET_KEYWORD_SEARCH_REQUEST = 'GET_KEYWORD_SEARCH_REQUEST';
export const GET_KEYWORD_SEARCH_SUCCESS = 'GET_KEYWORD_SEARCH_SUCCESS';
export const GET_KEYWORD_SEARCH_FAILURE = 'GET_KEYWORD_SEARCH_FAILURE';
//</editor-fold>

//<editor-fold desc="Feed">
export const GET_FEED_TOTAL_LIST_REQUEST = 'GET_FEED_TOTAL_LIST_REQUEST';
export const GET_FEED_TOTAL_LIST_SUCCESS = 'GET_FEED_TOTAL_LIST_SUCCESS';
export const GET_FEED_TOTAL_LIST_FAILURE = 'GET_FEED_TOTAL_LIST_FAILURE';

export const GET_PROJECT_FEED_LIST_REQUEST = 'GET_PROJECT_FEED_LIST_REQUEST';
export const GET_PROJECT_FEED_LIST_SUCCESS = 'GET_PROJECT_FEED_LIST_SUCCESS';
export const GET_PROJECT_FEED_LIST_FAILURE = 'GET_PROJECT_FEED_LIST_FAILURE';

export const GET_FEEDS_BY_ID_REQUEST = 'GET_FEEDS_BY_ID_REQUEST';
export const GET_FEEDS_BY_ID_SUCCESS = 'GET_FEEDS_BY_ID_SUCCESS';
export const GET_FEEDS_BY_ID_FAILURE = 'GET_FEEDS_BY_ID_FAILURE';
//</editor-fold>

//<editor-fold desc="Search">
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';

export const PROJECT_FEED_SEARCH_REQUEST = 'PROJECT_FEED_SEARCH_REQUEST';
export const PROJECT_FEED_SEARCH_SUCCESS = 'PROJECT_FEED_SEARCH_SUCCESS';
export const PROJECT_FEED_SEARCH_FAILURE = 'PROJECT_FEED_SEARCH_FAILURE';
//</editor-fold>

//<editor-fold desc="Subscribe">
export const EDIT_SUBSCRIPTION_ORDER_REQUEST = 'EDIT_SUBSCRIPTION_ORDER_REQUEST';
export const EDIT_SUBSCRIPTION_ORDER_SUCCESS = 'EDIT_SUBSCRIPTION_ORDER_SUCCESS';
export const EDIT_SUBSCRIPTION_ORDER_FAILURE = 'EDIT_SUBSCRIPTION_ORDER_FAILURE';

export const GET_SUBSCRIBE_LIST_REQUEST = 'GET_SUBSCRIBE_LIST_REQUEST';
export const GET_SUBSCRIBE_LIST_SUCCESS = 'GET_SUBSCRIBE_LIST_SUCCESS';
export const GET_SUBSCRIBE_LIST_FAILURE = 'GET_SUBSCRIBE_LIST_FAILURE';

export const SUBSCRIBE_CHANGE_REQUEST = 'SUBSCRIBE_CHANGE_REQUEST';
export const SUBSCRIBE_CHANGE_SUCCESS = 'SUBSCRIBE_CHANGE_SUCCESS';
export const SUBSCRIBE_CHANGE_FAILURE = 'SUBSCRIBE_CHANGE_FAILURE';

export const ADD_NOTIFY_KEYWORD_REQUEST = 'ADD_NOTIFY_KEYWORD_REQUEST';
export const ADD_NOTIFY_KEYWORD_SUCCESS = 'ADD_NOTIFY_KEYWORD_SUCCESS';
export const ADD_NOTIFY_KEYWORD_FAILURE = 'ADD_NOTIFY_KEYWORD_FAILURE';

export const DELETE_NOTIFY_KEYWORD_REQUEST = 'DELETE_NOTIFY_KEYWORD_REQUEST';
export const DELETE_NOTIFY_KEYWORD_SUCCESS = 'DELETE_NOTIFY_KEYWORD_SUCCESS';
export const DELETE_NOTIFY_KEYWORD_FAILURE = 'DELETE_NOTIFY_KEYWORD_FAILURE';
//</editor-fold>

//<editor-fold desc="Feed Layer">
export const OPEN_FEED_LAYER = 'OPEN_FEED_LAYER';
export const CLOSE_FEED_LAYER_REQUEST = 'CLOSE_FEED_LAYER_REQUEST';
export const CLOSE_FEED_LAYER = 'CLOSE_FEED_LAYER';
export const SET_FEED_LAYER_INDEX = 'SET_FEED_LAYER_INDEX';
export const SET_FEED_LAYER_FEEDS = 'SET_FEED_LAYER_FEEDS';
//</editor-fold>