/**
 * Created by kimchangduk on 2017-05-26.
 */

import React from "react";
import { getAppHistory } from "../Utils";
import { Consts, Strings, Urls } from "../constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showSnackbar } from "../actions/globalSnackbar";
import axios from "axios";
import querystring from "query-string";

class OAuthCallbackPage extends React.Component {
  static DispatchToProps = (dispatch) => {
    return {
      actions: {
        alert: (message) => {
          dispatch(showSnackbar(message));
        },
      },
    };
  };

  static propTypes = {
    match: PropTypes.object,
    actions: PropTypes.object,
  };

  componentDidMount() {
    this.requestAccessToken();
  }

  requestAccessToken() {
    const queryParams = querystring.parse(window.location.search);
    axios
      .post(API_URL.replace("{id}", this.props.match.params.id), { code: queryParams.code })
      .then((response) => {
        this.props.actions.alert(Strings.SUCCESS_TO_GET_ACCESS_TOKEN);
        getAppHistory().push(Urls.PROFILE);
      })
      .catch((error) => {
        this.props.actions.alert(Strings.FAILED_TO_GET_ACCESS_TOKEN);
        getAppHistory().push(Urls.PROFILE);
      });
  }

  render() {
    return <div />;
  }
}

const API_URL = "/v1/social/{id}";

export default connect(null, OAuthCallbackPage.DispatchToProps)(OAuthCallbackPage);
