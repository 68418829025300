/**
 **/
import axios from "axios";

export function writeContact(email: string, title: string, content: string) {
  return axios.post(`/v1/board/contact`, { email, title, content });
}

export function addFolder(name: string) {
  return axios.post("/v1/subscribe/folder", { name });
}

export function genRss(rss: {
  isPublic: boolean;
  title: string;
  description: string;
  url: string;
  itemXpath: string;
  titleXpath: string;
  thumbnailXpath: string;
  linkXpath: string;
}) {
  return axios.post(`/v1/rssgen`, rss, {
    headers: {
      ["Content-Type"]: "application/json",
    },
    transformRequest: (data) => {
      if (data) {
        return JSON.stringify(data);
      }
      return data;
    },
  });
}

export function editRss(
  uuid: string,
  rss: {
    isPublic: boolean;
    title: string;
    description: string;
    url: string;
    itemXpath: string;
    titleXpath: string;
    thumbnailXpath: string;
    linkXpath: string;
  }
) {
  return axios.put(`/v1/rssgen/${uuid}`, rss, {
    headers: {
      ["Content-Type"]: "application/json",
    },
    transformRequest: (data) => {
      if (data) {
        return JSON.stringify(data);
      }
      return data;
    },
  });
}

export function deleteRss(uuid: string) {
  return axios.delete(`/v1/rssgen/${uuid}`);
}

export function fetchRss(uuid: string) {
  return axios.get<Generator>(`/v1/rssgen/${uuid}`);
}

export function getRssProject(link: string) {
  return axios.get<{
    urlKey: string;
    image: string;
    type: "RSS";
    link: string;
    title: string;
    description: string | null;
    subscribersCount: number;
  }>(`/v1/project/rss`, {
    params: {
      link,
    },
  });
}

export interface Generator {
  id: number;
  uuid: string;
  userId: number;
  isPublic: true;
  title: string;
  description: string;
  url: string;
  itemXpath: string;
  titleXpath: string;
  thumbnailXpath: string;
  linkXpath: string;
  createdAt: string;
  updatedAt: string;
  editedUrl: string;
  rssUrl: string;
}
