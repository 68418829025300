/**
 * Created by kimchangduk on 2018-03-10.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllFeeds, getProjectFeeds, getSubscribingFeeds } from "../actions/feed";
import { search } from "../actions/search";
import FeedItemList from "../components/FeedItemList";
import LoaderPage from "./LoaderPage";
import { Consts } from "../constants";
import Cookies from "js-cookie";

class FeedTimeline extends React.Component {
  static StateToProps = (state, ownProps) => {
    let store;
    if (ownProps.urlKey) {
      if (ownProps.searchWord) {
        store = state.search;
      } else {
        store = state.feed.project;
      }
    } else {
      store = state.feed.total;
    }

    return {
      request: store.state.request,
      errorMessage: store.state.errorMessage,
      dataSource: store.dataSource,
      last: store.state.last,
      feedContentLayerIndex: state.feedContentLayer.index,

      isLogin: state.user.state.isLogin,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getAllFeeds: (lastFeedId = undefined) => {
          dispatch(getAllFeeds(lastFeedId));
        },
        getProjectFeeds: (lastFeedId = undefined) => {
          dispatch(getProjectFeeds(decodeURIComponent(ownProps.urlKey), lastFeedId));
        },
        getSubscribingFeeds: (lastFeedId = undefined) => {
          dispatch(getSubscribingFeeds(lastFeedId));
        },
        search: (lastFeedId = undefined) => {
          dispatch(search(decodeURIComponent(ownProps.urlKey), decodeURIComponent(ownProps.searchWord), lastFeedId));
        },
      },
    };
  };

  static propTypes = {
    actions: PropTypes.object,
    request: PropTypes.string,
    errorMessage: PropTypes.string,
    dataSource: PropTypes.array,
    feedContentLayerIndex: PropTypes.number,
    last: PropTypes.bool,
    urlKey: PropTypes.string, // urlEncoded urlKey!!!
    isLogin: PropTypes.bool,
    id: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    searchWord: PropTypes.string,
  };

  state = {
    viewMode: Cookies.get(Consts.COOKIE_KEY_FEED_ITEM_VIEW_TYPE) ? Cookies.get(Consts.COOKIE_KEY_FEED_ITEM_VIEW_TYPE) : Consts.FeedItemViewTypes.THUMBNAIL,
  };

  componentDidMount() {
    this.getFeeds();
    if (this.refs.container) {
      this.refs.container.addEventListener("scroll", this.onScroll);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isLogin !== nextProps.isLogin) {
      this.getFeeds(undefined, nextProps);
    }

    if (this.props.feedContentLayerIndex !== nextProps.feedContentLayerIndex && nextProps.feedContentLayerIndex >= 0) {
      if (
        nextProps.dataSource &&
        nextProps.dataSource.length - 2 <= nextProps.feedContentLayerIndex &&
        !nextProps.last &&
        nextProps.request === Consts.REQUEST_SUCCESS &&
        nextProps.dataSource.length > 0
      ) {
        const lastItem = nextProps.dataSource[nextProps.dataSource.length - 1];
        this.getFeeds(lastItem.id);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.urlKey !== this.props.urlKey || prevProps.searchWord !== this.props.searchWord) {
      this.getFeeds();
    }

    if ((prevProps.request !== this.props.request && this.props.request === Consts.REQUEST_SUCCESS) || this.state.viewMode !== prevState.viewMode) {
      this.checkNeedToLoadMore();
    }
  }

  componentWillUnmount() {
    if (this.refs.container) {
      this.refs.container.removeEventListener("scroll", this.onScroll);
    }
  }

  getFeeds = (lastFeedId = undefined, props = undefined) => {
    if (props === undefined) {
      props = this.props;
    }

    if (props.urlKey) {
      if (props.searchWord) {
        this.props.actions.search(lastFeedId);
      } else {
        this.props.actions.getProjectFeeds(lastFeedId);
      }
    } else {
      if (props.isLogin) {
        this.props.actions.getSubscribingFeeds(lastFeedId);
      } else {
        this.props.actions.getAllFeeds(lastFeedId);
      }
    }
  };

  onScroll = (e) => {
    this.checkNeedToLoadMore();
  };

  checkNeedToLoadMore = () => {
    const element = this.refs.container;
    if (!element) {
      return;
    }

    if (element.scrollHeight - element.clientHeight - 100 <= element.scrollTop) {
      if (!this.props.last && this.props.request === Consts.REQUEST_SUCCESS && this.props.dataSource.length > 0) {
        const lastItem = this.props.dataSource ? this.props.dataSource[this.props.dataSource.length - 1] : undefined;
        this.getFeeds(lastItem ? lastItem.id : undefined);
      }
    }
  };

  onViewModeChangeRequest = (mode) => {
    this.setState({ viewMode: mode });
  };

  render() {
    return (
      <div style={styles.container} id={this.props.id} ref="container">
        <div className="center-align-page">
          {this.props.children}
          {this.props.request !== Consts.REQUEST_FAILURE ? (
            <div>
              {this.props.dataSource ? (
                <FeedItemList viewMode={this.state.viewMode} onViewModeChangeRequest={this.onViewModeChangeRequest} feeds={this.props.dataSource} />
              ) : undefined}
            </div>
          ) : undefined}
          {this.props.request === Consts.REQUEST_WAITING ? <LoaderPage /> : undefined}
          {this.props.request === Consts.REQUEST_FAILURE ? <p className="error-message">{this.props.errorMessage}</p> : undefined}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    height: "100%",
    overflowY: "auto",
  },
  subscribeButton: {
    width: "100%",
  },
};

export default connect(FeedTimeline.StateToProps, FeedTimeline.DispatchToProps)(FeedTimeline);
