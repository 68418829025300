/**
 * Created by kimchangduk on 2017-04-23.
 */

import axios from "axios";
import { processHttpError } from "./commons";
import { ActionTypes, Strings, Urls, Consts } from "../constants";
import { getMessageFromResponse, callLogoutInterface, requestFcmToken } from "../Utils";
import { showSnackbar } from "./globalSnackbar";
import Cookies from "js-cookie";
import { auth } from '../firebase'
import queryString from "query-string";

export function processLoginWithFirebaseAuth(user) {
  if (!user) {
    return;
  }

  return (dispatch) => {
    user
      .getIdToken(true)
      .then((token) => {
        axios
          .post(`/v1/oauth/firebase`, { token })
          .then((response) => {
            requestFcmToken(false);
            dispatch({ type: ActionTypes.LOGIN_SUCCESS, data: response.data });
          })
          .catch((error) => {
            processHttpError(dispatch, error);
            let errorMessage = getMessageFromResponse(error.response, Strings.FAIL_TO_LOGIN);
            dispatch({ type: ActionTypes.LOGIN_FAILURE, errorMessage: errorMessage });
          });
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.LOGIN_FAILURE,
          errorMessage: Strings.FAIL_TO_LOGIN,
        });
      });
  };
}

export function loginWithToken(username, token) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOGIN_REQUEST });
    return axios
      .post("/v1/account/token/login", { username, token })
      .then((response) => {
        dispatch({ type: ActionTypes.LOGIN_SUCCESS, data: response.data });
      })
      .catch((error) => {
        processHttpError(dispatch, error);
        let errorMessage = getMessageFromResponse(error.response, Strings.FAIL_TO_LOGIN);
        dispatch({ type: ActionTypes.LOGIN_FAILURE, errorMessage: errorMessage });
      });
  };
}

export function loginWithOAuth(provider, params) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOGIN_REQUEST });
    return axios
      .post(`/v1/oauth/${provider}`, params)
      .then((response) => {
        dispatch({ type: ActionTypes.LOGIN_SUCCESS, data: response.data });
      })
      .catch((error) => {
        processHttpError(dispatch, error);
        let errorMessage = getMessageFromResponse(error.response, Strings.FAIL_TO_LOGIN);
        dispatch({ type: ActionTypes.LOGIN_FAILURE, errorMessage: errorMessage });
      });
  };
}

export function logout(redirectUrl = Urls.DISCOVER) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOGOUT_REQUEST });

    return axios
      .get("/logout")
      .then((response) => {
        callLogoutInterface();

        // fcm 초기화
        const fcmToken = Cookies.get(Consts.COOKIE_KEY_FCM_TOKEN);
        if (fcmToken) {
          Cookies.remove(Consts.COOKIE_KEY_FCM_TOKEN);
          axios.delete("/v1/account/fcm", { params: { token: fcmToken } });
        }

        auth.signOut();
        dispatch({ type: ActionTypes.LOGOUT_SUCCESS, redirectUrl });
      })
      .catch((error) => {
        processHttpError(dispatch, error);
        dispatch({ type: ActionTypes.LOGOUT_FAILURE });
      });
  };
}

export function getUser() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_USER_REQUEST });
    return axios
      .get("/v1/account")
      .then((response) => {
        dispatch({ type: ActionTypes.GET_USER_SUCCESS, data: response.data });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.GET_USER_FAILURE, errorMessage: Strings.FAIL_TO_LOAD_USER });
      });
  };
}

export function editUser(pathParams, formdata) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.EDIT_USER_REQUEST });
    return axios
      .put(`${Urls.EDIT_USER_API}?${queryString.stringify(pathParams)}`, formdata)
      .then((response) => {
        dispatch({ type: ActionTypes.EDIT_USER_SUCCESS, data: response.data });
      })
      .catch((error) => {
        processHttpError(dispatch, error);
        dispatch({ type: ActionTypes.EDIT_USER_FAILURE, errorMessage: Strings.FAIL_TO_SAVE_USER });
      });
  };
}

export function resendEmailVerification(thenFunc, catchFunc) {
  return (dispatch) => {
    axios
      .get("/v1/account/validate/resend")
      .then((response) => {
        showSnackbar(Strings.SUCCESS_TO_RESEND_EMAIL)(dispatch);
        if (thenFunc) {
          thenFunc(response);
        }
      })
      .catch((error) => {
        processHttpError(dispatch, error);
        showSnackbar(Strings.FAILED_TO_RESEND_EMAIL)(dispatch);
        if (catchFunc) {
          catchFunc(error);
        }
      });
  };
}
