/**
 * Created by kimchangduk on 2017-04-29.
 */
import { Urls, Consts, Strings } from "./constants";
import firebase from "firebase/compat/app";
import "firebase/messaging";
import axios from "axios";
import Cookies from "js-cookie";
import { store } from "./store";
import queryString from "query-string";

let appHistory;
let _isIosApp = false;

export function extractContentFromHtml(s) {
  const span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

/**
 * @param {String} HTML representing a single element
 * @return {Node | null}
 */
export function htmlToElement(html) {
  const template = document.createElement("template");
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}

/**
 * @param {String} HTML representing a single element
 * @return {NodeList | null}
 */
export function htmlToNodeList(html) {
  const template = document.createElement("template");
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.childNodes;
}

export function capitalizeFirstLetter(string) {
  if (!string) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isDescendant(parent, child) {
  let node = child.parentNode;
  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

export function nodeListToHtml(nodeList) {
  if (!nodeList) {
    return "";
  }

  let result = "";
  for (let node of nodeList) {
    switch (node.nodeType) {
      case node.TEXT_NODE:
        if (node.nodeValue) {
          result += node.nodeValue;
        }
        break;
      case node.ELEMENT_NODE:
        result += node.outerHTML;
        break;
    }
  }
  return result;
}

export function styleArrayToObject(array) {
  if (Array.isArray(array)) {
    let newStyle = {};
    for (let i = 0; i < array.length; i++) {
      if (array[i]) {
        newStyle = Object.assign(newStyle, array[i]);
      }
    }
    return newStyle;
  } else {
    return array;
  }
}

export function setAppHistory(history) {
  appHistory = history;
}

export function getAppHistory() {
  return appHistory;
}

export function pushHistory(url, state) {
  appHistory.push(url, state);
}

export function extractPropObject(props, propTypes) {
  const newProp = {};
  if (Array.isArray(propTypes)) {
    for (let i in propTypes) {
      if (props[propTypes[i]] !== undefined) {
        newProp[propTypes[i]] = props[propTypes[i]];
      }
    }
  } else {
    for (let key in propTypes) {
      newProp[key] = props[key];
    }
  }
  return newProp;
}

export function getDateTimeString(date, format = "YYYY-MM-DD HH24:MI:SS") {
  if (typeof date === "number") {
    date = new Date(date);
  }
  return date ? date.toFormat(format) : "";
}

export function isURL(str) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return pattern.test(str);
}

export function getTimespanString(seconds) {
  let result = "";

  const hours = Math.floor(seconds / 3600);
  if (hours > 0) {
    result += `${hours}${Strings.HOURS} `;
    seconds -= hours * 3600;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes > 0) {
    result += `${minutes}${Strings.MINUTES} `;
    seconds -= minutes * 60;
  }

  seconds = Math.floor(seconds);
  if (seconds > 0) {
    result += `${seconds}${Strings.SECONDS} `;
  }
  return result.trim();
}

export function moveLoginPage() {
  if (window.android && window.android.onLogin) {
    window.android.onLogin();
  } else if (_isIosApp) {
    callObjectiveCMethod("onLogin");
  } else {
    if (appHistory) {
      const redirectionPathname = appHistory.location.pathname === Urls.DISCOVER ? "/" : appHistory.location.pathname;
      appHistory.push(Urls.LOGIN, {
        redirectionPathname: redirectionPathname,
        redirectionState: appHistory.location.state,
      });
    } else {
      window.location.ref = Urls.LOGIN;
    }
  }
}

export function moveSignUpPage(redirectionPathname, redirectionState) {
  if (window.android && window.android.onSignUp) {
    window.android.onSignUp();
  } else if (_isIosApp) {
    callObjectiveCMethod("onSignUp");
  } else {
    if (appHistory) {
      appHistory.push(Urls.SIGNUP, {
        redirectionPathname: redirectionPathname === undefined ? appHistory.location.pathname : redirectionPathname,
        redirectionState: redirectionState === undefined ? appHistory.location.state : redirectionState,
      });
    } else {
      window.location.ref = Urls.LOGIN;
    }
  }
}

export function callFeedContentLayerOpenChangedAndroidInterface(open) {
  if (window.android && window.android.onFeedContentLayerOpenChanged) {
    window.android.onFeedContentLayerOpenChanged(open);
  }
}

export function callLogoutInterface() {
  if (window.android && window.android.onLogout) {
    window.android.onLogout();
  } else if (_isIosApp) {
    callObjectiveCMethod("onLogout");
  }
}

export function callNotificationPermission() {
  if (!isMobileApp()) {
    requestFcmToken();
  } else if (_isIosApp) {
    callObjectiveCMethod("onCheckNotificationPermission");
  }
}

export function requestFcmToken(withCheckPermission = true) {
  let messaging = null;
  try {
    messaging = firebase.messaging();
  } catch (error) {
    messaging = null;
  }

  if (!messaging) {
    return;
  }

  const processGetToken = () => {
    messaging.getToken().then((currentToken) => {
      if (currentToken) {
        const prevCookie = Cookies.get(Consts.COOKIE_KEY_FCM_TOKEN);
        if (prevCookie && prevCookie !== currentToken) {
          Cookies.set(Consts.COOKIE_KEY_FCM_TOKEN, "");
          unregisterFcmToken(prevCookie);
        }
        registerFcmToken(currentToken);
      }
    });
  };

  if (withCheckPermission) {
    messaging.requestPermission().then(() => {
      processGetToken();
    });
  } else {
    processGetToken();
  }
}

export function registerFcmToken(token) {
  axios.post("/v1/account/fcm", { os: Consts.OS_WEB, token }).then((response) => {
    Cookies.set(Consts.COOKIE_KEY_FCM_TOKEN, token, { expires: 99999 });
  });
}

export function unregisterFcmToken(token) {
  axios.delete(`/v1/account/fcm?${queryString.stringify({ token })}`);
}

export function getMessageFromResponse(response, defaultMessage) {
  if (response && response.data && response.data.message !== undefined) {
    return response.data.message;
  }
  return defaultMessage;
}

export function getScrollTop() {
  return window.pageYOffset;
}

export function getBodyClientHeight() {
  return document.body.clientHeight;
}

export function getWindowHeight() {
  return document.documentElement.clientHeight;
}

export function isMobileApp() {
  return isIosApp() || isAndroidApp();
}

export function isIosApp() {
  return _isIosApp;
}

export function isAndroidApp() {
  return window.android != null;
}

export function isMobileScreen() {
  const size = [480, 812];
  const { outerWidth, outerHeight } = window;
  return (outerWidth < size[0] && outerHeight < size[1]) || (outerWidth < size[1] && outerHeight < size[0]);
}

function callObjectiveCMethod(method) {
  window.location = `notihub://${method}`;
}

window.ios = {
  checkIn: () => {
    _isIosApp = true;
  },
};

window.pushHistory = pushHistory;

Array.prototype.groupBy = function (keyGetter) {
  const result = {};
  for (let i = 0; i < this.length; i++) {
    const key = keyGetter(this[i]);
    let groupArray = result[key];
    if (!groupArray) {
      groupArray = [];
      result[key] = groupArray;
    }
    groupArray.push(this[i]);
  }
  return result;
};
