/**
 * Created by kimchangduk on 2017-05-27.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  postList: {
    state: {
      urlKey: -1,
      page: -1,
      totalPages: -1,
      request: Consts.REQUEST_NONE,
      errorMessage: "",
      canEdit: false,
    },
    dataSource: [],
  },
  postView: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
      boardUrlKey: -1,
      postId: -1,
    },
    dataSource: null,
  },

  comment: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
      boardUrlKey: -1,
      postId: -1,
    },
  },
  deleteComment: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
      boardUrlKey: -1,
      postId: -1,
      commentId: -1,
    },
  },
};

export default function boardReducer(state = initialState, action) {
  switch (action.type) {
    //<editor-fold desc="get board post list">
    case ActionTypes.GET_BOARD_POST_LIST_REQUEST:
      return update(state, {
        postList: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            urlKey: { $set: action.urlKey },
            page: { $set: action.page },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.GET_BOARD_POST_LIST_SUCCESS:
      if (action.urlKey !== state.postList.state.urlKey || action.page !== state.postList.state.page) {
        return state;
      }
      return update(state, {
        postList: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            totalPages: { $set: action.data.totalPages },
            canEdit: { $set: action.data.canEdit },
          },
          dataSource: { $set: action.data.posts },
        },
      });

    case ActionTypes.GET_BOARD_POST_LIST_FAILURE:
      if (action.urlKey !== state.postList.state.urlKey || action.page !== state.postList.state.page) {
        return state;
      }
      return update(state, {
        postList: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="get board post view">
    case ActionTypes.GET_BOARD_POST_VIEW_REQUEST:
      return update(state, {
        postView: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            boardUrlKey: { $set: action.boardUrlKey },
            postId: { $set: action.postId },
            errorMessage: { $set: "" },
          },
        },
      });
    case ActionTypes.GET_BOARD_POST_VIEW_SUCCESS:
      if (action.boardUrlKey !== state.postView.state.boardUrlKey || action.postId !== state.postView.state.postId) {
        return state;
      }
      return update(state, {
        postView: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $set: action.data },
        },
      });
    case ActionTypes.GET_BOARD_POST_VIEW_FAILURE:
      if (action.boardUrlKey !== state.postView.state.boardUrlKey || action.postId !== state.postView.state.postId) {
        return state;
      }
      return update(state, {
        postView: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
          dataSource: { $set: null },
        },
      });
    //</editor-fold>

    //<editor-fold desc="post comment">
    case ActionTypes.POST_COMMENT_REQUEST:
      return update(state, {
        comment: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            boardUrlKey: { $set: action.boardUrlKey },
            postId: { $set: action.postId },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.POST_COMMENT_SUCCESS: {
      if (action.boardUrlKey !== state.comment.state.boardUrlKey || action.postId !== state.comment.state.postId) {
        return state;
      }
      const updateValue = {
        comment: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $set: action.data },
        },
      };

      if (state.postView.dataSource && state.postView.state.boardUrlKey === action.boardUrlKey && state.postView.dataSource.id === parseInt(action.postId)) {
        updateValue.postView = {
          dataSource: { $set: action.data },
        };
      }

      return update(state, updateValue);
    }

    case ActionTypes.POST_COMMENT_FAILURE:
      if (action.boardUrlKey !== state.comment.state.boardUrlKey || action.postId !== state.comment.state.postId) {
        return state;
      }
      return update(state, {
        comment: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
          dataSource: { $set: null },
        },
      });
    //</editor-fold>

    //<editor-fold desc="delete comment">
    case ActionTypes.DELETE_COMMENT_REQUEST:
      return update(state, {
        deleteComment: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            boardUrlKey: { $set: action.boardUrlKey },
            postId: { $set: action.postId },
            commentId: { $set: action.commentId },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.DELETE_COMMENT_SUCCESS: {
      if (
        action.boardUrlKey !== state.deleteComment.state.boardUrlKey ||
        action.postId !== state.deleteComment.state.postId ||
        action.commentId !== state.deleteComment.state.commentId
      ) {
        return state;
      }
      const updateValue = {
        deleteComment: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $set: action.data },
        },
      };

      if (state.postView.dataSource && state.postView.state.boardUrlKey === action.boardUrlKey && state.postView.dataSource.id === parseInt(action.postId)) {
        updateValue.postView = {
          dataSource: { $set: action.data },
        };
      }

      return update(state, updateValue);
    }

    case ActionTypes.DELETE_COMMENT_FAILURE:
      if (action.boardUrlKey !== state.deleteComment.state.boardUrlKey || action.postId !== state.deleteComment.state.postId) {
        return state;
      }
      return update(state, {
        deleteComment: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
          dataSource: { $set: null },
        },
      });
    //</editor-fold>

    default:
      return state;
  }
}
