/**
 * Created by kimchangduk on 2017-05-04.
 */

import React from 'react';
import {Consts} from '../constants';
import Divider from 'material-ui/Divider';

let ProjectImage;
if (Consts.IS_MOBILE) ProjectImage = 'https://s3.ap-northeast-2.amazonaws.com/notihub/images/help/1_mobile.png';
else ProjectImage = 'https://s3.ap-northeast-2.amazonaws.com/notihub/images/help/1_pc.png';

let SubscribeIamge;
if (Consts.IS_MOBILE) SubscribeIamge = 'https://s3.ap-northeast-2.amazonaws.com/notihub/images/help/2_mobile.png';
else SubscribeIamge = 'https://s3.ap-northeast-2.amazonaws.com/notihub/images/help/2_pc.png';

let NewsImage;
if (Consts.IS_MOBILE) NewsImage = 'https://s3.ap-northeast-2.amazonaws.com/notihub/images/help/3_mobile.png';
else NewsImage = 'https://s3.ap-northeast-2.amazonaws.com/notihub/images/help/3_pc.png';

let ProfileImage;
if (Consts.IS_MOBILE) ProfileImage = 'https://s3.ap-northeast-2.amazonaws.com/notihub/images/help/4_mobile.png';
else ProfileImage = 'https://s3.ap-northeast-2.amazonaws.com/notihub/images/help/4_pc.png';

let EmailNotiImage;
if (Consts.IS_MOBILE) EmailNotiImage = 'https://s3.ap-northeast-2.amazonaws.com/notihub/images/help/5_mobile.png';
else EmailNotiImage = 'https://s3.ap-northeast-2.amazonaws.com/notihub/images/help/5_pc.png';


class HelpPage extends React.Component {
    render() {
        return (<div className="default-page text-align-center margin-top-10">
            <div>
                <img src={ProjectImage} style={styles.screenshot}/>
                <p style={styles.p}>PROJECTS 를 누른 후 다양한 알림 프로젝트들을 보고 원하는 프로젝트를 구독해보세요.</p>
            </div>
            <Divider style={styles.divider}/>
            <div>
                <img src={SubscribeIamge} style={styles.screenshot}/>
                <p style={styles.p}>알림을 클릭하고 SUBSCRIBE를 누르면 메일과 앱으로 알림을 받을 수 있습니다.</p>
            </div>
            <Divider style={styles.divider}/>
            <div>
                <img src={NewsImage} style={styles.screenshot}/>
                <p style={styles.p}>프로젝트에서 News를 누르시면 최신 뉴스들을 볼 수 있습니다!</p>
            </div>
            <Divider style={styles.divider}/>
            <div>
                <img src={EmailNotiImage} style={styles.screenshot}/>
                <p style={styles.p}>이메일로 알림을 받지 않기를 원하시면 Profile에서 Email notify를 off로 해주세요.</p>
            </div>
            <Divider style={styles.divider}/>
            <div>
                <img src={ProfileImage} style={styles.screenshot}/>
                <p style={styles.p}>또한, 알림을 직접 개발해보고 싶으시다면 Profile에서 개발자 모드를 on 으로 바꾸시면 알림을 개발할 수 있습니다!</p>
            </div>
            <Divider style={styles.divider}/>
            <strong style={styles.p}>원하는 알림 있으시면 <a className="highlight-color" href="mailto:notihub@crazyguys.me">notihub@crazyguys.me</a> 으로 메일 주세요.</strong>
        </div>);
    }
}

const styles = {
    divider: {
        margin: '30px 0'
    },
    screenshot: {
        maxWidth: '100%',
        marginBottom: 20,
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
        borderRadius: 2
    },
    p: {
        paddingBottom: 30
    }
};

export default HelpPage;