/**
 * Created by kimchangduk on 2017-04-24.
 */
/* eslint-disable no-useless-escape */

import React from "react";
import CountUp from "react-countup";
import { Urls } from "./index";
import { Link } from "react-router-dom";

export const NONE = localize({ default: "None", ko: "없음" });
export const ADD = localize({ default: "Add", ko: "추가" });
export const DELETE = localize({ default: "delete", ko: "삭제" });
export const REPORT = localize({ default: "report", ko: "신고" });
export const EDIT = localize({ default: "edit", ko: "수정" });
export const CLOSE = localize({ default: "close", ko: "닫기" });
export const WRITE = localize({ default: "write", ko: "작성" });
export const WRITE_POST = localize({ default: "Post", ko: "글 남기기" });
export const SHARE = localize({ default: "share", ko: "공유" });
export const SAVE = localize({ default: "Save!", ko: "저장" });
export const PASSWORD_CHANGE = localize({ default: "change password", ko: "패스워드 변경" });
export const CONFIRM_DELETE_REALLY = localize({ default: "Are you sure you want to delete?", ko: "정말로 삭제하시겠습니까?" });
export const SIGN_UP = localize({ default: "Sign up", ko: "회원가입" });
export const SIGN_IN = localize({ default: "Sign in", ko: "로그인" });
export const SIGN_OUT = localize({ default: "Sign out", ko: "로그아웃" });
export const ANDROID_APPLICATION = localize({ default: "Android Application", ko: "안드로이드 앱" });
export const IOS_APPLICATION = localize({ default: "iOS Application", ko: "iOS 앱" });
export const PUBLIC = localize({ default: "Public", ko: "전체 공개" });
export const PRIVATE = localize({ default: "Private", ko: "비공개" });
export const SUBSCRIPTIONS = localize({ default: "Subscriptions", ko: "구독리스트" });
export const HOME = localize({ default: "Home", ko: "홈" });
export const CONTACT = localize({ default: "Contact", ko: "문의" });
export const REQUEST_BOARD = localize({ default: "Request", ko: "요청게시판" });
export const LEARN_BOARD = localize({ default: "Learn", ko: "사용방법" });
export const FREE_BOARD = localize({ default: "Free board", ko: "자유게시판" });
export const QNA_BOARD = localize({ default: "Q&A", ko: "Q&A" });
export const COMMENTS = localize({ default: "Comments", ko: "댓글" });
export const COMMENT_PLACEHOLDER = localize({ default: "Add a comment..", ko: "댓글을 남겨주세요." });
export const SUBMIT_COMMENT = localize({ default: "Submit", ko: "작성" });
export const FAILED_TO_POST_COMMENT = localize({ default: "Failed to post comment!", ko: "댓글 등록에 실패했습니다." });
export const FAILED_TO_DELETE_COMMENT = localize({ default: "Failed to delete comment!", ko: "댓글 삭제에 실패했습니다." });
export const FAILED_TO_DELETE_POST = localize({ default: "Failed to delete post!", ko: "게시물 삭제에 실패했습니다." });
export const OAUTH_SOCIAL = localize({ default: "Social", ko: "소셜" });
export const NOTICE = localize({ default: "Notice", ko: "공지" });
export const SET_NOTICE = localize({ default: "Set notice", ko: "공지로 설정" });
export const TURN_OFF_NOTICE = localize({ default: "Turn off notice", ko: "공지 해제" });
export const SUCCESS_TO_SET_NOTICE = localize({ default: "Successfully setted notice", ko: "공지로 설정되었습니다." });
export const FAILED_TO_SET_NOTICE = localize({ default: "Failed to set notice", ko: "공지 설정에 실패했습니다." });
export const SUCCESS_TO_TURN_OFF_NOTICE = localize({ default: "Successfully turned off notice", ko: "공지 해제에 성공했습니다." });
export const FAILED_TO_TURN_OFF_NOTICE = localize({ default: "Failed to turn off notice", ko: "공지 해제에 실패했습니다." });
export const CHECK_YOUR_PROFILE = localize({ default: "Please check your profile.", ko: "프로필을 확인해 주세요." });
export const TURN_OFF_POPUP_BLOCKING = localize({
  default: "Please disable pop-up blocking.",
  ko: "원활한 사용을 위해서는 팝업을 항상 허용으로 변경해주세요.",
});
export const SEARCH_INPUT_PLACEHOLDER = localize({ default: "RSS or URL or Keyword" });
export const NO_SEARCH_RESULTS_FOUND = localize({ default: "No search results found.", ko: "검색결과가 없습니다." });
export const VISIT_WEBSITE = localize({ default: "Visit website", ko: "웹사이트 방문" });
export const NOT_NOTIFY = localize({ default: "Not notify", ko: "알림 받지 않기" });
export const NOTIFY_EVERY_30_MIN = localize({ default: "Notify every 30 minutes", ko: "30분마다 알림" });
export const SUCCESS_TO_SAVE = localize({ default: "Success to save!", ko: "저장 성공!" });
export const FAILED_TO_DELETE = localize({ default: "Failed to delete.", ko: "삭제에 실패했습니다." });

export function getRequiredFieldMessage(fields) {
  if (fields === null || fields === undefined) {
    return "";
  }
  return `${fields.join("\n")} ${
    fields.length > 1
      ? localize({ default: " are required.", ko: "을 입력해주시기 바랍니다." })
      : localize({ default: " is required.", ko: "을 입력해주시기 바랍니다." })
  }`;
}

//<editor-fold desc="Date, Time">
export const TODAY = localize({ default: "Today", ko: "오늘" });
export const HOURS = localize({ default: "hours", ko: "시간" });
export const MINUTES = localize({ default: "min", ko: "분" });
export const SECONDS = localize({ default: "sec", ko: "초" });
//</editor-fold>

//<editor-fold desc="Email verification">
export const SUCCESS_TO_VERIFY = localize({ default: "Successfully verified!", ko: "인증에 성공했습니다!" });
export const INVALID_APPROACH = localize({ default: "Invalid approach!", ko: "잘못된 접근입니다.!" });
export const NEED_TO_VERIFY_EMAIL = localize({ default: "Need to verify your email.", ko: "이메일 인증이 필요합니다." });
export const RESEND_EMAIL = localize({ default: "Resend email", ko: "이메일 재전송" });
export const FAILED_TO_RESEND_EMAIL = localize({ default: "Failed to resend email", ko: "이메일 재전송 실패!" });
export const SUCCESS_TO_RESEND_EMAIL = localize({ default: "Successfully recent", ko: "이메일 재전송 성공!" });
export const CHECK_YOUR_EMAIL = localize({ default: "Email authentication is required.", ko: "정상적인 사용을 위해서는 이메일 인증이 필요합니다." });
//</editor-fold>

//<editor-fold desc="Profile, Sign Up, Sign In">
export const DONT_HAVE_SUBSCRIBING_PROJECT = localize({ default: "You don't have any subscribing projects.", ko: "구독 중인 프로젝트가 없습니다." });
export const GO_TO_PROJECTS = localize({ default: "Explore Projects", ko: "전체 프로젝트" });
export const PROFILE = localize({ default: "Profile", ko: "프로필" });
export const USERNAME = localize({ default: "Username", ko: "아이디" });
export const NICKNAME = localize({ default: "Nickname", ko: "닉네임" });
export const EMAIL = localize({ default: "Email", ko: "이메일" });
export const PASSWORD = localize({ default: "Password", ko: "패스워드" });
export const CURRENT_PASSWORD = localize({ default: "Current Password", ko: "현재 패스워드" });
export const NEW_PASSWORD = localize({ default: "New Password", ko: "새 패스워드" });
export const VERIFY_PASSWORD = localize({ default: "Verify Password", ko: "새 패스워드 확인" });
export const USERNAME_OR_EMAIL = localize({ default: "Username or emaill address", ko: "아이디 혹은 이메일" });

export const PASSWORD_IS_EMPTY = localize({ default: "Type your current password", ko: "현재 패스워드를 입력하십시오." });
export const NEW_PASSWORD_IS_EMPTY = localize({ default: "type your new password", ko: "변경할 패스워드를 입력하십시오." });
export const EMAIL_IS_EMPTY = localize({ default: "Email is empty", ko: "Email을 입력해주십시오." });
export const USERNAME_IS_EMPTY = localize({ default: "Username is empty", ko: "Username을 입력해주십시오." });

export const NOT_EQUALS = localize({ default: "not equals", ko: "일치하지 않습니다." });
export const NOT_EQUALS_NEW_PASSWORD = localize({ default: "Password does not match the confirm password.", ko: "새로운 패스워드가 일치하지 않습니다." });

export const NOT_CHANGED = localize({ default: "There's no changes", ko: "변경사항이 없습니다." });

export const FAIL_TO_SAVE_USER = localize({ default: "Failed to save user.", ko: "사용자 정보 수정에 실패했습니다." });
export const FAIL_TO_LOAD_DATA = localize({ default: "Failed to load data.", ko: "데이터를 불러오는 데 실패했습니다." });
export const FAIL_TO_EDIT_DATA = localize({ default: "Failed to edit data.", ko: "데이터를 수정하는 데 실패했습니다." });
export const FAIL_TO_ADD_DATA = localize({ default: "Failed to add data.", ko: "데이터를 추가하는 데 실패했습니다." });

export const FAIL_TO_DELETE = localize({ default: "Failed to delete.", ko: "삭제에 실패했습니다." });
export const FAIL_TO_SIGN_UP = localize({ default: "Failed to sign up.", ko: "회원가입에 실패했습니다." });
export const FAIL_TO_LOAD_USER = localize({ default: "Failed to load user.", ko: "사용자 데이터를 불러오는 데에 실패했습니다." });
export const FAIL_TO_LOGIN = localize({ default: "Failed to login.", ko: "로그인에 실패했습니다." });

export const SUCCESS_TO_UPDATED = localize({ default: "Successfully updated.", ko: "수정 성공" });
export const SUCCESS_TO_PASSWORD_EDIT = localize({ default: "Password successfully updated.", ko: "패스워드 수정 성공" });
export const FAIL_TO_PASSWORD_EDIT = localize({ default: "Failed to change password.", ko: "패스워드 수정 실패" });
export const SUCCESS_TO_DELETE = localize({ default: "Successfully deleted", ko: "삭제에 성공했습니다." });

export const GO_TO_PROFILE = localize({ default: "Go to profile", ko: "프로필로 이동" });
export const FAILED_TO_LOGIN_WITH_GOOGLE = localize({ default: "Failed to login with Google", ko: "Google 로그인에 실패했습니다." });
export const FAILED_TO_LOGIN_WITH_FACEBOOK = localize({ default: "Failed to login with Facebook", ko: "Facebook 로그인에 실패했습니다." });
//</editor-fold>

//<editor-fold desc="Subscription">
export const MUST_ADD_AT_LEAST_ONE_SUBSCRIPTION = localize({ default: "You must add at least one subscription.", ko: "하나 이상의 구독을 추가해야합니다." });
export const ADD_FOLDER = localize({ default: "Add folder", ko: "폴더 추가" });
export const SELECT_SUBSCRIPTIONS_TO_ADD = localize({
  default: "Please select a subscription to add to the folder.",
  ko: "폴더에 추가 할 구독을 선택하십시오.",
});
export const TYPE_FOLDER_NAME = localize({ default: "Please enter a folder name.", ko: "폴더 이름을 입력하십시오." });
export const LIST_NAME = localize({ default: "List name", ko: "리스트 이름" });
export const SUBSCRIBE = localize({ default: "Subscribe", ko: "구독하기" });
export const SUBSCRIBING = localize({ default: "Subscribing", ko: "구독 중" });
export const UNSUBSCRIBE = localize({ default: "Unsubscribe", ko: "구독 취소" });
export const SUBSCRIBE_LIST = localize({ default: "Subscribes", ko: "구독 리스트" });
export const FILTER = localize({ default: "Filter", ko: "필터" });

export const UNSUBSCRIBE_CONFIRM = localize({ default: "Are you sure you want to cancel your subscription?", ko: "구독을 정말 취소하시겠습니까?" });

export const SUCCESS_TO_SUBSCRIBE = localize({ default: "Succeed to subscribe!", ko: "구독에 성공했습니다!" });
export const FAILED_TO_SUBSCRIBE = localize({ default: "Failed to subscribe.", ko: "구독에 실패했습니다." });
export const FAILED_TO_UNSUBSCRIBE = localize({ default: "Failed to unsubscribe.", ko: "구독 취소에 실패했습니다." });

export const FAILED_TO_ADD_FOLDER = localize({ default: "Failed to add folder", ko: "폴더 추가에 실패했습니다." });
export const FAILED_TO_EDIT_SUBSCRIPTIONS = localize({ default: "Failed to edit subscribes", ko: "구독 리스트 수정에 실패했습니다." });
export const FAILED_TO_GET_SUBSCRIBE_LIST = localize({ default: "Failed to get subscribes", ko: "구독 리스트를 불러오는 데 실패했습니다." });
export const FAILED_TO_ADD_KEYWORD = localize({ default: "Failed to add keyword", ko: "키워드 등록에 성공했습니다." });
export const FAILED_TO_REMOVE_KEYWORD = localize({ default: "Failed to remove keyword", ko: "키워드 삭제에 실패했습니다." });

export const NOTIFICATION_FILTERING_KEYWORD_PLACEHOLDER = localize({
  default: "Type keyword for notification filtering.",
  ko: "알림을 필터링할 키워드를 입력하세요.",
});
//</editor-fold>

export const CLIPBOARD_COPIED = localize({ default: "Clipboard copied.", ko: "클립보드에 저장되었습니다." });
export const NEWS = localize({ default: "News", ko: "소식" });
export const SETTING = localize({ default: "Setting", ko: "설정" });
export const EMAIL_NOTIFY = localize({ default: "Email notify", ko: "이메일 알림" });
export const DEVELOPER_MODE = localize({ default: "Developer mode", ko: "개발자 모드" });

export const FAILED_TO_READ_FILE = localize({ default: "Failed to read the file!", ko: "파일을 읽을 수 없습니다." });
export const FAILED_TO_UPLOAD = localize({ default: "Failed to upload!", ko: "업로드에 실패했습니다." });
export const FAILED_TO_UPLOAD_IMAGE = localize({ default: "Failed to upload image!", ko: "이미지 업로드에 실패했습니다." });
export const getLargeImageFileError = (maxFileSize) =>
  localize({
    default: `The image file is too large.\nMaximum image file size is ${maxFileSize}`,
    ko: `이미지 파일의 용량이 너무 큽니다.\n${maxFileSize}KB 이하의 파일만 가능합니다.`,
  });
export const getBoardName = (boardUrlKey) => {
  switch (boardUrlKey) {
    case "learn":
      return LEARN_BOARD;
    case "request":
      return REQUEST_BOARD;
    case "freeboard":
      return FREE_BOARD;
    case "qna":
      return QNA_BOARD;
  }
};

export const MEMBERSHIP = localize({ default: "Membership", ko: "서비스 등급" });
export const getRemainProjectCount = (x) => localize({ default: `${x} Project can be created`, ko: `${x}개 프로젝트 추가 가능` });
export const EXCCEED_MAX_PROJECT_COUNT = localize({
  default: "You have exceeded the maximum number of producible projects.",
  ko: "최대 생성 가능 프로젝트 수를 초과하였습니다.",
});

export function getProjectCountComponent(count, duration = 0.7) {
  return localize({
    default: (
      <span>
        We have{" "}
        <Link to={Urls.PROJECT_LIST}>
          <CountUp start={0} end={count} useEasing={false} separator="," duration={duration} />
        </Link>{" "}
        projects.
      </span>
    ),
    ko: (
      <span>
        <Link to={Urls.PROJECT_LIST}>
          <CountUp start={0} end={count} useEasing={false} separator="," duration={duration} />
        </Link>
        개의 프로젝트가 등록되어 있습니다.
      </span>
    ),
  });
}

export const getRegisteredTime = (time) => {
  if (typeof time === "number") {
    time = new Date(time);
  }

  if (!time) {
    return "";
  }

  const currentTime = new Date();
  const totalMonth = Math.trunc(time.getMonthsBetween(currentTime));
  if (totalMonth > 0) {
    if (totalMonth >= 12) {
      return `${Math.trunc(totalMonth / 12)}${localize({ ko: "년 전", default: "years ago" })}`;
    }
    return `${totalMonth}${localize({ ko: "달 전", default: "months ago" })}`;
  }

  const totalDays = Math.trunc(time.getDaysBetween(currentTime));
  if (totalDays > 0) {
    return `${totalDays}${localize({ ko: "일 전", default: "days ago" })}`;
  }
  const totalHours = Math.trunc(time.getHoursBetween(currentTime));
  if (totalHours > 0) {
    return `${totalHours}${localize({ ko: "시간 전", default: "hours ago" })}`;
  }

  const totalMinutes = Math.trunc(time.getMinutesBetween(currentTime));
  if (totalMinutes > 0) {
    return `${totalMinutes}${localize({ ko: "분 전", default: "minutes ago" })}`;
  }

  const totalSeconds = Math.trunc(time.getSecondsBetween(currentTime));
  if (totalSeconds >= 0) {
    return `${localize({ ko: "방금", default: "Just now" })}`;
  }
  return time.toFormat("YYYY-MM-DD HH24:MI");
};

//<editor-fold desc="Project Source, Project Add page">
export const TITLE = localize({ default: "Title", ko: "제목" });
export const ADD_PROJECT = localize({ default: "Add Project", ko: "프로젝트 추가" });
export const SOURCE_VISIBILITY = localize({ default: "Source visibility", ko: "소스 공개 범위" });
export const NEXT_RUN_TIME = localize({ default: "Next run time", ko: "다음 실행 시각" });
export const RUN_TIME = localize({ default: "Run time", ko: "실행 시각" });
export const EXECUTION_CYCLE = localize({ default: "Execution cycle", ko: "실행 주기" });
export const VARIABLES = localize({ default: "Variables", ko: "변수" });
export const SINGLE_NOTIFY = localize({ default: "Single notify", ko: "단일 알림" });
export const OFFICIAL_SOCIAL_POSTING = localize({ default: "Official social posting", ko: "공식 소셜 포스팅" });
export const NAME = localize({ default: "Name", ko: "이름" });
export const KEY = localize({ default: "Key", ko: "키" });
export const VALUE = localize({ default: "Value", ko: "값" });
export const DESCRIPTION = localize({ default: "Description", ko: "설명" });
export const COMPONENT = localize({ default: "Component", ko: "컴포넌트" });
export const ADD_COMPONENT = localize({ default: "Add Component", ko: "컴포넌트 추가" });
export const PROCESS = localize({ default: "Process", ko: "프로세스" });

export const CURRENT_STATUS = localize({ default: "Status", ko: "현재 상태" });
export const LAST_EXECUTION_SUCCESS_TIME = localize({ default: "Last execution success", ko: "마지막 실행 성공" });
export const LAST_UPDATED_TIME = localize({ default: "Last updated", ko: "마지막 수정일" });
export const RESULT_GENERATED_TIME = localize({ default: "Result creation time", ko: "결과 생성일" });

export const DECLARE_VARIABLES_FIRST = localize({ default: "Declare variables first.", ko: "값을 입력하거나 변수를 추가하여 입력해주세요." });
export const PRESS_ALT_INSERT = localize({ default: "Press Alt + Insert to insert variable.", ko: "값을 입력하거나 Alt + Insert를 눌러 변수를 입력해주세요." });
export const TYPE_KEY_FIRST = localize({ default: "Type 'Key' first.", ko: "'키'를 먼저 입력해주세요." });

export const TAGS = localize({ default: "Tags", ko: "태그" });
export const TAG_TO_ADD = localize({ default: "Tag to add", ko: "추가할 태그" });
export const TAG_INPUT_HINT = localize({ default: "Type tag and press enter to add tag.", ko: "태그를 입력하고 엔터를 눌러주세요." });
export const ALREADY_TAG_EXISTS = localize({ default: "This tag already exists.", ko: "이미 존재하는 태그입니다." });

export const CLEAR_ALL_DB_DATA = localize({ default: "Clear all db data", ko: "디비 전체 지우기" });
export const CLEAR_DB_DATA_BY_KEY = localize({ default: "Clear db data by key", ko: "디비 키로 지우기" });

export const REMOVE_ALL_NEWS = localize({ default: "Delete all news", ko: "뉴스 전체 지우기" });
export const PROJECT_DELETE = localize({ default: "Delete project", ko: "프로젝트 삭제" });
export const DEBUG_RESULT = localize({ default: "Debug result", ko: "디버그 결과" });
export const NO_ERROR_WAS_FOUND = localize({ default: "No error was found", ko: "에러를 찾지 못했습니다." });
export const ERROR_HAS_OCCURRED = localize({ default: "Error has occurred.", ko: "에러가 발생했습니다." });
export const SHOW_RESULT = localize({ default: "Show result", ko: "결과 보기" });

export const LANGUAGE_ENGLISH = localize({ default: "English", ko: "영어" });
export const LANGUAGE_KOREAN = localize({ default: "Korean", ko: "한국어" });
export const LANGUAGE_JAPANESE = localize({ default: "Japanese", ko: "일본어" });
export const LANGUAGE_CHINESE = localize({ default: "Chinese(Simplified)", ko: "중국어(간체)" });

export const MESSAGE = localize({ default: "Message", ko: "메세지" });

export const LOAD_PREVIOUSE_NEWS = localize({ default: "Load previouse news", ko: "이전 뉴스 불러오기" });

export const REASON_HINT_TEXT = localize({ default: "Type your reason for reporting.", ko: "신고 사유를 입력해주세요." });
export const REPORT_RECEIVED = localize({ default: "Your report has been received.", ko: "신고가 접수되었습니다." });
export const REPORT_FAILED = localize({ default: "Failed to report.", ko: "신고 접수에 실패했습니다." });
export const ACCEPT_TERMS = localize({ default: "I agree to terms.", ko: "약관에 동의합니다." });
export const ACCEPT_TERMS_REQUIRED = localize({ default: "You must accept the terms.", ko: "약관에 동의를 하셔야 합니다." });
//</editor-fold>

//<editor-fold desc="OAuth2">
export const SUCCESS_TO_GET_ACCESS_TOKEN = localize({ default: "Successfully acquired Access token", ko: "성공적으로 Access Token을 획득했습니다." });
export const FAILED_TO_GET_ACCESS_TOKEN = localize({ default: "Failed to get Access Token", ko: "Access Token 획득에 실패했습니다." });

//</editor-fold>

//<editor-fold desc="Keyword">
export const SEARCH_FEED = localize({ default: "Search feeds", ko: "게시물 검색" });
export const SEARCH_IN_PROJECT = localize({ default: "search in", ko: "내에서 검색" });
export const SEARCH_PROJECT_FEEDS = localize({ default: "Search project or feed", ko: "전체에서 검색" });
export const SEARCH_SUBSCRIBE_FEEDS = localize({ default: "Search in my subscriptions", ko: "내 구독리스트 내에서 검색" });

export function getKeywordString(keyword) {
  if (!keyword) {
    return "";
  }

  if (keyword.title) {
    return keyword.title;
  }

  let result = "";
  if (keyword.tag) {
    result += `#${keyword.tag.name}`;
  }
  if (keyword.project) {
    if (result) result += "+";
    result += `@${keyword.project.title}`;
  }
  if (keyword.word) {
    if (result) result += "+";
    result += keyword.word;
  }
  return result;
}

export function getConfirmUnsubscribeKeyword(item) {
  const keywordString = getKeywordString(item);
  return localize({ default: `Are you sure you want to unsubscribe '${keywordString}'`, ko: `'${keywordString}'에 대해 알림을 받지 않으시겠습니까?` });
}

//</editor-fold>

export const DISCOVER_PAGE_WELCOME = localize({ default: "Subscribe RSS!<br/>Get the notification you need!", ko: "RSS를 구독하고 원하는 알림을 받아보세요." });

//<editor-fold desc="Contact Page">
export const SUCCESS_TO_SUBMIT_CONTACT = localize({ default: "Thanks for your opinion!" });
export const FAILED_TO_SUBMIT_CONTACT = localize({ default: "Failed to submit!" });
export const CONTACT_INPUT_HINT = localize({ default: "Say anything what you need!" });
//</editor-fold>

//<editor-fold desc="RSS Build Page">
export const RSS_GENERATOR = localize({ default: "RSS GENERATOR", ko: "RSS 생성기" });
export const MY_RSS_PAGE_LIST = localize({ default: "My RSS Pages", ko: "내 RSS 페이지 목록" });

export const STEP1_TITLE = localize({ default: "Step1: Enter URL", ko: "URL 불러오기" });
export const STEP2_TITLE = localize({ default: "Step2: Define Extract Rule", ko: "2단계: 추출 규칙" });
export const STEP3_TITLE = localize({ default: "Step3: Define Feed Generation Rule", ko: "3단계: 피드 생성 규칙" });
export const STEP4_TITLE = localize({ default: "Step3: Site Information", ko: "4단계: 사이트 정보" });

export const EXTRACT_RULE_LINK = localize({ default: "Extract Rule", ko: "추출 규칙" });
export const FEED_GENERATION_RULE_TITLE = localize({ default: "Feed Generation Rule: Title", ko: "피드 생성 규칙: Title" });
export const FEED_GENERATION_RULE_LINK = localize({ default: "Feed Generation Rule: Link", ko: "피드 생성 규칙: Link" });
export const RSS_TITLE = localize({ default: "RSS Title", ko: "RSS 이름" });
export const RSS_DESCRIPTION = localize({ default: "RSS Description", ko: "RSS 설명" });

export const TYPE_RSS_RULE = localize({ default: "Example. Please fill out the rules here.", ko: "예제입니다. 이곳에 규칙을 작성해주세요." });

export const LOAD_URL = localize({ default: "Load URL", ko: "URL 불러오기" });
export const REGISTER = localize({ default: "Register", ko: "등록" });
export const EXTRACT = localize({ default: "Extract repeated item", ko: "반복 아이템 뽑아내기" });
export const FAILED_TO_EXTRACT = localize({ default: "Failed to extract items", ko: "반복아이템 추출에 실패했습니다." });
export const FAILED_TO_REGISTER = localize({ default: "Failed to register", ko: "등록에 실패했습니다." });
export const FAILED_TO_FETCH_HTML = localize({ default: "Failed to fetch html", ko: "HTML을 가져오는 데 실패했습니다." });
export const FAILED_TO_FETCH_RSS = localize({ default: "Failed to fetch rss", ko: "RSS를 가져오는 데 실패했습니다." });
export const OPEN_IN_NOTIHUB = localize({ default: "Open in Notihub", ko: "NotiHub에서 보기" });
export const VIEW_RSS = localize({ default: "View RSS", ko: "RSS 보기" });
export const PROJECT_NOT_FOUND = localize({ default: "Project not founded", ko: "프로젝트를 불러올 수 없습니다." });
export const GENERATE_RSS = localize({ default: "Generate RSS!", ko: "RSS 생성!" });
export const FAILED_TO_GENERATE_RSS = localize({ default: "Failed to generate RSS.", ko: "RSS 생성에 실패했습니다." });
export const FAILED_TO_EDIT_RSS = localize({ default: "Failed to edit RSS.", ko: "RSS 수정에 실패했습니다." });
export const FAILED_TO_LOAD_HTML = localize({
  default: "Failed to load URL.\nPlease make sure it is a valid URL.",
  ko: "URL을 불러오는데 실패하였습니다.\n올바른 URL 인지 확인해주세요.",
});
export function getRssRemoveConfirmText(projectTitle) {
  return localize({
    default: `Are you sure you want to delete ${projectTitle}?`,
    ko: `'${projectTitle}' RSS를 삭제하시겠습니까?`,
  });
}

export const getRssParseCountParagraph = (count) => localize({ default: `${count} items extracted.`, ko: `${count}개의 아이템을 찾았습니다.` });
//</editor-fold>

//<editor-fold desc="Page404">
export const PAGE_404_SUBTITLE = localize({ default: "Oops, the page you're looking for doesn't exist.", ko: "존재하지 않는 페이지입니다." });
export const PAGE_404_BUTTON = localize({ default: "Go to homepage", ko: "홈페이지로 이동" });
//</editor-fold>

export const TEXT = localize({ default: "Text", ko: "텍스트" });
export const THUMBNAIL = localize({ default: "Thumbnail", ko: "썸네일" });
export const NO_SEARCH_RESULT = localize({ default: "No results", ko: "검색 결과가 없습니다." });
export const FAILED_TO_GET_PROJECT = localize({ default: "Failed to get project.", ko: "프로젝트를 불러오는 데 실패했습니다." });
export const FAILED_TO_GET_FEEDS = localize({ default: "Failed to get feeds", ko: "피드를 불러오는 데 실패했습니다." });
export const FAILED_TO_GET_FEED = localize({ default: "Failed to get feed", ko: "피드를 불러오는 데 실패했습니다." });
export const UNCLASSIFIED = localize({ default: "undefined", ko: "분류 없음" });

export function getLanguageCode() {
  let langCode = window.navigator.userLanguage || window.navigator.language;
  if (/(\w+)\-(\w+)/g.test(langCode)) {
    langCode = /(\w+)\-(\w+)/g.exec(langCode)[1];
  }
  if (langCode) {
    langCode = langCode.toLowerCase();
  }
  return langCode;
}

export function localize(text) {
  const language = getLanguageCode();
  if (typeof text === "string") return text;
  if (text[language]) {
    return text[language];
  }
  return text.default;
}
