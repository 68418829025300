import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Consts, Urls, Strings} from '../constants';
import {getSubscribeList} from "../actions/subscribe";
import DialogManager from '../dialogs/DialogManager';
import SubscriptionSortableList from "../components/SubscriptionSortableList";
import deepEqual from "deep-equal";
import {FlatButton} from "material-ui";
import AddSubscriptionListDialog from "../dialogs/AddSubscriptionListDialog";
import AddListIcon from 'material-ui/svg-icons/av/playlist-add';

class MobileSubscribesPage extends React.Component {
    static StateToProps = (state) => {
        return {
            isLogin: state.user.state.isLogin,
            mySubscribesRequest: state.subscribe.mySubscribes.state.request,
            mySubscribesErrorMessage: state.subscribe.mySubscribes.state.errorMessage,
            mySubscribesDataSource: state.subscribe.mySubscribes.dataSource
        };
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {
                getSubscribeList: () => {
                    dispatch(getSubscribeList());
                },
            }
        };
    };

    static propTypes = {
        actions: PropTypes.object,
        isLogin: PropTypes.bool,
        mySubscribesRequest: PropTypes.string,
        mySubscribesErrorMessage: PropTypes.string,
        mySubscribesDataSource: PropTypes.array,
    };

    state = {
        mySubscriptionGroup: null
    };

    static getDerivedStateFromProps(props, state) {
        const mySubscriptionGroup = props.mySubscribesDataSource ? props.mySubscribesDataSource.sort((item1, item2) => {
            const item1Order = item1.order !== null ? item1.order : Number.MAX_SAFE_INTEGER;
            const item2Order = item2.order !== null ? item2.order : Number.MAX_SAFE_INTEGER;
            return item1Order - item2Order;
        }).groupBy(item => item.subscriptionFolder ? item.subscriptionFolder.name : Consts.UNDEFINED_FOLDER_NAME) : null;
        if (!deepEqual(mySubscriptionGroup, state ? state.mySubscriptionGroup : null)) {
            return {mySubscriptionGroup};
        }
        return null;
    }

    componentDidMount() {
        this.props.actions.getSubscribeList();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.isLogin !== nextProps.isLogin && nextProps.isLogin) {
            this.props.actions.getSubscribeList();
        }

        return this.props.isLogin !== nextProps.isLogin ||
            this.props.mySubscribesDataSource !== nextProps.mySubscribesDataSource ||
            (this.props.mySubscribesRequest === Consts.REQUEST_WAITING && nextProps.mySubscribesRequest !== Consts.REQUEST_WAITING);
    }

    onAddListClick = () => {
        DialogManager.push(AddSubscriptionListDialog);
    };

    render = () => {
        const mySubscribes = this.props.mySubscribesDataSource;
        if (!this.state) {
            return <div/>;
        }

        const {mySubscriptionGroup} = this.state;
        if (!this.props.isLogin || !mySubscribes || mySubscribes.length <= 0) {
            return <div/>;
        }

        return <div className="my-subscribes">
            <FlatButton icon={<AddListIcon/>} label={Strings.ADD_FOLDER} onClick={this.onAddListClick} style={styles.addButton} fullWidth/>
            <SubscriptionSortableList subscriptions={mySubscriptionGroup}/>
        </div>;
    };
}

const styles = {
    addButton: {
        boxShadow: 'none'
    }
};

export default connect(MobileSubscribesPage.StateToProps, MobileSubscribesPage.DispatchToProps)(MobileSubscribesPage);