/**
 * Created by kimchangduk on 2017-04-30.
 */
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Consts, Strings, Urls } from "../constants";
import FontIcon from "material-ui/FontIcon";
import MenuItem from "material-ui/MenuItem";
import AddListIcon from "material-ui/svg-icons/av/playlist-add";
import { getUser, logout } from "../actions/user";
import { moveLoginPage } from "../Utils";
import { Divider } from "material-ui";
import { getSubscribeList } from "../actions/subscribe";
import { getContactMenuLink } from "../constants/Urls";
import deepEqual from "deep-equal";
import SubscriptionSortableList from "./SubscriptionSortableList";
import DialogManager from "../dialogs/DialogManager";
import AddSubscriptionListDialog from "../dialogs/AddSubscriptionListDialog";
import AdFitVertical from "./AdFitVertical";
import withRouter from "../withRouter";

class Drawer extends React.Component {
  static StateToProps = (state) => {
    const dataSource = state.user.dataSource;
    return {
      isLogin: state.user.state.isLogin,
      isApiView: dataSource && dataSource.auth ? dataSource.auth.includes(Consts.ROLE_API_VIEWER) : false,
      isAdmin: state.user.state.isAdmin,

      mySubscribesRequest: state.subscribe.mySubscribes.state.request,
      mySubscribesErrorMessage: state.subscribe.mySubscribes.state.errorMessage,
      mySubscribesDataSource: state.subscribe.mySubscribes.dataSource,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getSubscribeList: () => {
          dispatch(getSubscribeList());
        },
        getUser: () => {
          dispatch(getUser());
        },
        logout: () => {
          dispatch(logout());
        },
      },
    };
  };

  static getFontIcon = (className) => <FontIcon color={styles.fontIconColor} style={styles.fontIcon} className={className} />;

  static defaultProp = {};

  static propTypes = {
    location: PropTypes.object,
    actions: PropTypes.object,
    isLogin: PropTypes.bool,
    isApiView: PropTypes.bool,
    isAdmin: PropTypes.bool,
    closeDrawer: PropTypes.func,
    openRequest: PropTypes.func,
    open: PropTypes.bool,
    className: PropTypes.string,
    fixed: PropTypes.bool,
    fixToggleRequest: PropTypes.func,

    mySubscribesRequest: PropTypes.string,
    mySubscribesErrorMessage: PropTypes.string,
    mySubscribesDataSource: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = { mySubscriptionGroup: null };
  }

  static getDerivedStateFromProps(props, state) {
    const mySubscriptionGroup = props.mySubscribesDataSource
      ? props.mySubscribesDataSource
          .sort((item1, item2) => {
            const item1Order = item1.order !== null ? item1.order : Number.MAX_SAFE_INTEGER;
            const item2Order = item2.order !== null ? item2.order : Number.MAX_SAFE_INTEGER;
            return item1Order - item2Order;
          })
          .groupBy((item) => (item.subscriptionFolder ? item.subscriptionFolder.name : Consts.UNDEFINED_FOLDER_NAME))
      : null;
    if (!deepEqual(mySubscriptionGroup, state ? state.mySubscriptionGroup : null)) {
      return { mySubscriptionGroup };
    }
    return null;
  }

  componentDidMount() {
    if (this.props.isLogin) this.requestSubscribeList();
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.isLogin !== nextProps.isLogin && nextProps.isLogin) {
      this.requestSubscribeList();
    }

    return (
      this.props.isLogin !== nextProps.isLogin ||
      this.props.isApiView !== nextProps.isApiView ||
      this.props.isAdmin !== nextProps.isAdmin ||
      this.props.open !== nextProps.open ||
      this.props.className !== nextProps.className ||
      this.props.fixed !== nextProps.fixed ||
      this.props.mySubscribesDataSource !== nextProps.mySubscribesDataSource ||
      (this.props.mySubscribesRequest === Consts.REQUEST_WAITING && nextProps.mySubscribesRequest !== Consts.REQUEST_WAITING)
    );
  }

  onAddSubscriptionListClick = () => {
    DialogManager.push(AddSubscriptionListDialog);
  };

  requestSubscribeList = () => {
    this.props.actions.getSubscribeList();
  };

  renderMySubscripts = () => {
    const mySubscribes = this.props.mySubscribesDataSource;
    const { mySubscriptionGroup } = this.state;
    if (!this.props.isLogin || !mySubscribes || mySubscribes.length <= 0) {
      return undefined;
    }

    return (
      <div className="my-subscribes">
        <SubscriptionSortableList subscriptions={mySubscriptionGroup} />
      </div>
    );
  };

  renderOpenState = () => {
    const isLogin = this.props.isLogin;
    const isAdmin = this.props.isAdmin;
    const isApiView = this.props.isApiView;
    const contactComponent = (
      <Link to={getContactMenuLink()}>
        <MenuItem
          leftIcon={Drawer.getFontIcon("fas fa-comment")}
          onClick={this.props.closeDrawer}
          style={styles.drawerMenuItem}
          innerDivStyle={styles.drawerMenuItemInnerDiv}
        >
          {Strings.CONTACT}
        </MenuItem>
      </Link>
    );
    const mySubscribes = this.renderMySubscripts();

    return (
      <>
        <div>
          <div className="top-buttons">
            <span className="pin-toggle" onClick={this.props.fixToggleRequest}>
              {this.props.fixed ? "Unpin" : "Pin"}
            </span>
          </div>
          <div className="padding-left-right-15">
            {isLogin ? (
              <div>
                <Link to="/">
                  <MenuItem leftIcon={Drawer.getFontIcon("fas fa-home")} style={styles.drawerMenuItem} innerDivStyle={styles.drawerMenuItemInnerDivBold}>
                    {Strings.HOME}
                  </MenuItem>
                </Link>

                <Divider style={styles.divider} />

                {mySubscribes ? (
                  <p style={styles.subscribeListHeader}>
                    {Strings.SUBSCRIBE_LIST}
                    <AddListIcon style={styles.addListIcon} onClick={this.onAddSubscriptionListClick} />
                  </p>
                ) : undefined}
                {mySubscribes}
                {mySubscribes ? <Divider style={styles.divider} /> : undefined}

                {contactComponent}

                <Divider style={styles.divider} />

                <a href={Urls.PLAYSTORE} target="_blank" rel="noreferrer">
                  <MenuItem leftIcon={Drawer.getFontIcon("fab fa-android")} style={styles.drawerMenuItem} innerDivStyle={styles.drawerMenuItemInnerDiv}>
                    {Strings.ANDROID_APPLICATION}
                  </MenuItem>
                </a>
                <a href={Urls.APPSTORE} target="_blank" rel="noreferrer">
                  <MenuItem leftIcon={Drawer.getFontIcon("fab fa-apple")} style={styles.drawerMenuItem} innerDivStyle={styles.drawerMenuItemInnerDiv}>
                    {Strings.IOS_APPLICATION}
                  </MenuItem>
                </a>

                <Divider style={styles.divider} />

                {isAdmin || isApiView ? (
                  <Link key="0" to="/api">
                    <MenuItem
                      leftIcon={Drawer.getFontIcon("fas fa-flask")}
                      style={styles.drawerMenuItem}
                      innerDivStyle={styles.drawerMenuItemInnerDiv}
                      onClick={this.props.closeDrawer}
                    >
                      API
                    </MenuItem>
                  </Link>
                ) : undefined}
                <Link key="1" to={Urls.RSS_LIST}>
                  <MenuItem
                    leftIcon={Drawer.getFontIcon("fas fa-rss")}
                    style={styles.drawerMenuItem}
                    innerDivStyle={styles.drawerMenuItemInnerDiv}
                    onClick={this.props.closeDrawer}
                  >
                    {Strings.MY_RSS_PAGE_LIST}
                  </MenuItem>
                </Link>
                <Link key="2" to="/profile">
                  <MenuItem
                    leftIcon={Drawer.getFontIcon("fas fa-user")}
                    style={styles.drawerMenuItem}
                    innerDivStyle={styles.drawerMenuItemInnerDiv}
                    onClick={this.props.closeDrawer}
                  >
                    {Strings.PROFILE}
                  </MenuItem>
                </Link>
                <MenuItem
                  leftIcon={Drawer.getFontIcon("fas fa-sign-out-alt")}
                  key="3"
                  style={styles.drawerMenuItem}
                  innerDivStyle={styles.drawerMenuItemInnerDiv}
                  onClick={() => {
                    this.props.actions.logout();
                  }}
                >
                  {Strings.SIGN_OUT}
                </MenuItem>
              </div>
            ) : (
              <div>
                <Link to="/">
                  <MenuItem leftIcon={Drawer.getFontIcon("fas fa-home")} style={styles.drawerMenuItem} innerDivStyle={styles.drawerMenuItemInnerDivBold}>
                    {Strings.HOME}
                  </MenuItem>
                </Link>

                <Divider style={styles.divider} />

                {contactComponent}
                <Divider style={styles.divider} />
                <a href={Urls.PLAYSTORE} target="_blank">
                  <MenuItem leftIcon={Drawer.getFontIcon("fab fa-android")} style={styles.drawerMenuItem} innerDivStyle={styles.drawerMenuItemInnerDiv}>
                    {Strings.ANDROID_APPLICATION}
                  </MenuItem>
                </a>
                <a href={Urls.APPSTORE} target="_blank">
                  <MenuItem leftIcon={Drawer.getFontIcon("fab fa-apple")} style={styles.drawerMenuItem} innerDivStyle={styles.drawerMenuItemInnerDiv}>
                    {Strings.IOS_APPLICATION}
                  </MenuItem>
                </a>
                <Divider style={styles.divider} />
                <MenuItem
                  leftIcon={Drawer.getFontIcon("fas fa-sign-in")}
                  style={styles.drawerMenuItem}
                  innerDivStyle={styles.drawerMenuItemInnerDiv}
                  onClick={() => {
                    moveLoginPage();
                  }}
                >
                  {Strings.SIGN_IN}
                </MenuItem>
              </div>
            )}
          </div>
        </div>
        {/* <AdFitVertical /> */}
      </>
    );
  };

  render() {
    return (
      <div className={this.props.className}>
        {this.props.open ? (
          this.renderOpenState()
        ) : (
          <div className="hamburger-icon" onClick={this.props.openRequest}>
            {Drawer.getFontIcon("fas fa-bars")}
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  drawerMenuItemInnerDiv: {
    paddingLeft: 45,
  },
  drawerMenuItemInnerDivBold: {
    paddingLeft: 45,
    fontWeight: "bold",
  },
  subscribeMenuItemInnerDiv: {
    paddingLeft: 20,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  subscribeMenuItemInnerDivWithIcon: {
    paddingLeft: 40,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  drawerMenuItem: {
    color: "rgba(0, 0, 0, 0.63)",
    lineHeight: "30px",
    minHeight: 30,
    fontSize: 13,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  subscribeMenuItem: {
    color: "rgba(0, 0, 0, 0.63)",
    lineHeight: "30px",
    minHeight: 30,
    fontSize: 13,
  },
  fontIconColor: "rgba(0, 0, 0, 0.63)",
  fontIcon: {
    fontSize: 18,
    width: 18,
    height: 18,
    margin: 6,
  },
  divider: {
    background: "transparent",
    border: "none",
    height: 39,
  },
  subscribeListHeader: {
    fontSize: 11,
  },
  addListIcon: {
    float: "right",
    cursor: "pointer",
    fill: "rgba(0, 0, 0, 0.63)",
  },
};

export default connect(Drawer.StateToProps, Drawer.DispatchToProps)(withRouter(Drawer));
