import React, { ReactElement } from "react";
import { useLocation, useParams, useNavigate, NavigateFunction, Params } from "react-router";

export default function withRouter(Component: React.ComponentType<any>) {
  const Wrapper = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    let params = useParams();

    return <Component {...props} location={location} params={params} navigate={navigate} />;
  };
  return Wrapper;
}

export type withRouterProps<T> = T & { location: Location; params: Readonly<Params<string>>; navigate: NavigateFunction };
