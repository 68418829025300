/**
 * Created by kimchangduk on 2017-04-24.
 */
import React from 'react';
import {Urls} from '../constants';

class ApiPage extends React.Component {
    render() {
        return (<div style={styles.container}>
            <iframe src={Urls.SWAGGER} style={styles.iframe}/>
        </div>);
    }
}

const styles = {
    container: {
        overflow: 'hidden'
    },
    iframe: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 80,
        left: 0,
        border: 'none'
    }
};

export default ApiPage;