/**
 * Created by kimchangduk on 2017-06-08.
 */

import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Strings} from '../constants';
import {Dialog, RaisedButton} from "material-ui";
import {resendEmailVerification} from '../actions/user';

class EmailVerificationRequireDialog extends React.Component {
    static DispatchToProps = (dispatch) => {
        return {
            actions: {
                resendEmailVerification: (thenFunc, catchFunc) => {
                    dispatch(resendEmailVerification(thenFunc, catchFunc));
                }
            }
        };
    };

    static propTypes = {
        actions: PropTypes.object,
        open: PropTypes.bool,
        onRequestClose: PropTypes.func
    };

    onResendClick = () => {
        this.props.actions.resendEmailVerification(this.props.onRequestClose);
    };

    render() {
        return (<Dialog
            contentStyle={styles.dialogContent}
            open={this.props.open}
            modal={true}
            onRequestClose={this.props.onRequestClose}
        >
            <div>{Strings.CHECK_YOUR_EMAIL}</div>
            <div>
                <RaisedButton label={Strings.RESEND_EMAIL} primary className="margin-top-10" onClick={this.onResendClick}/>
                <RaisedButton label={Strings.CLOSE} primary className="margin-top-10 margin-left-5" onClick={this.props.onRequestClose}/>
            </div>
        </Dialog>);
    }
}

const styles = {
    dialogContent: {
        textAlign: 'center'
    }
};

export default connect(null, EmailVerificationRequireDialog.DispatchToProps)(EmailVerificationRequireDialog);