/**
 * Created by kimchangduk on 2017-06-06.
 */

import { ActionTypes, Consts, Strings } from "../constants";
import update from "immutability-helper";

const initialState = {
  userTimeline: {
    state: {
      timelineType: Consts.TIMELINE_TYPE_ALL,
      requestPos: -1,
      nextPos: -1,
      done: false,
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: [],
  },
  tagTimeline: {
    state: {
      tag: "",
      page: -1,
      totalPages: -1,
      done: false,
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: [],
  },
  newsTimeline: {
    state: {
      request: Consts.REQUEST_NONE,
      id: -1,
      topPage: 0,
      bottomPage: 0,
      prevPageExists: true,
      nextPageExists: true,
      errorMessage: "",
    },
    dataSource: [],
  },
  pollTimeline: {
    state: {
      request: Consts.REQUEST_NONE,
    },
  },
  pollTagTimeline: {
    state: {
      request: Consts.REQUEST_NONE,
    },
  },
};

export default function timelineReducer(state = initialState, action) {
  switch (action.type) {
    //<editor-fold desc="get timeline">
    case ActionTypes.GET_TIMELINE_REQUEST:
      return update(state, {
        userTimeline: {
          state: {
            timelineType: { $set: action.timelineType },
            request: { $set: Consts.REQUEST_WAITING },
            requestPos: { $set: action.requestPos },
            done: { $set: false },
            errorMessage: { $set: "" },
          },
        },
      });
    case ActionTypes.GET_TIMELINE_SUCCESS: {
      if (action.timelineType !== state.userTimeline.state.timelineType) {
        return state;
      }

      if (state.userTimeline.state.requestPos !== action.requestPos) {
        return state;
      }

      const data = action.data;
      const nextPos = data.length > 0 ? data[data.length - 1].result.id : -1;
      const updateValue = {
        userTimeline: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            nextPos: { $set: nextPos },
            errorMessage: { $set: "" },
            done: { $set: data.length === 0 },
          },
        },
      };

      if (action.requestPos === null || action.requestPos === undefined) {
        updateValue.userTimeline.dataSource = { $set: data };
      } else {
        updateValue.userTimeline.dataSource = { $push: data };
      }

      return update(state, updateValue);
    }
    case ActionTypes.GET_TIMELINE_FAILURE:
      if (action.timelineType !== state.userTimeline.state.timelineType) {
        return state;
      }

      if (state.userTimeline.state.requestPos !== action.requestPos) {
        return state;
      }

      return update(state, {
        userTimeline: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="get tag timeline">
    case ActionTypes.GET_TAG_TIMELINE_REQUEST:
      return update(state, {
        tagTimeline: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            tag: { $set: action.tag },
            page: { $set: action.page },
            done: { $set: false },
            errorMessage: { $set: "" },
          },
        },
      });
    case ActionTypes.GET_TAG_TIMELINE_SUCCESS: {
      const data = action.data;
      if (state.tagTimeline.state.page !== action.page || state.tagTimeline.state.tag !== action.tag) {
        return state;
      }

      const updateValue = {
        tagTimeline: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            errorMessage: { $set: "" },
            done: { $set: state.tagTimeline.state.page === data.totalPages },
            totalPages: { $set: data.totalPages },
          },
        },
      };

      if (action.page === 0) {
        updateValue.tagTimeline.dataSource = { $set: data.content };
      } else {
        updateValue.tagTimeline.dataSource = { $push: data.content };
      }

      return update(state, updateValue);
    }
    case ActionTypes.GET_TAG_TIMELINE_FAILURE:
      if (state.tagTimeline.state.page !== action.page || state.tagTimeline.state.tag !== action.tag) {
        return state;
      }

      return update(state, {
        tagTimeline: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="poll timeline update">
    case ActionTypes.POLL_TIMELINE_UPDATE_REQUEST:
      if (state.userTimeline.state.timelineType !== action.timelineType) {
        return state;
      }
      return update(state, {
        pollTimeline: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
          },
        },
      });
    case ActionTypes.POLL_TIMELINE_UPDATE_SUCCESS: {
      if (state.userTimeline.state.timelineType !== action.timelineType) {
        return state;
      }

      const recentData = action.data;
      const insertNews = [];

      for (let i = 0; i < recentData.length; i++) {
        const news = recentData[i];
        if (news.result && state.userTimeline.dataSource.findIndex((item, key) => item.result && item.result.id === news.result.id) < 0) {
          insertNews.push(news);
        }
      }

      let updatedNews = null;
      if (insertNews.length > 0) {
        insertNews.splice(0, 0, 0, 0);
        updatedNews = update(state.userTimeline.dataSource, { $splice: [insertNews] });
      }

      const updateValues = {
        pollTimeline: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
        },
      };

      if (updatedNews) {
        updateValues.userTimeline = {
          dataSource: { $set: updatedNews.sort((item1, item2) => item2.result.createdDate - item1.result.createdDate) },
        };
      }

      return update(state, updateValues);
    }
    case ActionTypes.POLL_TIMELINE_UPDATE_FAILURE:
      if (state.userTimeline.state.timelineType !== action.timelineType) {
        return state;
      }

      return update(state, {
        pollTimeline: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="poll tag timeline update">
    case ActionTypes.POLL_TAG_TIMELINE_UPDATE_REQUEST: {
      return update(state, {
        pollTagTimeline: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
          },
        },
      });
    }
    case ActionTypes.POLL_TAG_TIMELINE_UPDATE_SUCCESS: {
      let updateTimelineValue = undefined;
      if (state.tagTimeline.state.tag === action.tag) {
        const recentData = action.data;
        const insertNews = [];
        if (recentData.content) {
          for (let i = 0; i < recentData.content.length; i++) {
            const news = recentData.content[i];
            if (state.tagTimeline.dataSource.findIndex((item, key) => item.id === news.id) < 0) {
              insertNews.push(news);
            }
          }
          insertNews.splice(0, 0, 0, 0);
        }
        updateTimelineValue = {
          dataSource: { $splice: [insertNews] },
        };
      }
      return update(state, {
        pollTagTimeline: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
        },
        tagTimeline: updateTimelineValue,
      });
    }
    case ActionTypes.POLL_TAG_TIMELINE_UPDATE_FAILURE:
      return update(state, {
        pollTagTimeline: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="news id로 가져오기">
    case ActionTypes.GET_NEWS_TIMELINE_REQUEST:
      return update(state, {
        newsTimeline: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            id: { $set: action.id },
            topPage: { $set: 0 },
            bottomPage: { $set: 0 },
            prevPageExists: { $set: true },
            nextPageExists: { $set: true },
          },
        },
      });

    case ActionTypes.GET_NEWS_TIMELINE_SUCCESS: {
      const data = action.data;
      return update(state, {
        newsTimeline: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            nextPageExists: { $set: !data.last },
          },
          dataSource: { $set: data.content },
        },
      });
    }

    case ActionTypes.GET_NEWS_TIMELINE_FAILURE:
      return update(state, {
        newsTimeline: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: Strings.FAIL_TO_LOAD_DATA },
          },
          dataSource: { $set: [] },
        },
      });

    case ActionTypes.GET_NEWS_TIMELINE_NEXT_REQUEST:
      return update(state, {
        newsTimeline: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            id: { $set: action.id },
            bottomPage: { $set: action.page },
          },
        },
      });

    case ActionTypes.GET_NEWS_TIMELINE_NEXT_SUCCESS: {
      const data = action.data;
      return update(state, {
        newsTimeline: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            nextPageExists: { $set: !data.last },
          },
          dataSource: { $push: data.content },
        },
      });
    }

    case ActionTypes.GET_NEWS_TIMELINE_NEXT_FAILURE:
      return update(state, {
        newsTimeline: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            nextPageExists: { $set: false },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });

    case ActionTypes.GET_NEWS_TIMELINE_PREV_REQUEST:
      return update(state, {
        newsTimeline: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            id: { $set: action.id },
            topPage: { $set: action.page },
          },
        },
      });

    case ActionTypes.GET_NEWS_TIMELINE_PREV_SUCCESS: {
      const data = action.data;
      return update(state, {
        newsTimeline: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            prevPageExists: { $set: !data.last },
          },
          dataSource: { $splice: [update(data.content.reverse(), { $splice: [[0, 0, 0, 0]] })] },
        },
      });
    }

    case ActionTypes.GET_NEWS_TIMELINE_PREV_FAILURE:
      return update(state, {
        newsTimeline: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            prevPageExists: { $set: false },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    default:
      return state;
  }
}
