/**
 * Created by kimchangduk on 2017-04-23.
 */

import { ActionTypes, Consts, Strings } from "../constants";
import update from "immutability-helper";

const initialState = {
  state: {
    request: Consts.REQUEST_NONE,
  },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SIGNUP_REQUEST:
      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_WAITING },
        },
      });

    case ActionTypes.SIGNUP_SUCCESS:
      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_SUCCESS },
        },
      });

    case ActionTypes.SIGNUP_FAILURE:
      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_FAILURE },
          errorMessage: { $set: action.errorMessage },
        },
      });

    default:
      return state;
  }
}
