/**
 * Created by kimchangduk on 2017-04-24.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  project: {
    state: {
      request: Consts.REQUEST_NONE,
      error: null,
      errorMessage: "",
      urlKey: "",
    },
    dataSource: null,
  }, // rss or urlKey로 project get
  search: {
    state: {
      request: Consts.REQUEST_NONE,
      error: null,
      errorMessage: "",
      keyword: "",
    },
    dataSource: null,
  },
};

function getProjectUpdateValue(project, action) {
  let result;
  if (action.subscribing) {
    result = {
      subscribe: { $set: action.data },
      subscribing: { $set: true },
    };
    if (!action.modify) {
      result.subscribersCount = { $set: Math.max(0, project.subscribersCount + 1) };
    }
  } else {
    result = {
      subscribe: { $set: null },
      subscribersCount: { $set: Math.max(0, project.subscribersCount - 1) },
      subscribing: { $set: false },
    };
  }
  return result;
}

export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    //<editor-fold desc="Get project">
    case ActionTypes.GET_PROJECT_REQUEST:
      return update(state, {
        project: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
            error: { $set: null },
            requestAt: { $set: action.requestAt },
            urlKey: { $set: action.urlKey },
          },
          dataSource: { $set: null },
        },
      });

    case ActionTypes.GET_PROJECT_SUCCESS:
      if (action.requestAt !== state.project.state.requestAt || action.urlKey !== state.project.state.urlKey) {
        return state;
      }
      return update(state, {
        project: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $set: action.data },
        },
      });

    case ActionTypes.GET_PROJECT_FAILURE:
      if (action.requestAt !== state.project.state.requestAt || action.urlKey !== state.project.state.urlKey) {
        return state;
      }
      return update(state, {
        project: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
            error: { $set: action.error },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="Search project">
    case ActionTypes.SEARCH_PROJECT_REQUEST:
      return update(state, {
        search: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            keyword: { $set: action.keyword },
          },
        },
      });

    case ActionTypes.SEARCH_PROJECT_SUCCESS:
      if (action.keyword !== state.search.state.keyword) {
        return state;
      }
      return update(state, {
        search: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $set: action.data },
        },
      });

    case ActionTypes.SEARCH_PROJECT_FAILURE:
      if (action.keyword !== state.search.state.keyword) {
        return state;
      }
      return update(state, {
        search: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            error: { $set: action.error },
            errorMessage: { $set: action.errorMessage },
          },
          dataSource: { $set: null },
        },
      });
    //</editor-fold>

    //<editor-fold desc="Subscribe">
    case ActionTypes.SUBSCRIBE_CHANGE_SUCCESS: {
      const needProjectUpdate = state.project.dataSource && action.urlKey === state.project.dataSource.urlKey;
      const needSearchUpdate = state.search.dataSource && state.search.dataSource.findIndex((a) => a.urlKey === action.urlKey) >= 0;

      if (!needProjectUpdate && !needSearchUpdate) return state;

      const updateValues = {};
      if (needProjectUpdate) {
        updateValues.project = {
          dataSource: getProjectUpdateValue(state.project.dataSource, action),
        };
      }
      if (needSearchUpdate) {
        const index = state.search.dataSource.findIndex((a) => a.urlKey === action.urlKey);
        const newProject = update(state.search.dataSource[index], getProjectUpdateValue(state.search.dataSource[index], action));
        updateValues.search = {
          dataSource: { $splice: [[index, 1, newProject]] },
        };
      }
      return update(state, updateValues);
    }

    case ActionTypes.ADD_NOTIFY_KEYWORD_SUCCESS: {
      const needProjectUpdate = state.project.dataSource && action.urlKey === state.project.dataSource.urlKey;
      const needSearchUpdate = state.search.dataSource && state.search.dataSource.findIndex((a) => a.urlKey === action.urlKey) >= 0;

      if (!needProjectUpdate && !needSearchUpdate) return state;

      const updateValues = {};
      if (needProjectUpdate && state.project.dataSource.subscribe) {
        updateValues.project = {
          dataSource: {
            subscribe: {
              keywords: { $set: action.data.keywords },
              notifyMinute: { $set: action.data.notifyMinute },
            },
          },
        };
      }
      if (needSearchUpdate) {
        const index = state.search.dataSource.findIndex((a) => a.urlKey === action.urlKey);
        if (index >= 0 && state.search.dataSource[index].subscribe) {
          const newProject = update(state.search.dataSource[index], {
            subscribe: {
              keywords: { $set: action.data.keywords },
              notifyMinute: { $set: action.data.notifyMinute },
            },
          });
          updateValues.search = {
            dataSource: { $splice: [[index, 1, newProject]] },
          };
        }
      }
      return update(state, updateValues);
    }

    case ActionTypes.DELETE_NOTIFY_KEYWORD_SUCCESS: {
      const needProjectUpdate = state.project.dataSource && action.urlKey === state.project.dataSource.urlKey;
      const needSearchUpdate = state.search.dataSource && state.search.dataSource.findIndex((a) => a.urlKey === action.urlKey) >= 0;

      if (!needProjectUpdate && !needSearchUpdate) return state;

      const updateValues = {};
      if (needProjectUpdate && state.project.dataSource.subscribe) {
        const keywordIndex = state.project.dataSource.subscribe.keywords
          ? state.project.dataSource.subscribe.keywords.findIndex((a) => a.keyword === action.keyword)
          : -1;
        if (keywordIndex >= 0) {
          updateValues.project = {
            dataSource: {
              subscribe: {
                keywords: { $splice: [[keywordIndex, 1]] },
              },
            },
          };
        }
      }
      if (needSearchUpdate) {
        const index = state.search.dataSource.findIndex((a) => a.urlKey === action.urlKey);
        if (index >= 0 && state.search.dataSource[index].subscribe && state.search.dataSource[index].subscribe.keywords) {
          const keywordIndex = state.search.dataSource[index].subscribe.keywords.findIndex((a) => a.keyword === action.keyword);
          if (keywordIndex >= 0) {
            const newProject = update(state.search.dataSource[index], {
              subscribe: {
                keywords: { $splice: [[keywordIndex, 1]] },
              },
            });
            updateValues.search = {
              dataSource: { $splice: [[index, 1, newProject]] },
            };
          }
        }
      }
      return update(state, updateValues);
    }
    //</editor-fold>

    default:
      return state;
  }
}
