/**
 * Created by kimchangduk on 2018-01-25.
 */

import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getAppHistory, moveLoginPage} from '../Utils';
import {Consts, Strings, Urls} from '../constants';
import {Tabs, Tab} from 'material-ui/Tabs';
import HomeIcon from 'material-ui/svg-icons/action/home';
import FavoriteIcon from 'material-ui/svg-icons/action/favorite';
import SettingIcon from 'material-ui/svg-icons/action/settings';
import ChatIcon from 'material-ui/svg-icons/communication/chat';
import PersonIcon from 'material-ui/svg-icons/social/person';
import {getContactMenuLink} from "../constants/Urls";

class TabPager extends React.Component {
    static StateToProps = (state) => {
        return {
            isLogin: state.user.state.isLogin
        };
    };

    static propTypes = {
        location: PropTypes.object,
        isLogin: PropTypes.bool
    };

    state = {
        index: -1
    };

    componentDidMount() {
        this.adjustTabValue(this.props.location.pathname);
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            this.adjustTabValue(nextProps.location.pathname);
        }
    };

    adjustTabValue = (pathname) => {
        switch (pathname) {
            case Urls.DISCOVER:
            case '/':
                this.setState({index: TAB_HOME});
                break;
            case Urls.MOBILE_SUBSCRIBE_LIST:
                this.setState({index: TAB_SUBSCRIBING});
                break;
            case Urls.SETTING:
                this.setState({index: TAB_SETTING});
                break;
            case Urls.LOGIN:
                this.setState({index: TAB_LOGIN});
                break;
            case Urls.CONTACT:
                this.setState({index: TAB_CONTACT});
                break;
            default:
                this.setState({index: TAB_NONE});
                break;
        }
    };

    onTabIndexChange = (value) => {
        const history = getAppHistory();
        switch (value) {
            case TAB_HOME:
                history.replace("/");
                break;

            case TAB_SUBSCRIBING:
                history.replace(Urls.MOBILE_SUBSCRIBE_LIST);
                break;

            case TAB_CONTACT:
                history.replace(getContactMenuLink());
                break;

            case TAB_SETTING:
                history.replace(Urls.SETTING);
                break;

            case TAB_LOGIN:
                moveLoginPage();
                break;
        }
    };

    render() {
        const tabs = [];
        if (this.props.isLogin === true) {
            tabs.push(<Tab
                key={0}
                buttonStyle={styles.tabDiv}
                className="tab"
                icon={<HomeIcon/>}
                label={Strings.HOME}
                value={TAB_HOME}
            />);
            tabs.push(<Tab
                key={1}
                buttonStyle={styles.tabDiv}
                className="tab"
                icon={<FavoriteIcon/>}
                label={Strings.SUBSCRIPTIONS}
                value={TAB_SUBSCRIBING}
            />);
            tabs.push(<Tab
                key={2}
                buttonStyle={styles.tabDiv}
                className="tab"
                icon={<ChatIcon/>}
                label={Strings.CONTACT}
                value={TAB_CONTACT}
            />);
            tabs.push(<Tab
                key={3}
                buttonStyle={styles.tabDiv}
                className="tab"
                icon={<SettingIcon/>}
                label={Strings.SETTING}
                value={TAB_SETTING}
            />);
        } else if (this.props.isLogin === false) {
            tabs.push(<Tab
                key={0}
                buttonStyle={styles.tabDiv}
                className="tab"
                icon={<HomeIcon/>}
                label={Strings.HOME}
                value={TAB_HOME}
            />);
            tabs.push(<Tab
                key={1}
                buttonStyle={styles.tabDiv}
                className="tab"
                icon={<ChatIcon/>}
                label={Strings.CONTACT}
                value={TAB_CONTACT}
            />);
            tabs.push(<Tab
                key={2}
                buttonStyle={styles.tabDiv}
                className="tab"
                icon={<PersonIcon/>}
                label={Strings.SIGN_IN}
                value={TAB_LOGIN}
            />);
        }
        return (<Tabs
            className="tab-pager"
            tabTemplateStyle={{height: 30}}
            inkBarStyle={styles.inkBar}
            value={this.state.index}
            onChange={this.onTabIndexChange}
        >
            {tabs}
        </Tabs>);
    }
}

const TAB_NONE = "NONE";
const TAB_HOME = "HOME";
const TAB_SUBSCRIBING = "SUBSCRIBING";
const TAB_CONTACT = "TAB_CONTACT";
const TAB_SETTING = "SETTING";
const TAB_LOGIN = "LOGIN";

const styles = {
    tabDiv: {
        userSelect: 'none',
        height: Consts.TAB_PAGER_HEIGHT,
        fontSize: 12
    },
    inkBar: {
        backgroundColor: Consts.BLUE_COLOR
    }
};

export default connect(TabPager.StateToProps)(TabPager);