import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, Messaging, onMessage } from "@firebase/messaging";
import { getAuth } from "@firebase/auth";
import { getProjectFeeds } from "./actions/feed";
import { store } from "./store";
import { registerFcmToken } from "./Utils";
import { Urls } from "./constants";
import { processLoginWithFirebaseAuth } from "./actions/user";

const config = {
  apiKey: "AIzaSyCHWyVa5E2HJ76AHu78PlksKukwQB3trvY",
  authDomain: "noti-hub.firebaseapp.com",
  databaseURL: "https://noti-hub.firebaseio.com",
  projectId: "noti-hub",
  storageBucket: "noti-hub.appspot.com",
  messagingSenderId: "1098601175489",
};

initializeApp(config);
console.log("initialize");

//<editor-fold desc="Cloud Message 관련 설정">

export const messaging = (function () {
  let messaging: Messaging | undefined;
  try {
    messaging = getMessaging();
  } catch (error) {
    console.error(error);
  }
  return messaging;
})();

if (messaging) {
  onMessage(messaging, (payload) => {
    const feedState = store.getState();
    if (feedState.feed.project.state.urlKey !== null) {
      store.dispatch(getProjectFeeds(feedState.feed.project.state.urlKey));
    }
  });
  getToken(messaging, { vapidKey: "BB21IyZRF3BsrD3oWxhP8TgK3JJ8c6DZVlBAGtoZE_yGaujpqFkPP_nhl_KY-172snhCM2J_DALByzxNQHs9ccA" })
    .then((currentToken) => {
      if (currentToken) {
        registerFcmToken(currentToken);
      } else {
        console.log("No registration token available. Request permission to generate one.");
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
}

//</editor-fold>

export const auth = getAuth();
auth.onAuthStateChanged((user) => {
  if (user) {
    const userState = store.getState().user.state;
    const userDataSource: any = store.getState().user.dataSource;
    if (
      (userState.isLogin !== null && !(userState.isLogin && userDataSource && userDataSource.firebase && userDataSource.firebase.uid === user.uid)) || // Firebase SDK 세션과 현재 쿠키 세션이 다름
      window.location.pathname.startsWith(Urls.WAIT_AUTH_CHANGE) // Firebase Auth Change 대기 페이지
    ) {
      const dispatcher = processLoginWithFirebaseAuth(user);
      if (dispatcher) {
        dispatcher(store.dispatch);
      }
    }
  }
});
