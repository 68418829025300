import React from 'react';
import PropTypes from 'prop-types';
import {Urls, Consts} from '../constants';

class KakaoChat extends React.Component {
    static propTypes = {};

    render() {
        return <div style={styles.container}>
            <iframe src={Urls.KAKAO_CHAT_LINK} style={styles.iframe}/>
        </div>;
    }
}

const styles = {
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden'
    },
    iframe: {
        width: '100%',
        height: '100%',
        border: 'none'
    }
};

export default KakaoChat;