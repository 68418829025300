/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import { Strings, Urls } from "../constants";
import "./DiscoverPage.scss";
import { getAppHistory } from "../Utils";
import SubscribePopup from "../components/SubscribePopup";
import axios from "axios";
import ProjectList from "../components/ProjectList";
import ProjectListItem from "../components/ProjectListItem";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";

class DiscoverPage extends React.Component {
  static propTypes = {
    location: PropTypes.object,
  };

  state = {
    inputStr: "",
    inputFocus: false,
    top10Projects: null,
  };

  componentDidMount() {
    axios.get("/v1/project/top10").then((response) => {
      this.setState({ top10Projects: response.data });
    });
  }

  onKeyDown = (e) => {
    switch (e.keyCode) {
      case 13:
        getAppHistory().push(Urls.getSearchResult(this.state.inputStr));
        break;
    }
  };

  render() {
    return (
      <div className="center-align-page discover-page">
        <div>
          <p className="welcome" dangerouslySetInnerHTML={{ __html: Strings.DISCOVER_PAGE_WELCOME }} />
          <div className={`search-div ${this.state.inputFocus ? "focus" : ""}`}>
            <i className="fas fa-search" />
            <input
              onFocus={() => {
                this.setState({ inputFocus: true });
              }}
              onBlur={() => {
                this.setState({ inputFocus: false });
              }}
              onKeyDown={this.onKeyDown}
              value={this.state.inputStr}
              placeholder={Strings.SEARCH_INPUT_PLACEHOLDER}
              onChange={(e) => this.setState({ inputStr: e.target.value })}
            />
          </div>
          {this.state.top10Projects ? (
            <div className="top10-projects">
              <p>TOP 10 SOURCES</p>
              {this.state.top10Projects.map((item, key) => {
                return (
                  <Link to={Urls.getProjectFeedPage(item.urlKey)} className="rank-item" key={key}>
                    <div className="thumbnail" style={{ backgroundImage: `url("${item.image}")` }} />
                    <p>{item.title}</p>
                  </Link>
                );
              })}
            </div>
          ) : undefined}
        </div>
        {this.props.location.state && this.props.location.state.target ? (
          <SubscribePopup
            key={1}
            linkTitle={this.props.location.state.title}
            link={this.props.location.state.target}
            onRequestClose={() => {
              getAppHistory().replace(this.props.location.pathname, {});
            }}
          />
        ) : undefined}
      </div>
    );
  }
}

export default withRouter(DiscoverPage);
