/**
 * Created by kimchangduk on 2017-05-20.
 */

import React from "react";
import Radium from "radium";
import { Strings, Consts, Urls } from "../../constants/index";
import PropTypes from "prop-types";

let AppStoreImage;
if (Strings.getLanguageCode() === "ko") AppStoreImage = "https://s3.ap-northeast-2.amazonaws.com/notihub/images/appstore_kr.svg";
else AppStoreImage = "https://s3.ap-northeast-2.amazonaws.com/notihub/images/appstore_en.svg";

class AppStoreLink extends React.Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  render() {
    const style = {};
    if (this.props.height !== undefined) {
      style.height = this.props.height;
    }
    if (this.props.padding !== undefined) {
      style.padding = this.props.padding;
    }

    return (
      <a className="position-relative display-inline-block cursor-pointer" target="_blank" href={Urls.APPSTORE}>
        <img style={[styles.appStoreImage, style]} src={AppStoreImage} />
      </a>
    );
  }
}

const styles = {
  appStoreImage: {
    height: Consts.IS_MOBILE ? 50 : 70,
    padding: Consts.IS_MOBILE ? 9 : 12,
  },
};

export default Radium(AppStoreLink);
