import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  total: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
      lastFeedId: -1,
      last: false,
    },
    dataSource: null,
  },
  project: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
      lastFeedId: -1,
      urlKey: "",
      last: false,
    },
    dataSource: null,
  },
  getFeedsByIds: {
    state: {
      request: Consts.REQUEST_NONE,
      error: null,
      errorMessage: "",
      ids: null,
    },
    dataSource: null,
  },
};

export default function feedReducer(state = initialState, action) {
  switch (action.type) {
    //<editor-fold desc="Get feed total list">
    case ActionTypes.GET_FEED_TOTAL_LIST_REQUEST: {
      const updateValues = {
        total: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
            lastFeedId: { $set: action.lastFeedId },
          },
        },
      };

      if (action.lastFeedId === undefined || action.lastFeedId === null) {
        updateValues.total.dataSource = { $set: [] };
      }

      return update(state, updateValues);
    }

    case ActionTypes.GET_FEED_TOTAL_LIST_SUCCESS: {
      if (action.lastFeedId !== state.total.state.lastFeedId) {
        return state;
      }
      const data = action.data;
      const updateValue = {
        total: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            last: { $set: false },
          },
        },
      };

      if (action.lastFeedId === undefined || action.lastFeedId === null) {
        updateValue.total.dataSource = { $set: data };
      } else {
        updateValue.total.dataSource = { $push: data };
      }

      return update(state, updateValue);
    }

    case ActionTypes.GET_FEED_TOTAL_LIST_FAILURE: {
      if (action.lastFeedId !== state.total.state.lastFeedId) {
        return state;
      }

      let updateValues;
      if (action.error && action.error.response && action.error.response.status === 404) {
        updateValues = {
          total: {
            state: {
              request: { $set: Consts.REQUEST_SUCCESS },
              last: { $set: true },
            },
          },
        };
      } else {
        updateValues = {
          total: {
            state: {
              request: { $set: Consts.REQUEST_FAILURE },
              errorMessage: { $set: action.errorMessage },
            },
          },
        };
      }

      return update(state, updateValues);
    }
    //</editor-fold>

    //<editor-fold desc="Get project feed list">
    case ActionTypes.GET_PROJECT_FEED_LIST_REQUEST: {
      const updateValues = {
        project: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
            urlKey: { $set: action.urlKey },
            lastFeedId: { $set: action.lastFeedId },
          },
        },
      };

      if (state.project.state.urlKey !== action.urlKey || action.lastFeedId === undefined || action.lastFeedId === null) {
        updateValues.project.dataSource = { $set: null };
      }
      return update(state, updateValues);
    }

    case ActionTypes.GET_PROJECT_FEED_LIST_SUCCESS: {
      if (action.lastFeedId !== state.project.state.lastFeedId || action.urlKey !== state.project.state.urlKey) {
        return state;
      }
      const data = action.data;
      const updateValue = {
        project: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            last: { $set: false },
          },
        },
      };

      if (action.lastFeedId === undefined || action.lastFeedId === null) {
        updateValue.project.dataSource = { $set: data };
      } else {
        updateValue.project.dataSource = { $push: data };
      }

      return update(state, updateValue);
    }

    case ActionTypes.GET_PROJECT_FEED_LIST_FAILURE: {
      if (action.lastFeedId !== state.project.state.lastFeedId || action.urlKey !== state.project.state.urlKey) {
        return state;
      }

      let updateValues;
      if (action.error && action.error.response && action.error.response.status === 404) {
        updateValues = {
          project: {
            state: {
              request: { $set: Consts.REQUEST_SUCCESS },
              last: { $set: true },
            },
          },
        };
      } else {
        updateValues = {
          project: {
            state: {
              request: { $set: Consts.REQUEST_FAILURE },
              errorMessage: { $set: action.errorMessage },
            },
          },
        };
      }

      return update(state, updateValues);
    }
    //</editor-fold>

    //<editor-fold desc="Get feeds by id">
    case ActionTypes.GET_FEEDS_BY_ID_REQUEST:
      return update(state, {
        getFeedsByIds: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            error: { $set: null },
            errorMessage: { $set: "" },
            ids: { $set: action.ids },
          },
          dataSource: { $set: null },
        },
      });

    case ActionTypes.GET_FEEDS_BY_ID_SUCCESS:
      if (action.ids !== state.getFeedsByIds.state.ids) {
        return state;
      }

      return update(state, {
        getFeedsByIds: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $set: action.data },
        },
      });

    case ActionTypes.GET_FEEDS_BY_ID_FAILURE:
      if (action.ids !== state.getFeedsByIds.state.ids) {
        return state;
      }

      return update(state, {
        getFeedsByIds: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            error: { $set: action.error },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    default:
      return state;
  }
}
