import React, { Component, RefObject } from "react";
import update from "immutability-helper";
import "./RssBuilderIframe.scss";
import getXPath from "get-xpath";
import { CircularProgress, Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, TextField, IconButton, FlatButton } from "material-ui";
import RefreshIcon from "material-ui/svg-icons/navigation/refresh";
import axios from "axios";
import { Strings } from "../constants";
import { genRss, Generator, editRss } from "../Api";
import { FAILED_TO_FETCH_HTML } from "../constants/Strings";

interface Props {
  style?: React.CSSProperties;
  iframeStyle?: React.CSSProperties;
  iframeUrl: string;
  onRegisterSuccess?: () => void;
  generator?: Generator;
  editMode?: boolean;
}
interface State {
  clickHighlightsElement: HTMLElement[];
  disableIframe: boolean;
  tableRows: { thumbnail: string; title: string; link: string }[];
  title: string;
  description: string;
  xPaths: XPaths;
}

interface XPaths {
  itemXPath: string;
  linkXPath: string;
  titleXPath: string;
  thumbnailXPath: string;
}

export default class RssBuilderIframe extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.iframeRef = React.createRef<HTMLIFrameElement>();

    this.state = {
      clickHighlightsElement: [],
      disableIframe: false,
      tableRows: [],
      title: props.generator?.title || "",
      description: props.generator?.description || "",
      xPaths: {
        itemXPath: props.generator?.itemXpath || "",
        linkXPath: props.generator?.linkXpath || "",
        titleXPath: props.generator?.titleXpath || "",
        thumbnailXPath: props.generator?.thumbnailXpath || "",
      },
    };
  }

  componentDidMount() {
    if (this.iframeRef?.current) {
      this.fetchHTML(this.props.iframeUrl);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.iframeUrl !== this.props.iframeUrl) {
      this.fetchHTML(this.props.iframeUrl);
    }
    if (prevState.clickHighlightsElement !== this.state.clickHighlightsElement) {
      if (this.iframeRef?.current) {
        const document = this.iframeRef.current.contentDocument;
        if (document) {
          const containerId = "select-highlight-div";
          const prevContainer = document.querySelector(`#${containerId}`);
          if (prevContainer) {
            prevContainer.remove();
          }

          if (this.state.clickHighlightsElement.length > 0) {
            const container = document.createElement("div");
            container?.setAttribute("id", containerId);

            for (let element of this.state.clickHighlightsElement) {
              const selectedElementHighlight = document.createElement("div");
              const rect = element.getBoundingClientRect();

              const scrollOffset = {
                left: document.scrollingElement?.scrollLeft || 0,
                top: document.scrollingElement?.scrollTop || 0,
              };
              selectedElementHighlight.setAttribute("id", "selected-highlight");
              selectedElementHighlight.style.position = "absolute";
              selectedElementHighlight.style.zIndex = "2147483647";
              selectedElementHighlight.style.left = `${rect.left + scrollOffset.left}px`;
              selectedElementHighlight.style.top = `${rect.top + scrollOffset.top}px`;
              selectedElementHighlight.style.width = `${rect.width}px`;
              selectedElementHighlight.style.height = `${rect.height}px`;
              selectedElementHighlight.style.backgroundColor = "rgba(0,0,0,0.5)";
              container.append(selectedElementHighlight);
            }
            document.body.append(container);
          }
        }
      }
    }

    if (this.state.xPaths !== prevState.xPaths && this.iframeRef?.current) {
      this.displayIframeXpathItems();
    }
  }

  componentWillUnmount() {
    if (this.iframeRef?.current?.contentWindow) {
      this.iframeRef?.current.contentWindow.removeEventListener("click", this.onIframeDocumentClick, { capture: true });
    }
  }

  onRefreshClick = () => {
    this.setState({
      clickHighlightsElement: [],
      xPaths: {
        itemXPath: "",
        titleXPath: "",
        thumbnailXPath: "",
        linkXPath: "",
      },
    });
    if (this.iframeRef?.current?.contentDocument?.body) {
      this.iframeRef.current.contentDocument.body.innerHTML = "";
    }
    this.fetchHTML(this.props.iframeUrl);
  };

  fetchHTML(url: string) {
    this.setState({ disableIframe: true });
    axios
      .get(`${window.location.protocol}//${window.location.host}/v1/rssgen/html?url=${encodeURIComponent(url)}`)
      .then((response) => {
        const html = response.data;
        if (this.iframeRef?.current) {
          this.iframeRef.current.contentDocument?.open();
          this.iframeRef.current.contentDocument?.write(html);
          this.iframeRef.current.contentDocument?.close();
          this.iframeRef.current.contentWindow?.removeEventListener("click", this.onIframeDocumentClick, { capture: true });
          this.iframeRef.current.contentWindow?.addEventListener("click", this.onIframeDocumentClick, { capture: true });
          this.injectStyle();
          if (this.state.xPaths.itemXPath) {
            this.displayIframeXpathItems();
          }
        }
        this.setState({ disableIframe: false });
      })
      .catch((error) => {
        console.error(error);
        window.alert(FAILED_TO_FETCH_HTML);
      });
  }

  register = () => {
    genRss({
      isPublic: true,
      title: this.state.title,
      description: this.state.description,
      itemXpath: this.state.xPaths.itemXPath,
      titleXpath: this.state.xPaths.titleXPath,
      thumbnailXpath: this.state.xPaths.thumbnailXPath,
      linkXpath: this.state.xPaths.linkXPath,
      url: this.props.iframeUrl,
    })
      .then((response) => {
        this.props.onRegisterSuccess?.();
      })
      .catch((error) => {
        console.error(error);
        window.alert(Strings.FAILED_TO_REGISTER);
      });
  };

  edit = () => {
    if (!this.props.generator) {
      return;
    }
    editRss(this.props.generator.uuid, {
      isPublic: true,
      title: this.state.title,
      description: this.state.description,
      itemXpath: this.state.xPaths.itemXPath,
      titleXpath: this.state.xPaths.titleXPath,
      thumbnailXpath: this.state.xPaths.thumbnailXPath,
      linkXpath: this.state.xPaths.linkXPath,
      url: this.props.iframeUrl,
    })
      .then((response) => {
        this.props.onRegisterSuccess?.();
      })
      .catch((error) => {
        console.error(error);
        window.alert(Strings.FAILED_TO_REGISTER);
      });
  };

  onIframeDocumentClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.xPaths.itemXPath) {
      if (this.state.clickHighlightsElement.length === 1) {
        const parentElement = findCommonParent(this.state.clickHighlightsElement[0], e.target as HTMLElement);
        this.extractXPaths(this.state.clickHighlightsElement[0], e.target as HTMLElement);
        if (!parentElement) {
          window.alert(Strings.FAILED_TO_EXTRACT);
          this.onRefreshClick();
          return;
        }
      }
      this.setState(
        update(this.state, {
          clickHighlightsElement: {
            $push: [e.target] as HTMLElement[],
          },
        })
      );
    }
  };

  extractXPaths = (titleElement1: HTMLElement, titleElement2: HTMLElement) => {
    const result = this.parseItemXPath(getXPath(titleElement1), getXPath(titleElement2));
    if (result) {
      const defaultTitle = this.iframeRef?.current?.contentDocument?.title || "";
      this.setState({
        xPaths: result,
        title: this.props.editMode ? this.state.title : defaultTitle,
        description: "",
      });
    }
  };

  parseItemXPath: (xpath1: string, xpath2: string) => XPaths | null = (xpath1: string, xpath2: string) => {
    const xpath1Splitted = xpath1.split("/");
    const xpath2Splitted = xpath2.split("/");
    for (let i = 0; i < xpath1Splitted.length; i++) {
      if (xpath1Splitted[i] !== xpath2Splitted[i]) {
        const result: XPaths = {
          itemXPath: xpath1Splitted.slice(0, i).join("/") + `/${xpath1Splitted[i].replace(/\[\d+\]/g, "")}`,
          linkXPath: "",
          thumbnailXPath: "",
          titleXPath: "",
        };

        if (result.itemXPath[result.itemXPath.length - 1] !== "a") {
          const remain = xpath1Splitted.slice(i + 1);
          const aIndex = remain.findIndex((a) => a === "a");
          const linkXPath = `${remain.slice(0, aIndex + 1).join("/")}`;
          result.linkXPath = linkXPath;
          result.titleXPath = `${remain.join("/")}`;
          result.thumbnailXPath = `.//img`;
        }
        return result;
      }
    }
    return null;
  };

  iframeRef: RefObject<HTMLIFrameElement> | null = null;

  Step1Guide = () => {
    const xpathSetted = this.state.xPaths.itemXPath;
    return (
      <>
        <div className={`step-guide ${!xpathSetted ? "active" : ""}`} key={0}>
          <p>반복 아이템중 게시글 제목을 두개 클릭하세요. {!xpathSetted ? <span>{`(${this.state.clickHighlightsElement.length}/2)`}</span> : undefined}</p>
        </div>
        {xpathSetted && (
          <div className={`step-guide active`} key={1}>
            <p>
              다시 설정하려면{" "}
              <span className="icon">
                <RefreshIcon color="#4d4d4d" />
              </span>
              아이콘을 클릭해 주세요.
            </p>
          </div>
        )}
      </>
    );
  };

  Step2 = () => {
    const { xPaths } = this.state;
    return (
      <form
        className="step2"
        onSubmit={(e) => {
          e.preventDefault();
          if (this.props.editMode) {
            this.edit();
          } else {
            this.register();
          }
        }}
      >
        <div className="row">
          <label>사이트 제목*</label>
          <TextField value={this.state.title} onChange={(e) => this.setState({ title: (e.target as any).value as string })} />
        </div>
        <div className="row">
          <label>사이트 설명</label>
          <TextField value={this.state.description} onChange={(e) => this.setState({ description: (e.target as any).value as string })} />
        </div>
        {/* <div className="row">
          <label>아이템 XPath</label>
          <TextField value={xPaths.itemXPath} />
        </div>
        <div className="row">
          <label>제목 XPath</label>
          <TextField value={xPaths.titleXPath} />
        </div>
        <div className="row">
          <label>썸네일 XPath</label>
          <TextField value={xPaths.thumbnailXPath} />
        </div>
        <div className="row">
          <label>링크 XPath</label>
          <TextField value={xPaths.linkXPath} />
        </div> */}
        <div className="result">
          <p>추출 결과</p>
          <Table className="parse-result-table" selectable={false}>
            <TableHeader displaySelectAll={false}>
              <TableRow>
                <TableHeaderColumn>제목</TableHeaderColumn>
                <TableHeaderColumn style={{ width: 100 }}>썸네일</TableHeaderColumn>
                <TableHeaderColumn>링크</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {this.state.tableRows.map((item, key) => {
                console.log(item);
                return (
                  <TableRow key={key}>
                    <TableRowColumn>{item.title}</TableRowColumn>
                    <TableRowColumn style={{ width: 100 }}>
                      {item.thumbnail && <img src={item.thumbnail} alt={`rss-builder-thumbnail-${key + 1}`} />}
                    </TableRowColumn>
                    <TableRowColumn>
                      {item.link ? (
                        <a href={item.link} target="_blank" rel="noreferrer">
                          {item.link}
                        </a>
                      ) : (
                        ""
                      )}
                    </TableRowColumn>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <button type="submit" className={`${this.state.title ? "active" : ""} register-button`} disabled={!this.state.title}>
          {this.props.editMode ? Strings.EDIT : Strings.REGISTER}
        </button>
      </form>
    );
  };

  injectStyle = () => {
    const styleId = `rss-builder-highlight-style`;
    if (this.iframeRef?.current?.contentDocument) {
      const document = this.iframeRef?.current?.contentDocument;
      let element = document.getElementById(styleId);
      if (!element) {
        element = document.createElement("style");
        element.setAttribute("id", styleId);
        element.innerHTML = highlightStyle;
        document.body.append(element);
      }
    }
  };

  displayIframeXpathItems = () => {
    const document = this.iframeRef?.current?.contentDocument;
    if (!document) {
      return;
    }

    let rows: { thumbnail: string; title: string; link: string }[] = [];
    const result = getElementsByXPath(document, this.state.xPaths.itemXPath);
    if (result) {
      for (let i = 0; i < result.length; i++) {
        const itemElement = result[i];
        itemElement.classList.add("rss-builder-highlight-item");
        let row = rows[i] || { thumbnail: "", title: "", link: "" };

        const titleElement = getElementsByXPath(document, this.state.xPaths.titleXPath, itemElement);
        if (titleElement && titleElement.length > 0) {
          row.title = titleElement[0].innerText || "";
          titleElement[0].classList.add("rss-builder-highlight-title");
        }

        const thumbnailElement = getElementsByXPath(document, this.state.xPaths.thumbnailXPath, itemElement);
        if (thumbnailElement && thumbnailElement.length > 0) {
          row.thumbnail = thumbnailElement[0].getAttribute("src") || "";
          thumbnailElement[0].classList.add("rss-builder-highlight-thumbnail");
        }

        const linkElement = getElementsByXPath(document, this.state.xPaths.linkXPath, itemElement);
        if (linkElement && linkElement.length > 0) {
          row.link = linkElement[0].getAttribute("href") || "";
          linkElement[0].classList.add("rss-builder-highlight-link");
        }

        rows.push(row);
      }
    }
    this.setState({ tableRows: rows });
  };

  render() {
    const { style, iframeStyle } = this.props;
    return (
      <div style={style} className="rss-builder-iframe">
        <div className="step-guide-container">
          <this.Step1Guide />
        </div>
        <div className="iframe-wrapper">
          <iframe
            title="notihub-rss-builder"
            ref={this.iframeRef}
            style={iframeStyle}
            sandbox="allow-same-origin"
            onLoad={() => {
              this.iframeRef?.current?.contentWindow?.removeEventListener("click", this.onIframeDocumentClick, { capture: true });
              this.iframeRef?.current?.contentWindow?.addEventListener("click", this.onIframeDocumentClick, { capture: true });
            }}
          />
          {this.state.xPaths.itemXPath && (
            <IconButton onClick={this.onRefreshClick} className="refresh-button" style={refreshButtonStyle as any}>
              <RefreshIcon color="#ffffff" />
            </IconButton>
          )}
          {this.state.disableIframe ? (
            <div className="disable-iframe-div">
              <CircularProgress />
            </div>
          ) : undefined}
        </div>
        {this.state.xPaths.itemXPath ? <this.Step2 /> : undefined}
      </div>
    );
  }
}

function findCommonParent(element1: HTMLElement | null, element2: HTMLElement | null): HTMLElement | null {
  if (element1 === null || element2 === null) {
    return null;
  }
  if (element1 === element2) {
    return element1;
  }
  return findCommonParent(element1.parentElement, element2.parentElement);
}

function getElementsByXPath(document: Document, xpath: string, contextElement?: Node) {
  const result: HTMLElement[] = [];
  try {
    const xPathResult = document.evaluate(xpath, contextElement || document);
    let element: Node | null;
    if (xPathResult) {
      while ((element = xPathResult.iterateNext())) {
        result.push(element as HTMLElement);
      }
    }
  } catch (error) {
    console.error(error);
  }
  return result;
}

const highlightStyle = `
.rss-builder-highlight-item {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.rss-builder-highlight-title {
  background-color: rgba(255, 0, 0, 0.1) !important;
}
.rss-builder-highlight-thumbnail {
  background-color: rgba(0, 255, 0, 0.1) !important;
  border: 2px dotted black !important;
  box-sizing: border-box !important;
}

`;

const refreshButtonStyle = {
  position: "absolute",
  bottom: "1rem",
  right: "1rem",
  backgroundColor: "#616161",
  borderRadius: "50%",
};
