/**
 * Created by kimchangduk on 2018-01-25.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getAppHistory, moveLoginPage} from "../Utils";
import {List, ListItem} from 'material-ui/List';
import PersonIcon from 'material-ui/svg-icons/social/person';
import LogoutIcon from 'material-ui/svg-icons/action/exit-to-app';
import RSSIcon from 'material-ui/svg-icons/communication/rss-feed';
import {Strings, Urls} from '../constants';
import {Link} from "react-router-dom";
import {logout} from "../actions/user";

class SettingTabPage extends React.Component {
    static StateToProps = (state) => {
        return {
            isLogin: state.user.state.isLogin
        };
    };

    static DispatchToProps = (dispatch) => {
        return {
            actions: {
                logout: () => {
                    dispatch(logout());
                }
            }
        };
    };

    static propTypes = {
        isLogin: PropTypes.bool,
        actions: PropTypes.object
    };

    componentDidMount() {
        if (this.props.isLogin === false) {
            moveLoginPage();
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.isLogin !== nextProps.isLogin) {
            if (this.props.isLogin === true && nextProps.isLogin === false) {
                getAppHistory().replace("/");
            } else if (nextProps.isLogin === false) {
                moveLoginPage();
            }
        }
    };

    render() {
        return (<div>
            <List style={styles.list}>
                <Link to={Urls.PROFILE}><ListItem style={styles.listItem} primaryText={Strings.PROFILE} leftIcon={<PersonIcon/>}/></Link>
                <Link to={Urls.RSS_LIST}><ListItem style={styles.listItem} primaryText={Strings.MY_RSS_PAGE_LIST} leftIcon={<RSSIcon/>}/></Link>
                <ListItem onClick={this.props.actions.logout} style={styles.listItem} primaryText={Strings.SIGN_OUT} leftIcon={<LogoutIcon/>}/>
            </List>
        </div>);
    }
}

const styles = {
    list: {
        padding: 0
    },
    listItem: {
        borderBottom: '1px solid #d8d8d8'
    }
};

export default connect(SettingTabPage.StateToProps, SettingTabPage.DispatchToProps)(SettingTabPage);