/**
 * Created by kimchangduk on 2017-04-23.
 */

import { ActionTypes } from "../constants";
import update from "immutability-helper";

const DEFAULT_AUTO_HIDE_DURATION = 3000;
const initialState = {
  open: false,
  message: "",
  autoHideDuration: DEFAULT_AUTO_HIDE_DURATION,
};

export default function globalSnackbarReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SNACKBAR_OEPN:
      return update(state, {
        message: { $set: action.message },
        autoHideDuration: { $set: action.autoHideDuration === undefined ? DEFAULT_AUTO_HIDE_DURATION : action.autoHideDuration },
        open: { $set: true },
      });

    case ActionTypes.SNACKBAR_CLOSE:
      return update(state, {
        open: { $set: false },
      });

    default:
      return state;
  }
}
