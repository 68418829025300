/**
 * Created by kimchangduk on 6/9/2018.
 */

import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";

class BaseDialog extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    onRequestClose: PropTypes.func,
    title: PropTypes.node,
    titleStyle: PropTypes.object,
    contentStyle: PropTypes.object,
    contentClassName: PropTypes.string,
    bodyStyle: PropTypes.object,
    modal: PropTypes.bool,
  };

  static defaultProps = {
    modal: false,
  };

  render() {
    const { title, titleStyle, contentClassName, contentStyle, bodyStyle, modal, children } = this.props;
    return (
      <div style={styles.container}>
        <div style={[styles.dialog, contentStyle]} className={contentClassName}>
          {title ? (
            <div className="title" style={[styles.title, titleStyle]}>
              {title}
            </div>
          ) : undefined}
          {children ? (
            <div className="body" style={[styles.body, title ? styles.paddingTopZero : undefined, bodyStyle]}>
              {children}
            </div>
          ) : undefined}
        </div>
        <div style={styles.overlay} />
      </div>
    );
  }
}

const styles = {
  container: {
    position: "fixed",
    display: "flex",
    boxSizing: "border-box",
    tapHighlightColor: "rgba(0, 0, 0, 0)",
    zIndex: 1500,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    alignItems: "center",
  },
  overlay: {
    position: "fixed",
    height: "100%",
    width: "100%",
    top: "0px",
    left: "0px",
    opacity: "1",
    backgroundColor: "rgba(0, 0, 0, 0.54)",
    tapHighlightColor: "rgba(0, 0, 0, 0)",
    willChange: "opacity",
    transition: "left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    zIndex: "1400",
    transform: "translateZ(0px)",
  },
  title: {
    margin: "0px 0px -1px",
    padding: "24px 24px 20px",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 22,
    lineHeight: "32px",
    fontWeight: 400,
    position: "relative",
  },
  dialog: {
    backgroundColor: "#ffffff",
    borderRadius: 2,
    zIndex: 1500,
    minWidth: 300,
    maxWidth: 768,
    maxHeight: "100%",
    width: "75%",
    margin: "auto",
    overflow: "auto",
  },
  body: {
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.6)",
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    boxSizing: "border-box",
    overflowY: "auto",
    borderTop: "none",
    borderBottom: "none",
    width: "100%",
    position: "relative",
  },
  paddingTopZero: {
    paddingTop: 0,
  },
};

export default Radium(BaseDialog);
