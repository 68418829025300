/**
 * Created by kimchangduk on 2017-05-02.
 */

import React from 'react';
import axios from 'axios';
import {Consts} from '../../constants';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import queryString from 'query-string';
import {loginWithToken, loginWithOAuth} from '../../actions/user.js';
import {showSnackbar} from '../../actions/globalSnackbar';

class MobileLogin extends React.Component {
    static StateToProps = (state, ownProps) => {
        return {
            request: state.user.state.loginRequest,
            errorMessage: state.user.state.loginErrorMessage
        };
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {
                loginWithToken: () => {
                    const {token, username} = queryString.parse(ownProps.location.search);
                    dispatch(loginWithToken(username, token));
                },
                loginWithOAuth: () => {
                    const {provider, token} = queryString.parse(ownProps.location.search);
                    dispatch(loginWithOAuth(provider, {token}));
                },
                showSnackbar: (text) => {
                    dispatch(showSnackbar(text));
                }
            }
        };
    };

    static propTypes = {
        request: PropTypes.string,
        errorMessage: PropTypes.string,
        history: PropTypes.object,
        location: PropTypes.object,
        actions: PropTypes.object
    };

    componentDidMount() {
        this.process();
    }

    componentWillReceiveProps(nextProps) {
        if (Consts.hasChangedRequestWaitingToSuccess(this.props.request, nextProps.request)) {
            const {fcm, os} = queryString.parse(this.props.location.search);
            if (fcm) {
                const parameter = {token: fcm};
                if (os) {
                    parameter.os = os;
                }

                axios.post(`/v1/account/fcm`, parameter).then(response => {
                    this.redirect();
                }).catch(error => {
                    this.redirect();
                });
            } else {
                this.redirect();
            }
        } else if (Consts.hasChangedRequestWaitingToFailure(this.props.request, nextProps.request)) {
            this.redirect();
            this.props.actions.showSnackbar(nextProps.errorMessage);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.process();
        }
    }

    process = () => {
        const {type} = queryString.parse(this.props.location.search);
        switch (type ? type.toLowerCase() : '') {
            case TYPE_TOKEN:
                this.props.actions.loginWithToken();
                break;
            case TYPE_OAUTH:
                this.props.actions.loginWithOAuth();
                break;
            case TYPE_NONE:
            default:
                this.props.history.push('/');
                break;
        }
    };

    redirect() {
        const {redirect} = queryString.parse(this.props.location.search);
        if (redirect) {
            window.location.href = redirect;
        } else {
            this.props.history.push('/');
        }
    }

    render() {
        return (<div/>);
    }
}

const TYPE_NONE = 'none';
const TYPE_TOKEN = 'token';
const TYPE_OAUTH = 'oauth';

export default connect(MobileLogin.StateToProps, MobileLogin.DispatchToProps)(MobileLogin);