/**
 * Created by kimchangduk on 2017-06-09.
 */
import React from 'react';
class DialogManager extends React.Component {
    static dialogs = [];
    static component = [];
    static push = (dialog, props = undefined) => {
        DialogManager.dialogs.push({class: dialog, props});
        DialogManager.component.forceUpdate();
    };

    static pop = () => {
        if (DialogManager.dialogs.length > 0) {
            DialogManager.dialogs.splice(DialogManager.dialogs.length - 1, 1);

            // dim 클릭시 dim 뒤쪽 엘리먼트에 클릭이벤트가 동작할수도 있어서 setTimeout 사용
            setTimeout(() => {
                DialogManager.component.forceUpdate();
            }, 100);
        }
    };

    static setComponent = (component) => {
        DialogManager.component = component;
    };

    constructor(props) {
        super(props);
        DialogManager.setComponent(this);
    }

    renderDialog = (item, key) => (<item.class key={key} open={true} onRequestClose={() => {
        setTimeout(DialogManager.pop, 100);
    }} {...item.props}/>);

    render() {
        return <div>{DialogManager.dialogs.map((item, key) => this.renderDialog(item, key))}</div>;

    }
}

export default DialogManager;