/**
 * Created by kimchangduk on 2017-04-24.
 */
import {API_URL} from './Consts';
import {getLanguageCode} from "./Strings";
import {Consts} from "./index";


export const LINK = '/link';
export const LOGIN = '/login';
export const MOBILE_LOGIN = '/v1/login';
export const SWAGGER = `${API_URL}/swagger-ui.html`;
export const SIGNUP = '/signup';
export const KAKAO_CHAT = '/kakaoChat';
export const CONTACT = '/contact';
export const HELP = '/help';
export const PROFILE = '/profile';
export const PLAYSTORE = 'https://play.google.com/store/apps/details?id=net.notihub.android';
export const APPSTORE = 'https://itunes.apple.com/us/app/notihub/id1251994340';
export const PROJECT_LIST = '/projects';
export const MOBILE_SUBSCRIBE_LIST = '/subscribes';
export const DASHBOARD = '/dashboard';
export const SETTING = '/setting';
export const PROJECT_ADD = '/project/add';
export const EDIT_USER_API = '/v1/account';
export const IMAGE_UPLOAD_API = '/v1/util/image';
export const BOARD_REQUEST = '/board/request';
export const WAIT_AUTH_CHANGE = '/wait_auth_change';
export const DISCOVER = '/discover';
export const KAKAO_CHAT_LINK = 'https://pf.kakao.com/_RiURC';
export const RSS_BUILD = '/rss/build';
export const RSS_LIST = '/rss/list';
export const getRssEditLink = (uuid) => `/rss/${uuid}/edit`;
export const getRssPage = (uuid) => `${Consts.API_URL}/v1/rssgen/${uuid}`;
export const getContactMenuLink = () => {
    return getLanguageCode() === 'ko' ? KAKAO_CHAT : CONTACT;
};
export const getSearchResult = (query) => `/search/${encodeURIComponent(encodeURIComponent(query))}`;
export const getSubscriptionSearch = (query) => `/subscription/search/${encodeURIComponent(encodeURIComponent(query))}`;
export const getNewsUrl = (username, urlKey, id) => (`/${username}/${urlKey}/news?id=${id}`);
export const getFullUrl = (pathname) => (`${window.location.protocol}//${window.location.host}${pathname}`);
export const getBoard = (boardUrlKey, page = 0) => (page === undefined ? `/board/${boardUrlKey}` : `/board/${boardUrlKey}?page=${page}`);
export const getBoardPostView = (urlKey, postId) => (`/board/${urlKey}/${postId}`);
export const getBoardPostWrite = (boardUrlKey, postId) => (postId === undefined ? `/board/${boardUrlKey}/write` : `/board/${boardUrlKey}/${postId}/edit`);
export const getProjectFeedPage = (urlKey, doubleEncode = true) => doubleEncode ?
    (`/${encodeURIComponent(encodeURIComponent(urlKey))}`) :
    (`/${encodeURIComponent(urlKey)}`);
export const getProjectFeedSearchPage = (urlKey, searchWord, doubleEncode = true) => doubleEncode ?
    (`/${encodeURIComponent(encodeURIComponent(urlKey))}/search/${encodeURIComponent(encodeURIComponent(searchWord))}`) :
    (`/${encodeURIComponent(urlKey)}/search/${encodeURIComponent(searchWord)}`);

export const getShareUrl = (urlKey, id) => {
    return getFullUrl(`/${encodeURIComponent(encodeURIComponent(urlKey))}/feed/${id}`);
};

export const getTagProjects = (tag) => (`/projects/tag/${tag}`);
export const getTagTimeline = (tag) => (`/timeline/tag/${tag}`);
export const getFeedPage = (urlKey, feedId) => (`/${urlKey}/feed/${feedId}`);