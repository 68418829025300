import update from "immutability-helper";
import { ActionTypes } from "../constants";

const initialState = {
  closeWaiting: false,
  feeds: null,
  index: 0,
};

export default function feedContentLayerReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.OPEN_FEED_LAYER:
      return update(state, {
        closeWaiting: { $set: false },
        feeds: { $set: action.feeds },
        index: { $set: action.index },
      });

    case ActionTypes.CLOSE_FEED_LAYER_REQUEST:
      return update(state, {
        closeWaiting: { $set: true },
      });

    case ActionTypes.CLOSE_FEED_LAYER:
      return update(state, {
        closeWaiting: { $set: false },
        feeds: { $set: null },
        index: { $set: -1 },
      });

    case ActionTypes.SET_FEED_LAYER_INDEX:
      return update(state, {
        index: { $set: action.index },
      });

    case ActionTypes.SET_FEED_LAYER_FEEDS: {
      const updateValue = {
        feeds: { $set: action.feeds },
      };
      if (action.keepCurrentFeed) {
        let newIndex;
        if (
          action.feeds &&
          state.feeds &&
          state.feeds[state.index] &&
          (newIndex = action.feeds.findIndex((item) => item.id === state.feeds[state.index].id)) >= 0
        ) {
          updateValue.index = { $set: newIndex };
        } else {
          return state;
        }
      }

      return update(state, updateValue);
    }

    default:
      return state;
  }
}
