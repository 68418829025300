/**
 * Created by kimchangduk on 2018-03-31.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Urls} from '../constants';
import LoaderPage from "./LoaderPage";
import {getFeedsById, getProjectFeeds} from "../actions/feed";
import {hasChangedRequestWaitingToFailure, hasChangedRequestWaitingToSuccess} from "../constants/Consts";
import {getAppHistory} from "../Utils";
import {showSnackbar} from "../actions/globalSnackbar";

class ProjectFeedPage extends React.Component {
    static StateToProps = (state, ownProps) => {
        return {
            feedRequest: state.feed.getFeedsByIds.state.request,
            feedErrorMessage: state.feed.getFeedsByIds.state.errorMessage,
            feedDataSource: state.feed.getFeedsByIds.dataSource
        };
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {
                getFeed: (id = undefined) => {
                    if (id === undefined) {
                        id = ownProps.match.params.id;
                    }
                    dispatch(getFeedsById(id));
                },
                openSnackBar: (message) => {
                    dispatch(showSnackbar(message));
                }
            }
        };
    };

    static propTypes = {
        actions: PropTypes.object,
        match: PropTypes.object,
        feedRequest: PropTypes.string,
        feedErrorMessage: PropTypes.string,
        feedDataSource: PropTypes.array
    };

    componentDidMount() {
        this.props.actions.getFeed();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.urlKey !== nextProps.match.params.urlKey ||
            this.props.match.params.id !== nextProps.match.params.id) {
            this.props.actions.getFeed(nextProps.match.params.urlKey, nextProps.match.params.id);
        }

        if (hasChangedRequestWaitingToSuccess(this.props.feedRequest, nextProps.feedRequest)) {
            getAppHistory().replace(Urls.getProjectFeedPage(decodeURIComponent(this.props.match.params.urlKey), true), {
                openFeed: nextProps.feedDataSource ? nextProps.feedDataSource[0] : null
            });
        }

        if (hasChangedRequestWaitingToFailure(this.props.feedRequest, nextProps.feedRequest)) {
            this.props.actions.openSnackBar(nextProps.feedErrorMessage);
            getAppHistory().replace('/');
        }

    }

    render() {
        return <LoaderPage/>;
    }
}

export default connect(ProjectFeedPage.StateToProps, ProjectFeedPage.DispatchToProps)(ProjectFeedPage);