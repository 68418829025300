/**
 * Created by kimchangduk on 5/19/2018.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Urls, Consts, Strings } from "../constants";
import { List, ListItem } from "material-ui/List";
import { FlatButton, RaisedButton } from "material-ui";
import { getAppHistory } from "../Utils";
import AddIcon from "material-ui/svg-icons/content/add";
import axios from "axios";
import withRouter, { withRouterProps } from "../withRouter";
import { getRssRemoveConfirmText } from "../constants/Strings";
import { deleteRss, getRssProject } from "../Api";

interface State {
  list: GeneratorItem[] | null;
  waiting: boolean;
}
class RssListPage extends React.Component<withRouterProps<{}>, State> {
  static propTypes = {
    actions: PropTypes.object,
  };

  state: State = {
    list: null,
    waiting: false,
  };

  componentDidMount() {
    this.fetchRssList();
  }

  fetchRssList() {
    this.setState({ waiting: true });
    axios
      .get(`/v1/rssgen/my`)
      .then((response) => {
        this.setState({ waiting: false, list: response.data });
      })
      .catch((error) => {
        this.setState({ waiting: false });
      });
  }

  onRssBuildLinkClick = () => {
    this.props.navigate(Urls.RSS_BUILD);
  };

  //#region Button Event Handler (View, Edit, Delete)

  onViewButtonClick = (link: string) => {
    getRssProject(link)
      .then((response) => {
        this.props.navigate(`/${response.data.urlKey}`);
      })
      .catch((error) => {
        console.error(error);
        window.alert(Strings.FAILED_TO_GET_PROJECT);
      });
  };

  onEditButtonClick = (uuid: string) => {
    getAppHistory().push(Urls.getRssEditLink(uuid));
  };

  onRemoveButtonClick = (title: string, uuid: string) => {
    if (window.confirm(getRssRemoveConfirmText(title))) {
      deleteRss(uuid)
        .then((response) => {
          this.fetchRssList();
        })
        .catch((error) => {
          console.error(error);
          window.alert(Strings.FAILED_TO_DELETE);
        });
    }
  };

  //#endregion

  render() {
    return (
      <div className={Consts.IS_MOBILE ? "" : "center-align-page"}>
        <div style={styles.addButton}>
          <RaisedButton onClick={this.onRssBuildLinkClick} icon={<AddIcon />} label={Strings.RSS_GENERATOR} fullWidth primary />
        </div>
        <List style={styles.list}>
          {this.state.list
            ? this.state.list.map((item, key) => {
                return (
                  <ListItem
                    key={key}
                    disableTouchRipple
                    style={styles.listItem}
                    onClick={() => {
                      this.onViewButtonClick(item.rssUrl);
                    }}
                    primaryText={
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {item.title}
                        <div style={{ marginLeft: "auto" }}>
                          <RaisedButton
                            primary
                            style={styles.actionButton}
                            label="보기"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.onViewButtonClick(item.rssUrl);
                            }}
                          />
                          <RaisedButton
                            primary
                            style={styles.actionButton}
                            label="수정"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.onEditButtonClick(item.uuid);
                            }}
                          />
                          <RaisedButton
                            primary
                            style={styles.actionButton}
                            label="삭제"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.onRemoveButtonClick(item.title, item.uuid);
                            }}
                          />
                        </div>
                      </div>
                    }
                  />
                );
              })
            : undefined}
        </List>
      </div>
    );
  }
}

const styles = {
  list: {
    padding: 0,
  },
  listItem: {
    borderBottom: "1px solid #d8d8d8",
  },
  addButton: Consts.IS_MOBILE
    ? {
        padding: "1em",
      }
    : {
        marginBottom: "1em",
      },
  actionButton: { minWidth: "4rem", fontSize: "0.9rem", marginLeft: "0.5rem", color: "#fff" },
};

export default withRouter(RssListPage);

type GeneratorItem = {
  id: 0;
  uuid: string;
  userId: 0;
  isPublic: true;
  title: string;
  description: string;
  rssUrl: string;
  url: string;
  createdAt: string;
  updatedAt: string;
  editedUrl: string;
};
