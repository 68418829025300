import React from "react";
import { connect } from "react-redux";
import Radium from "radium";
import PropTypes from "prop-types";
import { Strings, Consts } from "../constants";
import "./SubscribeButton.scss";
import { hasChangedRequestWaitingToFailure } from "../constants/Consts";
import { showSnackbar } from "../actions/globalSnackbar";
import TimerIcon from "material-ui/svg-icons/image/timer";
import TimerOffIcon from "material-ui/svg-icons/image/timer-off";
import CloseIcon from "material-ui/svg-icons/navigation/close";
import { isDescendant, moveLoginPage } from "../Utils";
import { addNotifyKeyword, deleteNotifyKeyword, subscribe, unsubscribe } from "../actions/subscribe";

class SubscribeButton extends React.Component {
  static StateToProps = (state) => {
    return {
      requestState: state.subscribe.subscribeRequest.state,
      isLogin: state.user.state.isLogin,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        requestSubscribe: (subscribing, notifyMinute) => {
          if (subscribing) {
            dispatch(subscribe(ownProps.urlKey, notifyMinute, ownProps.subscribing));
          } else {
            dispatch(unsubscribe(ownProps.urlKey));
          }
        },
        addNotifyKeyword: (keyword) => {
          dispatch(addNotifyKeyword(ownProps.urlKey, keyword));
        },
        deleteNotifyKeyword: (keyword) => {
          dispatch(deleteNotifyKeyword(ownProps.urlKey, keyword));
        },
        showSnackbar: (text) => {
          dispatch(showSnackbar(text));
        },
      },
    };
  };

  static propTypes = {
    subscribing: PropTypes.bool,
    subscribe: PropTypes.object,
    urlKey: PropTypes.string,
    keyword: PropTypes.string,
    style: PropTypes.object,
    actions: PropTypes.object,
    requestState: PropTypes.object,
    isButtonStyle: PropTypes.bool,
    optionMenuRightAlign: PropTypes.bool,
    isLogin: PropTypes.bool,
    menuStyle: PropTypes.object,
    menuArrowStyle: PropTypes.object,
  };

  static defaultProps = {
    isButtonStyle: true,
    optionMenuRightAlign: true,
    keyword: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      addKeywordInput: "",
      filterOptionOpen: props.subscribing && props.subscribe && props.subscribe.notifyMinute !== null,
    };
  }

  componentDidMount() {
    window.addEventListener("click", this.onWindowClick);
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (hasChangedRequestWaitingToFailure(this.props.requestState.request, nextProps.requestState.request)) {
      this.props.actions.showSnackbar(nextProps.requestState.errorMessage);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("click", this.onWindowClick);
  }

  onClick = () => {
    if (!this.props.subscribing) {
      this.requestSubscribe(true, null);
    }

    if (!this.state.menuOpen)
      window.setTimeout(() => {
        this.setState({ menuOpen: true });
      });
  };

  onWindowClick = (e) => {
    if (this.optionMenuElement && this.state.menuOpen && !isDescendant(this.optionMenuElement, e.target)) {
      e.preventDefault();
      this.setState({ menuOpen: false });
    }
  };

  onCreateOptionMenuElement = (instance) => {
    this.optionMenuElement = instance;
    if (instance) {
      instance.focus();
    }
  };

  requestSubscribe = (subscribing, notifyMinute) => {
    if (!this.props.isLogin) {
      moveLoginPage();
    } else {
      this.props.actions.requestSubscribe(subscribing, notifyMinute);
    }
  };

  onKeywordInputKeyDown = (e) => {
    switch (e.keyCode) {
      case 13:
        this.props.actions.addNotifyKeyword(this.state.addKeywordInput);
        break;
    }
  };

  onDeleteKeywordClick = (keyword) => {
    this.props.actions.deleteNotifyKeyword(keyword);
  };

  renderKeywords = () => {
    return (
      <div className="filter-list">
        <p className="label">{Strings.FILTER}</p>
        {this.props.subscribe.keywords
          ? this.props.subscribe.keywords.map((item, key) => {
              return (
                <div key={key} className="filter-item">
                  {item.keyword}
                  <i
                    className="delete-button far fa-trash-alt"
                    onClick={() => {
                      this.onDeleteKeywordClick(item.keyword);
                    }}
                  />
                </div>
              );
            })
          : undefined}
        <div>
          <input
            value={this.state.addKeywordInput}
            onKeyDown={this.onKeywordInputKeyDown}
            placeholder={Strings.NOTIFICATION_FILTERING_KEYWORD_PLACEHOLDER}
            onChange={(e) => {
              this.setState({ addKeywordInput: e.target.value });
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    const notifyMinuteNullSubscribing = this.props.subscribe ? this.props.subscribe.notifyMinute === null : false;
    const notifyMinute30Subscribing = this.props.subscribe ? this.props.subscribe.notifyMinute === 30 : false;

    return (
      <div className="position-relative" style={this.props.style}>
        {this.props.isButtonStyle ? (
          <button key={0} style={[this.props.subscribing ? styles.subscribing : styles.notSubscribing]} onClick={this.onClick}>
            {this.props.subscribing ? Strings.SUBSCRIBING : Strings.SUBSCRIBE}
          </button>
        ) : (
          <a style={[styles.underline, this.props.subscribing ? { color: greenColor } : undefined]} onClick={this.onClick}>
            {this.props.subscribing ? Strings.SUBSCRIBING : Strings.SUBSCRIBE}
          </a>
        )}
        {this.state.menuOpen ? (
          <div
            ref={(instance) => {
              this.onCreateOptionMenuElement(instance);
            }}
            key={1}
            style={this.props.menuStyle}
            className={`subscribe-option-menu ${this.props.optionMenuRightAlign ? "" : "left"}`}
          >
            <div className="arrow" style={this.props.menuArrowStyle} />

            <button
              tabIndex={-1}
              style={notifyMinuteNullSubscribing ? styles.activeButton : undefined}
              onClick={() => {
                this.requestSubscribe(true, null);
              }}
            >
              <TimerOffIcon color={notifyMinuteNullSubscribing ? greenColor : subscribeOptionMenuIconColor} /> <span>{Strings.NOT_NOTIFY}</span>
            </button>
            <button
              tabIndex={-1}
              style={notifyMinute30Subscribing ? styles.activeButton : undefined}
              onClick={() => {
                this.requestSubscribe(true, 30);
              }}
            >
              <TimerIcon color={notifyMinute30Subscribing ? greenColor : subscribeOptionMenuIconColor} /> <span>{Strings.NOTIFY_EVERY_30_MIN}</span>
            </button>
            {notifyMinute30Subscribing ? this.renderKeywords() : undefined}
            {this.props.subscribing ? (
              <button
                tabIndex={-1}
                style={{ color: redColor }}
                onClick={() => {
                  if (this.props.subscribe.keywords && this.props.subscribe.keywords.length > 0) {
                    if (window.confirm(Strings.UNSUBSCRIBE_CONFIRM)) {
                      this.requestSubscribe(false);
                    }
                  } else {
                    this.requestSubscribe(false);
                  }
                }}
              >
                <CloseIcon color={redColor} /> <span>{Strings.UNSUBSCRIBE}</span>
              </button>
            ) : undefined}
          </div>
        ) : undefined}
      </div>
    );
  }
}

const greenColor = "#66BB6A";
const redColor = "#EF5350";
const subscribeOptionMenuIconColor = "#333333";

const styles = {
  notSubscribing: {
    minWidth: 76,
    display: "inline-block",
    boxShadow: `0 0 2px ${greenColor}`,
    color: greenColor,
    borderColor: greenColor,
    padding: "5px 10px",
  },
  subscribing: {
    minWidth: 76,
    display: "inline-block",
    padding: "5px 10px",
  },
  activeButton: {
    borderColor: greenColor,
    color: greenColor,
    boxShadow: `0 0 2px ${greenColor}`,
    fontWeight: "bold",
  },
  underline: {
    textDecoration: "underline",
  },
};

export default connect(SubscribeButton.StateToProps, SubscribeButton.DispatchToProps)(Radium(SubscribeButton));
