/**
 * Created by kimchangduk on 2017-04-21.
 */
import React from "react";
import FloatingActionButton from "material-ui/FloatingActionButton";
import { connect } from "react-redux";
import GA from "../GA";
import PropTypes from "prop-types";
import muiThemeable from "material-ui/styles/muiThemeable";
import Snackbar from "material-ui/Snackbar";
import Drawer from "../components/Drawer";
import LeftIcon from "material-ui/svg-icons/hardware/keyboard-arrow-left";
import { getUser, logout } from "../actions/user";
import { hideSnackbar } from "../actions/globalSnackbar";
import { Urls, Consts } from "../constants";
import { moveLoginPage, moveSignUpPage, getAppHistory, isIosApp, isMobileApp, callFeedContentLayerOpenChangedAndroidInterface } from "../Utils";
import DialogManager from "../dialogs/DialogManager";
import TabPager from "../components/TabPager";
import AppStorePopup from "../components/AppStorePopup";
import { STATE_KEY_ALLOW_DOWNLOAD_POPUP } from "../constants/Consts";
import "./App.scss";
import AppBar from "../components/AppBar";
import FeedContentLayer from "../components/FeedContentLayer";
import Cookies from "js-cookie";
import AdFitHorizontal from "../components/AdFitHorizontal";
import withRouter from "../withRouter";
import { relativeTimeThreshold } from "moment";

class App extends React.Component {
  static propTypes = {
    children: PropTypes.element,
    muiTheme: PropTypes.object,
    actions: PropTypes.object,
    location: PropTypes.object,
    globalSnackbar: PropTypes.object,
    currentUser: PropTypes.object,
    isLogin: PropTypes.bool,
    isAdmin: PropTypes.bool,
    logoutRequest: PropTypes.string,
    logoutRedirectUrl: PropTypes.string,
  };

  constructor(props) {
    super(props);
    let fixedCookieValue = Cookies.get(Consts.COOKIE_KEY_DRAWER_FIXED);
    if (fixedCookieValue === undefined) {
      fixedCookieValue = true;
    } else if (typeof fixedCookieValue === "string") {
      fixedCookieValue = fixedCookieValue.toLowerCase() === "true";
    }

    this.state = {
      open: !!fixedCookieValue,
      drawerFixed: !!fixedCookieValue,
    };
  }

  componentDidMount() {
    if (!window.location.pathname.startsWith("/v1/") && !window.location.pathname.startsWith(Urls.WAIT_AUTH_CHANGE)) {
      this.props.actions.getUser();
    }
    GA.logPage();
    callFeedContentLayerOpenChangedAndroidInterface(!!window.location.state && !!window.location.state.feedLayerOpen);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      GA.logPage();
    }

    if (this.props.currentUser !== nextProps.currentUser) {
      GA.setUserId(nextProps.currentUser ? nextProps.currentUser.id : null);
    }

    if (this.props.isLogin === null && nextProps.isLogin === false) {
      switch (nextProps.location.pathname) {
        case Urls.LOGIN:
        case Urls.MOBILE_LOGIN:
        case Urls.WAIT_AUTH_CHANGE:
        case Urls.LINK:
          break;
        default:
          if (
            Consts.IS_MOBILE &&
            !isMobileApp() &&
            (!this.props.location || !this.props.location.state || this.props.location.state[STATE_KEY_ALLOW_DOWNLOAD_POPUP] !== false)
          ) {
            DialogManager.push(AppStorePopup);
          }
          break;
      }
    }

    const prevOpenFeed = !!this.props.location.state && !!this.props.location.state.feedLayerOpen;
    const nextOpenFeed = !!nextProps.location.state && !!nextProps.location.state.feedLayerOpen;
    if (prevOpenFeed !== nextOpenFeed) {
      callFeedContentLayerOpenChangedAndroidInterface(nextOpenFeed);
    }
  }

  componentDidUpdate(prevProps) {
    if (Consts.hasChangedRequestWaitingToSuccess(prevProps.logoutRequest, this.props.logoutRequest)) {
      switch (this.props.logoutRedirectUrl) {
        case Urls.LOGIN:
          moveLoginPage();
          break;

        case Urls.SIGNUP:
          moveSignUpPage();
          break;

        default:
          this.props.navigate(this.props.logoutRedirectUrl, { replace: true });
          break;
      }
    }
  }

  onBackButtonClick = () => {
    this.props.navigate(-1);
  };

  onTouchContent = () => {
    if (!this.state.drawerFixed && this.state.open) {
      this.setState({ open: false });
    }
  };

  render() {
    const isMobile = Consts.IS_MOBILE;
    return (
      <div className="app">
        {Consts.IS_MOBILE ? undefined : (
          <Drawer
            fixed={this.state.drawerFixed}
            className={`drawer ${this.state.open ? "open" : ""}`}
            open={this.state.open}
            openRequest={() => {
              this.setState({ open: true });
            }}
            fixToggleRequest={() => {
              Cookies.set(Consts.COOKIE_KEY_DRAWER_FIXED, !this.state.drawerFixed);
              this.setState({ drawerFixed: !this.state.drawerFixed });
            }}
            iconElementLeft={undefined}
          />
        )}
        {this.props.location.pathname !== Urls.DISCOVER ? (
          <AppBar
            className={isMobile ? "mobile" : `${this.state.drawerFixed ? "fixed" : ""} ${this.state.open ? "open" : ""}`}
            location={this.props.location}
            onClick={this.onTouchContent}
          />
        ) : undefined}
        <div
          className={isMobile ? "content mobile" : `content ${this.state.open ? "open" : ""} ${this.state.drawerFixed ? "fixed" : ""}`}
          onClick={this.onTouchContent}
        >
          {this.props.children}
        </div>
        {Consts.IS_MOBILE ? <TabPager location={this.props.location} /> : undefined}
        <FeedContentLayer location={this.props.location} />
        <Snackbar
          style={
            Consts.IS_MOBILE
              ? {
                  transform: this.props.globalSnackbar.open ? `translate(-50%, 0)` : "translate(-50%, 48px)",
                }
              : {}
          }
          open={this.props.globalSnackbar.open}
          message={this.props.globalSnackbar.message}
          autoHideDuration={this.props.globalSnackbar.autoHideDuration}
          onRequestClose={this.props.actions.hideSnackbar}
        />
        <DialogManager />
        {isIosApp() && getAppHistory().length > 1 ? (
          <FloatingActionButton style={styles.backButton} backgroundColor={styles.backButtonBackground} onClick={this.onBackButtonClick}>
            <LeftIcon style={styles.leftIcon} />
          </FloatingActionButton>
        ) : undefined}
        {/* <AdFitHorizontal /> */}
      </div>
    );
  }
}

const styles = {
  backButtonBackground: "#FFFFFF",
  backButton: {
    position: "fixed",
    bottom: (Consts.IS_MOBILE ? Consts.TAB_PAGER_HEIGHT : 0) + 20,
    left: 20,
    opacity: 0.75,
  },
  leftIcon: {
    fill: "#808080",
    width: 35,
  },
};

const DispatchToProps = (dispatch, ownProps) => {
  return {
    actions: {
      getUser: () => {
        dispatch(getUser());
      },
      logout: () => {
        dispatch(logout());
      },
      hideSnackbar: () => {
        dispatch(hideSnackbar());
      },
    },
  };
};

const StateToProps = (state) => {
  return {
    globalSnackbar: state.globalSnackbar,
    currentUser: state.user.dataSource,
    isLogin: state.user.state.isLogin,
    isAdmin: state.user.state.isAdmin,
    logoutRequest: state.user.state.logoutRequest,
    logoutRedirectUrl: state.user.state.logoutRedirectUrl,
  };
};

export default connect(StateToProps, DispatchToProps)(muiThemeable()(withRouter(App)));
