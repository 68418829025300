/**
 * Created by kimchangduk on 2017-04-28.
 */
import React from 'react';
import {Link} from 'react-router-dom';
import {Urls, Consts} from '../constants';
import PropTypes from 'prop-types';
import ImageView from "./ImageView";
import TagList from "./TagList";
import SubscribeButton from "./SubscribeButton";
import "./ProjectDescription.scss";
import {getAppHistory} from "../Utils";

class ProjectDescription extends React.Component {
    static propTypes = {
        data: PropTypes.object,
        style: PropTypes.object
    };

    onTitleClick = () => {
        if (this.props.data.link) {
            window.open(this.props.data.link);
        }
    };

    render() {
        const data = this.props.data;
        return (
            <div className="project-description" style={this.props.style}>
                {
                    data.image ? <div className="left">
                        <ImageView className="thumbnail" url={data.image} width={60} height={60}/>
                    </div> : undefined
                }
                <div className="right">
                    <div className="title" style={styles.title}>
                        <span onClick={this.onTitleClick} className="cursor-pointer">{data.title}</span>
                    </div>
                    <SubscribeButton
                        subscribing={data.subscribing}
                        subscribe={data.subscribe}
                        style={styles.subscribeButton}
                        urlKey={data.urlKey}
                        optionMenuRightAlign={!Consts.IS_MOBILE}
                        menuStyle={styles.subscribeMenu}
                        menuArrowStyle={styles.subscribeArrowMenu}
                    />
                    <p className="subscribers">{data.subscribersCount} followers</p>
                    <p className="description">
                        {data.description}
                    </p>
                    <TagList data={data.tags}/>
                </div>
            </div>
        );
    }
}

const styles = {
    description: {
        margin: 0,
        paddingTop: 2
    },
    rightInfo: {
        position: 'absolute',
        top: 13,
        right: 10,
        color: '#444',
        fontSize: 12,
        userSelect: 'none'
    },
    rightInfoIcon: {
        fontSize: 12,
        marginRight: 5,
        color: '#a7a7a7'
    },
    avatar: {
        top: 10,
        borderRadius: 3
    },
    secondaryText: {
        height: 'auto'
    },
    subscribeButton: Consts.IS_MOBILE ? {
        marginTop: '1em'
    } : {
        position: 'absolute',
        right: 0,
        top: 8
    },
    subscribeMenu: Consts.IS_MOBILE ? {
        left: 0,
        top: 45
    } : undefined,
    subscribeArrowMenu: Consts.IS_MOBILE ? {
        left: 25,
        right: null
    } : undefined,
    title: Consts.IS_MOBILE ? {
        paddingRight: 0
    } : undefined
};

export default ProjectDescription;