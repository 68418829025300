import { Router, Routes, Route } from "react-router-dom";
import { useLayoutEffect, useReducer } from "react";
import { createBrowserHistory } from "history";
import { setAppHistory } from "./Utils";

const reducer = (_: any, action: any) => action;
const history = createBrowserHistory();
setAppHistory(history);

export const HistoryRouter = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, {
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(dispatch), []);
  return (
    <Router navigationType={state.action} location={state.location} navigator={history}>
      {children}
    </Router>
  );
};
