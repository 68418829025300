/**
 * Created by kimchangduk on 2017-05-26.
 */

import React from 'react';
import axios from 'axios';
import {Strings} from "../constants";

class OAuthAuthorizer extends React.Component {
    state = {
        list: []
    };

    componentDidMount() {
        axios.get(LIST_API_URL).then(response => {
            this.setState({list: response.data});
        });
    }

    onAuthorizeRequest = (uri) => {
        window.location.href = uri;
    };

    unquote(string) {
        let count = string.length - 1;
        const pair = string.charAt(0) + string.charAt(count);
        return (pair === '""' || pair === "''") ? string.slice(1, count) : string;
    }

    renderAuthIcon = (item, key) => {
        return <img
            key={key}
            src={item.icon}
            style={styles.oauthLogo}
            onClick={() => {
                this.onAuthorizeRequest(item.authorizeUri);
            }}
            title={item.name}
        />;
    };

    render() {
        if (!this.state.list || this.state.list.length <= 0) {
            return <div/>;
        }

        return (<div className="text-align-left" style={styles.container}>
            <span className="vertical-align-middle margin-right-10">{Strings.OAUTH_SOCIAL}</span>
            {this.state.list.map((item, key) => {
                return this.renderAuthIcon(item, key);
            })}
        </div>);
    }
}

const styles = {
    oauthLogo: {
        cursor: 'pointer',
        height: 30,
        margin: 3,
        borderRadius: 5,
        verticalAlign: 'middle'
    },
    container: {
        margin: '10px 0'
    }
};
const LIST_API_URL = '/v1/social/oauth2';
export default OAuthAuthorizer;
