/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import { Strings } from "../constants";
import "./TooltipInput.scss";
import ErrorIcon from "material-ui/svg-icons/alert/error";

class TooltipInput extends React.Component {
  static propTypes = {
    style: PropTypes.object,
    name: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    tooltipMesssage: PropTypes.string,
    error: PropTypes.bool,
    errorIconSize: PropTypes.number,
    errorIconStyle: PropTypes.object,
  };

  static defaultProps = {
    errorIconSize: 20,
  };

  focus = () => {
    if (this.refs[INPUT_REF]) {
      this.refs[INPUT_REF].focus();
    }
  };

  blur = () => {
    if (this.refs[INPUT_REF]) {
      this.refs[INPUT_REF].blur();
    }
  };

  render() {
    return (
      <div style={[styles.container, this.props.style]} className="tooltip-input-wrapper">
        <input
          ref={INPUT_REF}
          name={this.props.name}
          className={this.props.className}
          value={this.props.value}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          onKeyDown={this.props.onKeyDown}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
        />
        {this.props.error ? (
          <div className="error-icon" style={this.props.errorIconStyle} onClick={this.focus}>
            <div className="background" />
            <div className="position-relative">
              <ErrorIcon color="#D32F2F" style={{ width: this.props.errorIconSize, height: this.props.errorIconSize }} />
            </div>
          </div>
        ) : undefined}
        {this.props.tooltipMesssage ? <div className="tooltip" dangerouslySetInnerHTML={{ __html: this.props.tooltipMesssage }} /> : undefined}
      </div>
    );
  }
}

const styles = {
  container: {
    position: "relative",
    display: "inline-block",
  },
  errorIcon: {
    width: 20,
    height: 20,
  },
};

const INPUT_REF = "INPUT_REF";

export default Radium(TooltipInput);
