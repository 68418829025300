/**
 * Created by kimchangduk on 2017-06-03.
 */

import React from "react";
import PropTypes from "prop-types";
import { getAppHistory } from "../../Utils";
import { connect } from "react-redux";
import { Consts, Strings } from "../../constants";
import { showSnackbar } from "../../actions/globalSnackbar";
import queryString from "query-string";
import axios from "axios";
import LoaderPage from "../LoaderPage";

class EmailValidationPage extends React.Component {
  static DispatchToProps = (dispatch) => {
    return {
      actions: {
        alert: (message) => {
          dispatch(showSnackbar(message));
        },
      },
    };
  };

  static propTypes = {
    actions: PropTypes.object,
    match: PropTypes.object,
  };

  componentDidMount() {
    const urlParam = queryString.parse(window.location.search);
    this.verifyToken(urlParam.token);
  }

  verifyToken = (token) => {
    axios
      .get(API_URL.replace("{username}", this.props.match.params.username), { params: { token } })
      .then(() => {
        this.props.actions.alert(Strings.SUCCESS_TO_VERIFY);
        this.navigateToMainPage();
      })
      .catch((error) => {
        this.props.actions.alert(Strings.INVALID_APPROACH);
        this.navigateToMainPage();
      });
  };

  navigateToMainPage = () => {
    getAppHistory().push("/");
  };

  render() {
    return (
      <div className="default-page text-align-center">
        <LoaderPage />
      </div>
    );
  }
}

const API_URL = "/v1/account/{username}/validate";

export default connect(null, EmailValidationPage.DispatchToProps)(EmailValidationPage);
