/**
 * Created by kimchangduk on 2017-05-20.
 */

import React from "react";
import { Strings, Urls, Consts } from "../../constants/index";
import PropTypes from "prop-types";
import Radium from "radium";

let PlayStoreImage;
if (Strings.getLanguageCode() === "ko") PlayStoreImage = "https://s3.ap-northeast-2.amazonaws.com/notihub/images/google-play-badge-kr.png";
else PlayStoreImage = "https://s3.ap-northeast-2.amazonaws.com/notihub/images/google-play-badge.png";

class PlayStoreLink extends React.Component {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  render() {
    const style = {};
    if (this.props.height !== undefined) {
      style.height = this.props.height;
    }
    if (this.props.padding !== undefined) {
      style.padding = this.props.padding;
    }

    return (
      <a className="display-inline-block cursor-pointer" target="_blank" href={Urls.PLAYSTORE}>
        <img style={[styles.playStoreImage, style]} src={PlayStoreImage} />
      </a>
    );
  }
}

const styles = {
  playStoreImage: {
    height: Consts.IS_MOBILE ? 50 : 70,
  },
};

export default Radium(PlayStoreLink);
