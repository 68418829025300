/**
 * Created by kimchangduk on 5/17/2018.
 */

import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import {RaisedButton} from "material-ui";
import LoaderPage from "./LoaderPage";
import {writeContact} from "../Api";
import {showSnackbar} from "../actions/globalSnackbar";
import {Strings} from "../constants";

class ContactPage extends React.Component {
    static StateToProps = (state) => {
        return {
            isLogin: state.user.state.isLogin,
            userEmail: state.user.state.isLogin && state.user.dataSource ? state.user.dataSource.email : ''
        };
    };

    static DispatchToProps = (dispatch) => {
        return {
            showSnackbar: (message) => {
                dispatch(showSnackbar(message));
            }
        };
    };

    static propTypes = {
        showSnackbar: PropTypes.func,
        isLogin: PropTypes.bool,
        userEmail: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            email: props.userEmail,
            content: '',
            isWaiting: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isLogin && nextProps.userEmail && !prevState.email) {
            return {
                email: nextProps.userEmail
            };
        }
    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onSubmitClick = () => {
        this.setState({isWaiting: true});
        writeContact(this.state.email, this.state.title, this.state.content).then(response => {
            this.setState({isWaiting: false});
            this.props.showSnackbar(Strings.SUCCESS_TO_SUBMIT_CONTACT);
        }).catch(error => {
            this.setState({isWaiting: false});
            this.props.showSnackbar(Strings.FAILED_TO_SUBMIT_CONTACT);
        });
    };

    render() {
        return (<div style={styles.container} className="center-align-page">
            <TextField value={this.state.title} name="title" floatingLabelText={Strings.TITLE} fullWidth onChange={this.onInputChange}/>
            <TextField value={this.state.email} name="email" floatingLabelText={Strings.EMAIL} fullWidth onChange={this.onInputChange}/>
            <TextField value={this.state.content} name="content" hintText={Strings.CONTACT_INPUT_HINT} fullWidth multiLine rows={3} onChange={this.onInputChange} hintStyle={styles.hint}/>
            <RaisedButton label="submit" primary={true} fullWidth onClick={this.onSubmitClick} disabled={!this.state.content && !this.state.title}/>
            {
                this.state.isWaiting ? <LoaderPage/> : undefined
            }
        </div>);
    }
}

const styles = {
    container: {
        textAlign: 'center',
        padding: '1em'
    },
    hint: {
        top: 12,
        bottom: null
    }
};

export default connect(ContactPage.StateToProps, ContactPage.DispatchToProps)(ContactPage);