import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import './SearchInput.scss';
import {Consts, Urls, Strings} from "../constants";
import {getAppHistory} from "../Utils";
import TooltipInput from "./TooltipInput";
import {search} from "../actions/search";

class SearchInput extends React.Component {
    static StateToProps = (state) => {
        return {
            searchKeyword: state.keyword.get.dataSource ? state.keyword.get.dataSource.keyword : null,
            keywordRankRequest: state.keyword.rank.state.request,
            keywordRank: state.keyword.rank.dataSource,
            projectDataSource: state.project.project.dataSource
        };
    };

    static propTypes = {
        actions: PropTypes.object,
        searchKeyword: PropTypes.object,
        keywordRankRequest: PropTypes.string,
        keywordRank: PropTypes.arrayOf(PropTypes.object),
        mobile: PropTypes.bool,
        clearSearchState: PropTypes.bool,
        location: PropTypes.object,
        projectDataSource: PropTypes.object
    };

    static defaultProps = {
        visibleSearchButton: false
    };

    constructor(props) {
        super(props);
    }

    state = {
        inputStr: '',
        searchModeCursor: 0
    };

    componentWillUpdate(nextProps, nextState) {
        if (this.props.searchKeyword !== nextProps.searchKeyword) {
            this.loadSearchState(nextProps.searchKeyword);
        }
    }

    componentWillUnmount() {
        if (this.tagInputBlurTimeout) {
            window.clearTimeout(this.tagInputBlurTimeout);
        }
    }

    tagInputBlurTimeout = null;

    loadSearchState = (searchKeyword) => {
        if (!searchKeyword) {
            return;
        }
        let wordStr = searchKeyword.word ? searchKeyword.word : '';
        let inputStr = '';
        let selectedItem = null;
        let selectedItemType = null;
        if (searchKeyword.tag) {
            inputStr = searchKeyword.tag.name;
            selectedItem = searchKeyword.tag;
            selectedItemType = Consts.TagOrProject.TAG;
        } else if (searchKeyword.project) {
            inputStr = searchKeyword.project.title;
            selectedItem = searchKeyword.project;
            selectedItemType = Consts.TagOrProject.PROJECT;
        }

        this.setState({inputStr, wordStr, selectedItem, selectedItemType});
    };

    onSearchInputChange = (e) => {
        const updateValue = {
            [e.target.name]: e.target.value
        };
        if (e.target.name === 'inputStr') {
            updateValue.instantSearchCursor = null;
            updateValue.selectedItem = null;
            updateValue.selectedItemType = null;
        }
        this.setState(updateValue);
    };

    search = (str, searchMode = undefined) => {
        if (!str) {
            return;
        }

        if (searchMode === undefined) {
            searchMode = this.state.searchModeCursor;
        }

        switch (searchMode) {
            case SearchModes.SEARCH_FEED_IN_MY_SUBSCRIBES:
                getAppHistory().push(Urls.getSubscriptionSearch(str));
                break;

            case SearchModes.SEARCH_FEED_IN_PROJECT:
                this.isProjectFeedPage() ?
                    getAppHistory().push(Urls.getProjectFeedSearchPage(this.props.projectDataSource.urlKey, str)) :
                    getAppHistory().push(Urls.getSearchResult(str));
                break;

            case SearchModes.SEARCH_FEED_AND_PROJECT:
            default:
                getAppHistory().push(Urls.getSearchResult(str));
                break;

        }
        this.setState({inputStr: ''});
    };

    onInstantSearchInputKeyDown = (e) => {
        if (!this.state.inputFocus) {
            return;
        }
        switch (e.keyCode) {
            // Enter
            case 13:
                this.search(this.state.inputStr);
                break;

            // ↑
            case 38: {
                const currentIndex = this.getSearchModeOptions().findIndex(item => item === this.state.searchModeCursor);
                this.setState({searchModeCursor: this.getSearchModeOptions()[Math.max(0, currentIndex - 1)]});
                break;
            }

            // ↓
            case 40: {
                const currentIndex = this.getSearchModeOptions().findIndex(item => item === this.state.searchModeCursor);
                this.setState({searchModeCursor: this.getSearchModeOptions()[Math.min(this.getSearchModeOptions().length - 1, currentIndex + 1)]});
                break;
            }
        }
    };

    onTagInputFocus = () => {
        this.setState({inputFocus: true, searchModeCursor: this.getSearchModeOptions()[0]});
    };

    onTagInputBlur = () => {
        this.tagInputBlurTimeout = window.setTimeout(() => {
            const updateValue = {inputFocus: false};
            this.setState(updateValue);
        }, 300);
    };

    onSearchModeSelect = (mode) => {
        this.setState({searchModeCursor: mode});
        this.search(this.state.inputStr, mode);
    };

    isProjectFeedPage = () => {
        return this.props.projectDataSource && this.props.location.pathname.startsWith(Urls.getProjectFeedPage(this.props.projectDataSource.urlKey, false));
    };

    getSearchModeOptions() {
        if (this.isProjectFeedPage()) {
            return [
                SearchModes.SEARCH_FEED_IN_PROJECT,
                SearchModes.SEARCH_FEED_IN_MY_SUBSCRIBES,
                SearchModes.SEARCH_FEED_AND_PROJECT
            ];
        } else {
            return [
                SearchModes.SEARCH_FEED_AND_PROJECT,
                SearchModes.SEARCH_FEED_IN_MY_SUBSCRIBES
            ];
        }
    }

    getSearchModeOptionTitle = (searchMode) => {
        switch (searchMode) {
            case SearchModes.SEARCH_FEED_AND_PROJECT:
                return Strings.SEARCH_PROJECT_FEEDS;

            case SearchModes.SEARCH_FEED_IN_MY_SUBSCRIBES:
                return Strings.SEARCH_SUBSCRIBE_FEEDS;

            case SearchModes.SEARCH_FEED_IN_PROJECT:
                return <div><span className="project-name">{this.props.projectDataSource.title}</span><span> {Strings.SEARCH_IN_PROJECT}</span></div>;
        }
    };

    render() {
        const placeholder = this.isProjectFeedPage() ?
            Strings.SEARCH_FEED : Strings.SEARCH_INPUT_PLACEHOLDER;

        return <div className="search-input-container">
            <div style={styles.inputDiv}>
                <TooltipInput
                    name="inputStr"
                    className={`search-input`}
                    value={this.state.inputStr}
                    onFocus={this.onTagInputFocus}
                    onBlur={this.onTagInputBlur}
                    onKeyDown={this.onInstantSearchInputKeyDown}
                    onChange={this.onSearchInputChange}
                    errorIconSize={this.props.mobile ? 30 : 20}
                    placeholder={placeholder}/>
                {
                    this.state.inputStr &&
                    this.state.inputFocus ?
                        <div className="autocomplete">
                            {
                                this.getSearchModeOptions().map((item, key) => {
                                    return (<div
                                        key={key}
                                        className={this.state.searchModeCursor === item ? 'active' : ''}
                                        onClick={() => {
                                            this.onSearchModeSelect(item);
                                        }}>
                                        {
                                            this.getSearchModeOptionTitle(item)
                                        }

                                    </div>);

                                })
                            }
                        </div> : undefined
                }
            </div>
        </div>;
    }
}

const SearchModes = {
    SEARCH_FEED_IN_PROJECT: 0,
    SEARCH_FEED_IN_MY_SUBSCRIBES: 1,
    SEARCH_FEED_AND_PROJECT: 2,
};

const styles = {
    searchResult: {
        position: 'absolute',
        left: 0,
        top: 10,
        background: 'red',
        width: 500,
        textAlign: 'left'
    },
    searchButton: {
        marginTop: 10
    },
    searchButtonLabel: {
        fontWeight: 'bold'
    },
    inputDiv: {
        position: 'relative',
        zIndex: 1
    }
};

export default connect(SearchInput.StateToProps, SearchInput.DispatchToProps)(SearchInput);