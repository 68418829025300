/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import {Consts, Strings} from '../constants';
import PlayStoreLink from "./StoreLinks/PlayStoreLink";
import AppStoreLink from "./StoreLinks/AppStoreLink";

class AppStorePopup extends React.Component {
    static propTypes = {
        onRequestClose: PropTypes.func
    };

    render() {
        const phrase = Strings.localize({
            default: 'It\'s easier to use in the app',
            ko: '앱에서 사용하시면<br/>더 편합니다'
        });
        return <div style={styles.container}>
            <div style={styles.verticalMiddle} onClick={this.props.onRequestClose}>
                <p style={styles.downloadPhrase} dangerouslySetInnerHTML={{__html: phrase}}/>
                {Consts.IS_IOS ? <AppStoreLink height={80}/> : <PlayStoreLink height={100}/>}
                <p style={styles.mobileWebLink}>
                    {
                        Strings.localize({
                            default: 'View on mobile web',
                            ko: '모바일웹에서 볼래요'
                        })
                    }
                </p>
            </div>
        </div>;
    }
}

const styles = {
    container: {
        position: 'fixed',
        top: 0, left: 0, right: 0, bottom: 0,
        background: 'rgba(0,0,0,0.8)',
        zIndex: 2,
        color: '#FFFFFF'
    },
    verticalMiddle: {
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        textAlign: 'center',
        transform: 'translateY(-50%)'
    },
    downloadPhrase: {
        fontWeight: 'bold',
        margin: 10,
        fontSize: 30
    },
    mobileWebLink: {
        margin: 10,
        textDecoration: 'underline'
    }
};

export default AppStorePopup;