import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Consts } from "../constants";
import FeedTimeline from "./FeedTimeline";
import { getProjectWithUrlKey } from "../actions/project";
import ProjectDescription from "../components/ProjectDescription";
import NotFoundPage from "./NotFoundPage";
import { openFeedLayer } from "../actions/feedContentLayer";
import withRouter from "../withRouter";

class ProjectFeedTimelinePage extends React.Component {
  static StateToProps = (state) => {
    return {
      projectDataSource: state.project.project.dataSource,
      projectState: state.project.project.state,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getProject: (urlKey = undefined) => {
          if (urlKey === undefined) {
            debugger;
            urlKey = decodeURIComponent(ownProps.params?.urlKey);
          }
          dispatch(getProjectWithUrlKey(urlKey));
        },
        openFeedLayer: (feeds, index = 0) => {
          dispatch(openFeedLayer(feeds, index));
        },
      },
    };
  };

  static propTypes = {
    match: PropTypes.object,
    actions: PropTypes.object,
    projectDataSource: PropTypes.object,
    projectState: PropTypes.object,
    location: PropTypes.object,
  };

  componentDidMount() {
    if (!this.props.projectDataSource || this.props.projectDataSource.urlKey !== decodeURIComponent(this.props.params.urlKey)) {
      this.props.actions.getProject(decodeURIComponent(this.props.params.urlKey));
    }
    if (this.props.location.state && this.props.location.state.openFeed) {
      window.setTimeout(() => {
        this.props.actions.openFeedLayer([this.props.location.state.openFeed]);
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params.urlKey !== nextProps.params.urlKey) {
      this.props.actions.getProject(decodeURIComponent(nextProps.params.urlKey));
    }
  }

  render() {
    const urlKey = decodeURIComponent(this.props.params.urlKey);
    const dataSource = this.props.projectDataSource;
    if (
      decodeURIComponent(this.props.params.urlKey) === this.props.projectState.urlKey &&
      this.props.projectState.request === Consts.REQUEST_FAILURE &&
      this.props.projectState.error &&
      this.props.projectState.error.response &&
      this.props.projectState.error.response.status === 404
    ) {
      return <NotFoundPage />;
    }
    return (
      <FeedTimeline urlKey={this.props.params.urlKey} searchWord={this.props.params.searchWord}>
        {dataSource && dataSource.urlKey === urlKey ? <ProjectDescription data={dataSource} /> : undefined}
      </FeedTimeline>
    );
  }
}

const styles = {
  container: {},
};

export default connect(ProjectFeedTimelinePage.StateToProps, ProjectFeedTimelinePage.DispatchToProps)(withRouter(ProjectFeedTimelinePage));
