import "core-js";

import React from "react";
import GA from "./GA";
import { render } from "react-dom";
import routes from "./routes";
// import injectTapEventPlugin from "react-tap-event-plugin";
import "date-utils";

import "./styles.scss";

import { Provider } from "react-redux";
import axios from "axios";
import querystring from "query-string";
import { store } from "./store";
import { Consts, Urls } from "./constants";
import FormData from "form-data";
import { getAppHistory, requestFcmToken } from "./Utils";
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getAuth } from "firebase/auth";
import { processLoginWithFirebaseAuth } from "./actions/user";
import { getProjectFeeds } from "./actions/feed";
require("./favicon.ico");

// injectTapEventPlugin();

//<editor-fold desc="Loading div 제거">
const loadingDiv = document.getElementById("loading");
if (loadingDiv) {
  loadingDiv.remove();
}
//</editor-fold>

//////////////////////////////////////////////////////////////////////////////////
// AJAX Setting                                                                 //
//////////////////////////////////////////////////////////////////////////////////
axios.defaults.baseURL = `${window.location.protocol}//${window.location.host}`;
axios.defaults.withCredentials = true;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
axios.defaults.headers.put["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
axios.defaults.headers.patch["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";

axios.defaults.transformRequest = [
  (data) => {
    if (data && FormData && data instanceof FormData) return data;
    return querystring.stringify(data);
  },
];

//////////////////////////////////////////////////////////////////////////////////
// GA setting                                                                   //
//////////////////////////////////////////////////////////////////////////////////
GA.initialize();

render(<Provider store={store}>{routes}</Provider>, document.getElementById("app"));
