/**
 * Created by kimchangduk on 2018-01-16.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Consts, Strings} from '../constants';
import './SubscribePopup.scss';
import {isAndroidApp, isIosApp} from "../Utils";
import StoreLinks from './StoreLinks';

class SubscribePopup extends React.Component {
    static propTypes = {
        actions: PropTypes.object,
        dataSource: PropTypes.object,
        link: PropTypes.string,
        linkTitle: PropTypes.string,
        onRequestClose: PropTypes.func,
        isLogin: PropTypes.bool,
    };

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        window.scrollTo(0, 0);
    };

    cancelOnClick(e) {
        e.stopPropagation();
    }

    renderStoreLink = () => {
        if (Consts.IS_MOBILE) {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (Consts.IS_IOS_WEBVIEW || isIosApp() || isAndroidApp()) {
                return undefined;
            } else if (/android/i.test(userAgent)) {
                return (<div><StoreLinks.PlayStoreLink/></div>);
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return (<div><StoreLinks.AppStoreLink/></div>);
            } else {
                return (<div>
                    <StoreLinks.PlayStoreLink/>
                    <StoreLinks.AppStoreLink/>
                </div>);
            }
        } else {
            return (<div>
                <StoreLinks.PlayStoreLink/>
                <StoreLinks.AppStoreLink/>
            </div>);
        }
    };

    render() {
        const uri = this.props.link ? decodeURI(this.props.link).replace(new RegExp("&amp;", "ig"), "&") : null;
        return (<div className="subscribe-popup close-layer" onClick={this.props.onRequestClose}>
            <div className="content">
                <p>노티허브에서 원하는 소식을 받아보세요.</p>
                {this.renderStoreLink()}
                {
                    uri ?
                        <div className="link">
                            <a href={uri} target="null" onClick={this.cancelOnClick}>
                                '{this.props.linkTitle ? this.props.linkTitle : uri}'로 이동
                            </a>
                        </div> : undefined
                }
            </div>
        </div>);
    }
}

export default SubscribePopup;