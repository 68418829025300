/**
 * Created by kimchangduk on 2017-05-09.
 */

import { ActionTypes, Consts, Strings } from "../constants";
import update from "immutability-helper";

const initialState = {
  pages: {
    state: {
      page: -1,
      totalPages: -1,
      done: false,
      request: Consts.REQUEST_NONE,
      username: "",
      urlKey: "",
      errorMessage: "",
    },
    dataSource: [],
  },
  detailPages: {
    state: {
      request: Consts.REQUEST_NONE,
      username: "",
      urlKey: "",
      id: -1,
      topPage: 0,
      bottomPage: 0,
      prevPageExists: true,
      nextPageExists: true,
      errorMessage: "",
    },
    dataSource: [],
  },
  deleteAll: {
    state: {
      request: Consts.REQUEST_NONE,
      username: "",
      urlKey: "",
      errorMessage: "",
    },
  },
  delete: {
    state: {
      request: Consts.REQUEST_NONE,
      username: "",
      urlKey: "",
      id: -1,
      errorMessage: "",
    },
  },
};

export default function projectNewsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CLEAR_PROJECT_NEWS_PAGE:
      return update(state, {
        pages: {
          state: {
            page: { $set: -1 },
            totalPages: { $set: -1 },
            done: { $set: false },
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
          },
          dataSource: { $set: [] },
        },
      });

    //<editor-fold desc="news 가져오기">
    case ActionTypes.GET_PROJECT_NEWS_PAGE_REQUEST:
      return update(state, {
        pages: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            username: { $set: action.username },
            urlKey: { $set: action.urlKey },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.GET_PROJECT_NEWS_PAGE_SUCCESS: {
      if (action.username !== state.pages.state.username || action.urlKey !== state.pages.state.urlKey) {
        return state;
      }

      const data = action.data;
      const updateValue = {
        pages: {
          state: {
            page: { $set: action.page },
            request: { $set: Consts.REQUEST_SUCCESS },
            done: { $set: data.last },
            totalPages: { $set: data.totalPages },
          },
        },
      };

      if (data.first) {
        updateValue.pages.dataSource = { $set: data.content };
      } else {
        updateValue.pages.dataSource = { $push: data.content };
      }

      return update(state, updateValue);
    }

    case ActionTypes.GET_PROJECT_NEWS_PAGE_FAILURE: {
      if (action.username !== state.pages.state.username || action.urlKey !== state.pages.state.urlKey) {
        return state;
      }

      const updateValue = {
        pages: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      };

      return update(state, updateValue);
    }
    //</editor-fold>

    //<editor-fold desc="news id로 가져오기">
    case ActionTypes.GET_PROJECT_NEWS_DETAIL_PAGE_REQUEST:
      return update(state, {
        detailPages: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            username: { $set: action.username },
            urlKey: { $set: action.urlKey },
            id: { $set: action.id },
            topPage: { $set: 0 },
            bottomPage: { $set: 0 },
            prevPageExists: { $set: true },
            nextPageExists: { $set: true },
          },
        },
      });

    case ActionTypes.GET_PROJECT_NEWS_DETAIL_PAGE_SUCCESS: {
      const data = action.data;
      return update(state, {
        detailPages: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            nextPageExists: { $set: !data.last },
          },
          dataSource: { $set: data.content },
        },
      });
    }

    case ActionTypes.GET_PROJECT_NEWS_DETAIL_PAGE_FAILURE:
      return update(state, {
        detailPages: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: Strings.FAIL_TO_LOAD_DATA },
          },
          dataSource: { $set: [] },
        },
      });

    case ActionTypes.GET_PROJECT_NEWS_DETAIL_PAGE_NEXT_REQUEST:
      return update(state, {
        detailPages: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            username: { $set: action.username },
            urlKey: { $set: action.urlKey },
            id: { $set: action.id },
            bottomPage: { $set: action.page },
          },
        },
      });

    case ActionTypes.GET_PROJECT_NEWS_DETAIL_PAGE_NEXT_SUCCESS: {
      const data = action.data;
      return update(state, {
        detailPages: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            nextPageExists: { $set: !data.last },
          },
          dataSource: { $push: data.content },
        },
      });
    }

    case ActionTypes.GET_PROJECT_NEWS_DETAIL_PAGE_NEXT_FAILURE:
      return update(state, {
        detailPages: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            nextPageExists: { $set: false },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });

    case ActionTypes.GET_PROJECT_NEWS_DETAIL_PAGE_PREV_REQUEST:
      return update(state, {
        detailPages: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            username: { $set: action.username },
            urlKey: { $set: action.urlKey },
            id: { $set: action.id },
            topPage: { $set: action.page },
          },
        },
      });

    case ActionTypes.GET_PROJECT_NEWS_DETAIL_PAGE_PREV_SUCCESS: {
      const data = action.data;
      return update(state, {
        detailPages: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            prevPageExists: { $set: !data.last },
          },
          dataSource: { $splice: [update(data.content.reverse(), { $splice: [[0, 0, 0, 0]] })] },
        },
      });
    }

    case ActionTypes.GET_PROJECT_NEWS_DETAIL_PAGE_PREV_FAILURE:
      return update(state, {
        detailPages: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            prevPageExists: { $set: false },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="delete project news">
    case ActionTypes.DELETE_PROJECT_NEWS_REQUEST:
      return update(state, {
        deleteAll: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            username: { $set: action.username },
            urlKey: { $set: action.urlKey },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.DELETE_PROJECT_NEWS_SUCCESS: {
      const updateValue = {
        deleteAll: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
        },
      };

      if (state.pages.state.username === action.username && state.pages.state.urlKey === action.urlKey) {
        updateValue.pages = {
          dataSource: { $set: [] },
        };
      }

      return update(state, updateValue);
    }

    case ActionTypes.DELETE_PROJECT_NEWS_FAILURE:
      return update(state, {
        deleteAll: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="delete project news by id">
    case ActionTypes.DELETE_PROJECT_NEWS_BY_ID_REQUEST:
      return update(state, {
        delete: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            username: { $set: action.username },
            urlKey: { $set: action.urlKey },
            id: { $set: action.id },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.DELETE_PROJECT_NEWS_BY_ID_SUCCESS: {
      const updateValue = {
        delete: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
        },
      };

      if (state.pages.state.username === action.username && state.pages.state.urlKey === action.urlKey && state.pages.dataSource) {
        const updateIndex = state.pages.dataSource.findIndex((item, key) => item.id === action.id);
        if (updateIndex >= 0) {
          updateValue.pages = {
            dataSource: { $splice: [[updateIndex, 1]] },
          };
        }
      }

      if (state.detailPages.state.username === action.username && state.detailPages.state.urlKey === action.urlKey) {
        const updateIndex = state.detailPages.dataSource.findIndex((item, key) => item.id === action.id);
        if (updateIndex >= 0) {
          updateValue.detailPages = {
            dataSource: { $splice: [[updateIndex, 1]] },
          };
        }
      }

      return update(state, updateValue);
    }

    case ActionTypes.DELETE_PROJECT_NEWS_BY_ID_FAILURE:
      return update(state, {
        delete: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    default:
      return state;
  }
}
