/**
 * Created by kimchangduk on 6/8/2018.
 */

import React from "react";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Strings } from "../constants";
import { Checkbox, IconButton, RaisedButton, Subheader } from "material-ui";
import CloseIcon from "material-ui/svg-icons/navigation/close";
import TextField from "../components/TextField";
import { editSubscriptionOrder, getSubscribeList } from "../actions/subscribe";
import { List, ListItem } from "material-ui/List";
import { getAppHistory, getMessageFromResponse } from "../Utils";
import BaseDialog from "./BaseDialog";
import ImageView from "../components/ImageView";
import { addFolder } from "../Api";
import { showSnackbar } from "../actions/globalSnackbar";
import { hasChangedRequestWaitingToFailure, hasChangedRequestWaitingToSuccess } from "../constants/Consts";

class AddSubscriptionListDialog extends React.Component {
  static StateToProps = (state, ownProps) => {
    return {
      mySubscribesDataSource: state.subscribe.mySubscribes.dataSource,
      editSubscriptionsRequest: state.subscribe.editSubscriptions.state.request,
      editSubscriptionsErrorMessage: state.subscribe.editSubscriptions.state.errorMessage,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getSubscribeList: () => {
          dispatch(getSubscribeList());
        },
        showSnackbar: (message) => {
          dispatch(showSnackbar(message));
        },
        editSubscriptionOrder: (subscribeIds, folderIds, orders) => {
          dispatch(editSubscriptionOrder(subscribeIds, folderIds, orders));
        },
      },
    };
  };

  static propTypes = {
    mySubscribesDataSource: PropTypes.array,
    editSubscriptionsRequest: PropTypes.string,
    editSubscriptionsErrorMessage: PropTypes.string,
    actions: PropTypes.object,
    open: PropTypes.bool,
    onRequestClose: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      folderName: "",
      folderNameError: "",
      checked: true,
      waiting: false,
      checkedIds: [],
    };
  }

  componentDidMount() {
    this.props.actions.getSubscribeList();

    const history = getAppHistory();
    const location = history.location;
    const state = location.state ? update(location.state, {}) : {};
    if (!state.dialogStack) {
      state.dialogStack = 1;
    } else {
      state.dialogStack++;
    }
    history.push(location.pathname, state);
    this.dialogStack = state.dialogStack;
    this.historyUnlisten = history.listen((location, action) => {
      this.props.onRequestClose();
    });
  }

  shouldComponentUpdate(nextProps) {
    if (hasChangedRequestWaitingToSuccess(this.props.editSubscriptionsRequest, nextProps.editSubscriptionsRequest)) {
      this.props.onRequestClose();
    } else if (hasChangedRequestWaitingToFailure(this.props.editSubscriptionsRequest, nextProps.editSubscriptionsRequest)) {
      this.setState({ waiting: false });
      this.props.actions.showSnackbar(nextProps.editSubscriptionsErrorMessage);
    }
    return true;
  }

  componentWillUnmount() {
    if (this.historyUnlisten) {
      this.historyUnlisten();
      this.historyUnlisten = null;
      const location = getAppHistory().location;
      if (location.state && location.state.dialogStack === this.dialogStack) getAppHistory().back();
    }
  }

  onAddClick = () => {
    if (!this.state.folderName) {
      this.setState({ folderNameError: Strings.TYPE_FOLDER_NAME });
      if (this.refs.folderName && this.refs.folderName.focus) {
        this.refs.folderName.focus();
      }
      return;
    }

    if (!this.state.checkedIds || this.state.checkedIds.length <= 0) {
      this.props.actions.showSnackbar(Strings.MUST_ADD_AT_LEAST_ONE_SUBSCRIPTION);
      return;
    }

    this.setState({ waiting: true });
    addFolder(this.state.folderName)
      .then((response) => {
        const { id, name } = response.data;
        let subscriptions = this.props.mySubscribesDataSource;
        const updateValue = [];
        for (let i in subscriptions) {
          const subscription = subscriptions[i];
          const index = this.state.checkedIds.findIndex((a) => a === subscription.id);
          if (index >= 0) {
            updateValue.push([index, 1]);
          }
        }
        subscriptions = update(subscriptions, { $splice: updateValue });
        const subscriptionIds = subscriptions.map((a) => a.id);
        const folderIds = subscriptions.map((a) => (a.subscriptionFolder ? a.subscriptionFolder.id : null));
        for (let checkedId of this.state.checkedIds) {
          subscriptionIds.push(checkedId);
          folderIds.push(id);
        }
        const orders = subscriptionIds.map((item, key) => key);
        this.props.actions.editSubscriptionOrder(subscriptionIds, folderIds, orders);
      })
      .catch((error) => {
        this.setState({ waiting: false });
        this.props.actions.showSnackbar(getMessageFromResponse(error.response, Strings.FAILED_TO_ADD_FOLDER));
      });
  };

  onCheckStateChanged = (subscriptionId) => {
    let updateValue;
    const prevIndex = this.state.checkedIds.findIndex((id) => id === subscriptionId);
    if (prevIndex < 0) {
      updateValue = { $push: [subscriptionId] };
    } else {
      updateValue = { $splice: [[prevIndex, 1]] };
    }
    this.setState({ checkedIds: update(this.state.checkedIds, updateValue) });
  };

  onTextFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  onRequestClose = () => {
    this.props.onRequestClose();
  };

  render() {
    const titleNode = (
      <div>
        {Strings.ADD_FOLDER}
        <IconButton iconStyle={styles.closeButtonIcon} style={styles.closeButton} onClick={this.props.onRequestClose}>
          <CloseIcon />
        </IconButton>
      </div>
    );
    const { mySubscribesDataSource } = this.props;
    return (
      <BaseDialog
        title={titleNode}
        titleStyle={styles.titleStyle}
        contentStyle={styles.dialogContent}
        contentClassName="add-subscription-dialog"
        bodyStyle={styles.body}
        open={this.props.open}
        modal={true}
        onRequestClose={this.onRequestClose}
      >
        <TextField
          ref="folderName"
          value={this.state.folderName}
          errorText={this.state.folderNameError}
          floatingLabelText={Strings.NAME}
          name="folderName"
          onChange={this.onTextFieldChange}
          fullWidth
          autoFocus
        />
        <Subheader style={styles.subheader}>{Strings.SELECT_SUBSCRIPTIONS_TO_ADD}</Subheader>
        <List style={styles.list}>
          {mySubscribesDataSource
            ? mySubscribesDataSource.map((item, key) => {
                const { project } = item;
                const checked = this.state.checkedIds.findIndex((id) => id === item.id) >= 0;
                return (
                  <ListItem
                    key={key}
                    style={styles.listItem}
                    leftCheckbox={
                      <Checkbox
                        style={styles.listItemCheckBox}
                        onCheck={() => {
                          this.onCheckStateChanged(item.id);
                        }}
                        checked={checked}
                      />
                    }
                    leftAvatar={<ImageView style={styles.avatar} width={30} height={30} url={project.image} />}
                    primaryText={<div style={styles.singleLine}>{project.title}</div>}
                    secondaryText={project.description ? <div style={styles.singleLine}>{project.description}</div> : undefined}
                  />
                );
              })
            : undefined}
        </List>
        <RaisedButton disabled={this.state.waiting} style={styles.addButton} className="margin-top-10" label={Strings.ADD} onClick={this.onAddClick} primary />
      </BaseDialog>
    );
  }
}

const styles = {
  dialogContent: {
    width: "100%",
    maxWidth: 500,
    maxHeight: 668,
    height: "100%",
  },
  body: {
    height: "calc(100% - 50px)",
  },
  titleStyle: {
    fontWeight: "bold",
    padding: "18px 18px 0px",
    height: 50,
  },
  closeButtonIcon: {
    width: 30,
    height: 30,
  },
  closeButton: {
    width: 40,
    height: 40,
    padding: 0,
    border: "none",
    right: 18,
    position: "absolute",
    bottom: -4,
    boxShadow: "none",
  },
  list: {
    padding: 0,
    overflow: "auto",
    position: "absolute",
    top: 120,
    left: 24,
    right: 24,
    height: "calc(100% - 180px)",
  },
  listItem: {
    paddingLeft: 75,
  },
  listItemCheckBox: {
    left: 0,
  },
  subheader: {
    padding: 0,
    position: "absolute",
    top: 72,
    left: 24,
    right: 24,
    width: null,
  },
  addButton: {
    position: "absolute",
    bottom: 14,
    left: 24,
    width: "calc(100% - 48px)",
  },
  avatar: {
    borderRadius: 5,
    left: 30,
    top: "calc(50% - 15px)",
    border: "1px solid rgb(222, 222, 222)",
  },
  singleLine: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

export default connect(AddSubscriptionListDialog.StateToProps, AddSubscriptionListDialog.DispatchToProps)(AddSubscriptionListDialog);
