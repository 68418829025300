/**
 * Created by kimchangduk on 2017-07-10.
 */
import React from "react";
import PropTypes from "prop-types";
import { Strings } from "../constants";
import { Dialog, FontIcon, RaisedButton, TextField } from "material-ui";
import { showSnackbar } from "../actions/globalSnackbar";
import { connect } from "react-redux";
import copy from "copy-to-clipboard";

class ShareDialog extends React.Component {
  static DispatchToProps = (dispatch) => {
    return {
      actions: {
        copiedAlert: () => {
          dispatch(showSnackbar(Strings.CLIPBOARD_COPIED));
        },
      },
    };
  };

  static propTypes = {
    actions: PropTypes.object,
    url: PropTypes.string,
    open: PropTypes.bool,
    onRequestClose: PropTypes.func,
  };

  onClipboardCopy = () => {
    copy(this.getUrl());
    this.props.actions.copiedAlert();
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  };

  getUrl = () => (this.props.url ? decodeURI(this.props.url) : decodeURI(window.location.href));

  render() {
    const url = this.getUrl();
    return (
      <Dialog contentStyle={styles.dialog} open={this.props.open} onRequestClose={this.props.onRequestClose}>
        <TextField fullWidth value={url} />
        <RaisedButton
          fullWidth
          primary
          icon={<FontIcon style={styles.clipboardIcon} className="fas fa-clipboard" />}
          label="COPY"
          onClick={this.onClipboardCopy}
        />
      </Dialog>
    );
  }
}

const styles = {
  dialog: {
    maxWidth: 600,
  },
  clipboardIcon: {
    fontSize: 17,
  },
};

export default connect(undefined, ShareDialog.DispatchToProps)(ShareDialog);
