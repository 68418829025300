/**
 * Created by kimchangduk on 2017-05-14.
 */

import {Urls} from '../constants';
import {logout} from './user';
import {getAppHistory} from '../Utils';

export function processHttpError(dispatch, error) {
    if (error && error.response && error.response.status === 401) {
        logout(Urls.LOGIN)(dispatch);
    }
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(error);
}