/**
 * Created by kimchangduk on 2017-04-30.
 */

import React from "react";
import Radium from "radium";
import axios from "axios";
import { connect } from "react-redux";
import { Consts, Strings, Urls } from "../constants";
import { PropTypes } from "prop-types";

import TextField from "../components/TextField";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import Toggle from "material-ui/Toggle";
import IconButton from "material-ui/IconButton";
import DeleteIcon from "material-ui/svg-icons/action/delete-forever";
import SaveIcon from "material-ui/svg-icons/file/file-upload";
import PasswordIcon from "material-ui/svg-icons/communication/vpn-key";
import LoaderPage from "./LoaderPage";
import { getUser, editUser, resendEmailVerification } from "../actions/user";
import { showSnackbar } from "../actions/globalSnackbar";
import ErrorMessage from "../components/ErrorMessage";
import { moveLoginPage } from "../Utils";
import OAuthAuthorizer from "../components/OAuthAuthorizer";
import ImageInput from "../components/ImageInput";
import { Divider } from "material-ui";
import queryString from "query-string";

class ProfilePage extends React.Component {
  static StateToProps = (state, ownProps) => {
    return {
      isLogin: state.user.state.isLogin,
      userState: state.user.state,
      userDataSource: state.user.dataSource,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getUser: () => {
          dispatch(getUser());
        },
        editUser: (pathParams, formdata) => {
          dispatch(editUser(pathParams, formdata));
        },
        showSnackbar: (text) => {
          dispatch(showSnackbar(text));
        },
        resendEmailVerification: () => {
          dispatch(resendEmailVerification());
        },
      },
    };
  };

  static propTypes = {
    actions: PropTypes.object,
    isLogin: PropTypes.bool,
    userState: PropTypes.object,
    userDataSource: PropTypes.object,
  };

  state = {
    profileFile: null,
    profileFileUrl: null,
    username: "",
    email: "",
    verifyPassword: "",
    verifyPasswordError: "",
    emailNotify: false,
    newPassword: "",
    snackbarOpen: false,
    snackbarText: "",
    changePasswordRequest: Consts.REQUEST_NONE,
  };

  componentDidMount() {
    this.props.actions.getUser();
  }

  componentWillReceiveProps(nextProps) {
    const editUserStateChangedToSuccess = Consts.hasChangedRequestWaitingToSuccess(this.props.userState.editRequest, nextProps.userState.editRequest);
    const editUserStateChangedToFailure = Consts.hasChangedRequestWaitingToFailure(this.props.userState.editRequest, nextProps.userState.editRequest);
    const isUserStateChangedToSuccess = Consts.hasChangedRequestWaitingToSuccess(this.props.userState.getRequest, nextProps.userState.getRequest);
    const isUserStateChangedToFailure = Consts.hasChangedRequestWaitingToFailure(this.props.userState.getRequest, nextProps.userState.getRequest);
    if (isUserStateChangedToSuccess || editUserStateChangedToSuccess) {
      const nextUser = nextProps.userDataSource;
      this.initializeStates(nextUser);
      if (editUserStateChangedToSuccess) {
        this.showSnackbar(Strings.SUCCESS_TO_UPDATED);
      }
    } else if (editUserStateChangedToFailure) {
      this.showSnackbar(nextProps.userState.editErrorMessage);
    }

    if (isUserStateChangedToFailure) {
      moveLoginPage();
    }
  }

  initializeStates(userData) {
    this.setState({
      profileFile: null,
      profileFileUrl: null,
      username: userData && userData.username ? userData.username : "",
      nickname: userData && userData.nickname ? userData.nickname : "",
      email: userData && userData.email ? userData.email : "",
      emailNotify: userData ? userData.emailNotify : false,
    });
  }

  onDeleteProfileClick = () => {
    this.setState({ profileFile: null, profileFileUrl: null });
  };

  onProfileInputChange = (file, url) => {
    this.setState({ profileFile: file, profileFileUrl: url });
  };

  onTextInputChange = (e, newVal) => {
    const name = e.target.name;
    const newState = {
      [e.target.name]: newVal,
    };

    switch (name) {
      case "newPassword":
        newState.varifyPasswordError = "";
        break;
    }

    this.setState(newState);
  };

  getTextInputChangeFunc = (name) => (e, newVal) => {
    this.setState({ [name]: newVal });
  };

  onVerifyPasswordFocus = () => {
    this.setState({ verifyPasswordError: "" });
  };

  onVerifyPasswordBlur = () => {
    this.setState({ verifyPasswordError: this.state.newPassword !== this.state.verifyPassword ? Strings.NOT_EQUALS : "" });
  };

  onEditClick = () => {
    if (!this.state.username) {
      this.showSnackbar(Strings.USERNAME_IS_EMPTY);
      return;
    }

    if (!this.state.username) {
      this.showSnackbar(Strings.EMAIL_IS_EMPTY);
      return;
    }

    const editParam = {};
    let formData = null;
    let edited = false;
    const currentUser = this.props.userDataSource;
    const currentUserNickname = currentUser ? currentUser.nickname : "";

    if (this.state.nickname !== currentUserNickname) {
      editParam.nickname = this.state.nickname;
      edited = true;
    }

    if (this.state.profileFile) {
      formData = new FormData();
      formData.append("profileImage", this.state.profileFile);
      edited = true;
    }

    if (edited) {
      this.props.actions.editUser(editParam, formData);
    } else {
      this.showSnackbar(Strings.NOT_CHANGED);
      return;
    }
  };

  onChangePasswordClick = () => {
    if (!this.state.password) {
      this.showSnackbar(Strings.PASSWORD_IS_EMPTY);
    } else if (!this.state.newPassword) {
      this.showSnackbar(Strings.NEW_PASSWORD_IS_EMPTY);
    } else if (this.state.newPassword !== this.state.verifyPassword) {
      this.showSnackbar(Strings.NOT_EQUALS_NEW_PASSWORD);
    } else {
      this.setState({ changePasswordRequest: Consts.REQUEST_WAITING });
      axios
        .put(`${Urls.EDIT_USER_API}?${queryString.stringify({ oldPassword: this.state.password, newPassword: this.state.newPassword })}`)
        .then((response) => {
          this.setState({ changePasswordRequest: Consts.REQUEST_SUCCESS });
          this.showSnackbar(Strings.SUCCESS_TO_PASSWORD_EDIT);
        })
        .catch((error) => {
          this.setState({ changePasswordRequest: Consts.REQUEST_FAILURE });
          this.showSnackbar(Strings.FAIL_TO_PASSWORD_EDIT);
        });
    }
  };

  onToggle = (e) => {
    const checked = this.state[e.target.name];
    this.setState({
      [e.target.name]: !checked,
    });
  };

  showSnackbar(text) {
    this.props.actions.showSnackbar(text);
  }

  onResendEmailClick = () => {
    this.props.actions.resendEmailVerification();
  };

  render() {
    if (this.props.userState.getRequest === Consts.REQUEST_WAITING || this.props.userState.getRequest === Consts.REQUEST_NONE) {
      return <LoaderPage />;
    }

    const user = this.props.userDataSource;
    if (!user) {
      return <ErrorMessage errorMessage={Strings.FAIL_TO_LOAD_USER} />;
    }

    const showLoadingPage = this.props.userState.editRequest === Consts.REQUEST_WAITING || this.state.changePasswordRequest === Consts.REQUEST_WAITING;
    return (
      <div className="default-page" style={styles.container}>
        <div>
          <Paper style={styles.paper} zDepth={1} className="fade-in">
            <div style={styles.profileContainer}>
              <ImageInput defaultImage={user.profileImage} image={this.state.profileFileUrl} onChange={this.onProfileInputChange} />
              {this.state.profileFileUrl ? (
                <IconButton onClick={this.onDeleteProfileClick} iconStyle={styles.deleteIcon} style={styles.deleteButton}>
                  <DeleteIcon />
                </IconButton>
              ) : undefined}
            </div>
            <div>
              <TextField
                fullWidth
                floatingLabelText={Strings.USERNAME}
                value={this.state.username}
                onChange={this.getTextInputChangeFunc("username")}
                disabled
              />
              <TextField fullWidth floatingLabelText={Strings.NICKNAME} value={this.state.nickname} onChange={this.getTextInputChangeFunc("nickname")} />
              <TextField
                fullWidth
                floatingLabelText={Strings.EMAIL}
                type="email"
                value={this.state.email}
                onChange={this.getTextInputChangeFunc("email")}
                disabled
              />
              <Divider />
              <RaisedButton label={Strings.EDIT} fullWidth primary icon={<SaveIcon />} onClick={this.onEditClick} />
            </div>
          </Paper>
          <Paper style={styles.paper} zDepth={1} className="fade-in">
            <div>
              <TextField
                fullWidth
                floatingLabelText={Strings.CURRENT_PASSWORD}
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.onTextInputChange}
              />
              <TextField
                fullWidth
                floatingLabelText={Strings.NEW_PASSWORD}
                name="newPassword"
                type="password"
                value={this.state.newPassword}
                onChange={this.onTextInputChange}
              />
              <TextField
                fullWidth
                floatingLabelText={Strings.VERIFY_PASSWORD}
                name="verifyPassword"
                type="password"
                value={this.state.verifyPassword}
                onChange={this.onTextInputChange}
                errorText={this.state.verifyPasswordError}
                onFocus={this.onVerifyPasswordFocus}
                onBlur={this.onVerifyPasswordBlur}
              />
              <RaisedButton label={Strings.PASSWORD_CHANGE} fullWidth primary icon={<PasswordIcon />} onClick={this.onChangePasswordClick} />
            </div>
          </Paper>
        </div>
        {showLoadingPage ? <LoaderPage /> : undefined}
      </div>
    );
  }
}

const styles = {
  container: {
    textAlign: "center",
  },
  paper: {
    marginBottom: 10,
    marginTop: 10,
    padding: 10,
    overflow: "hidden",
    wordBreak: "break-word",
  },
  profileContainer: {
    position: "relative",
    display: "inline-block",
    margin: "auto",
  },
  deleteIcon: {
    width: 25,
    height: 25,
  },
  deleteButton: {
    position: "absolute",
    right: 5,
    top: 5,
    width: 32,
    height: 32,
    padding: 0,
    background: "#FFFFFF",
    border: "1px solid rgba(0,0,0,0.4)",
    borderRadius: "100%",
  },
  toggleBlock: {
    marginTop: 10,
    marginBottom: 10,
    width: 230,
    textAlign: "left",
  },
  membershipData: {
    textAlign: "left",
    overflow: "auto",
  },
  membershipDataKey: {
    width: 190,
    display: "inline-block",
    float: "left",
    margin: "8px 0",
  },
  membershipDataValue: {
    float: "left",
    userSelect: "none",
  },
};

export default connect(ProfilePage.StateToProps, ProfilePage.DispatchToProps)(Radium(ProfilePage));
