import update from "immutability-helper";
import { ActionTypes, Consts } from "../constants";

export const initialState = {
  mySubscribes: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: null,
  },
  subscribeRequest: {
    state: {
      request: Consts.REQUEST_NONE,
      urlKey: -1,
      notifyMinute: null,
      subscribing: true, // true: 구독 신청, false: 구독 취소 신청,
      error: null,
      errorMessage: "",
    },
  },
  keywordRequest: {
    state: {
      request: Consts.REQUEST_NONE,
      urlKey: null,
      keyword: null,
      error: null,
      errorMessage: "",
    },
  },
  editSubscriptions: {
    state: {
      request: Consts.REQUEST_NONE,
      error: null,
      errorMessage: null,
    },
  },
};

export default function subscribeReducer(state = initialState, action) {
  switch (action.type) {
    //<editor-fold desc="Subscribe List">
    case ActionTypes.GET_SUBSCRIBE_LIST_REQUEST:
      return update(state, {
        mySubscribes: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.GET_SUBSCRIBE_LIST_SUCCESS:
      return update(state, {
        mySubscribes: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $set: action.data },
        },
      });

    case ActionTypes.GET_SUBSCRIBE_LIST_FAILURE:
      return update(state, {
        mySubscribes: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
          dataSource: { $set: null },
        },
      });
    //</editor-fold>

    //<editor-fold desc="구독 / 구독 취소 request">
    case ActionTypes.SUBSCRIBE_CHANGE_REQUEST:
      return update(state, {
        subscribeRequest: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            subscribing: { $set: action.subscribing },
            urlKey: { $set: action.urlKey },
            notifyMinute: { $set: action.notifyMinute },
            error: { $set: null },
            errorMessage: { $set: null },
          },
        },
      });

    case ActionTypes.SUBSCRIBE_CHANGE_SUCCESS: {
      if (
        state.subscribeRequest.state.urlKey !== action.urlKey ||
        state.subscribeRequest.state.subscribing !== action.subscribing ||
        state.subscribeRequest.state.notifyMinute !== action.notifyMinute
      ) {
        return state;
      }
      const updateValues = {
        subscribeRequest: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
        },
      };

      // mySubscribes에 프로젝트 추가
      if (state.mySubscribes.dataSource) {
        const prevSubscribeIndex = state.mySubscribes.dataSource.findIndex((a) => a.project && a.project.urlKey === action.urlKey);
        if (action.subscribing) {
          if (prevSubscribeIndex < 0) {
            updateValues.mySubscribes = {
              dataSource: { $push: [action.data] },
            };
          } else {
            updateValues.mySubscribes = {
              dataSource: { $splice: [[prevSubscribeIndex, 1, action.data]] },
            };
          }
        } else {
          if (prevSubscribeIndex >= 0) {
            updateValues.mySubscribes = {
              dataSource: { $splice: [[prevSubscribeIndex, 1]] },
            };
          }
        }
      }

      return update(state, updateValues);
    }

    case ActionTypes.SUBSCRIBE_CHANGE_FAILURE:
      if (
        state.subscribeRequest.state.urlKey !== action.urlKey ||
        state.subscribeRequest.state.subscribe !== action.subscribing ||
        state.subscribeRequest.state.notifyMinute !== action.notifyMinute
      ) {
        return state;
      }

      return update(state, {
        subscribeRequest: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            error: { $set: action.error },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="구독 순서 수정">
    case ActionTypes.EDIT_SUBSCRIPTION_ORDER_REQUEST:
      return update(state, {
        editSubscriptions: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            error: { $set: null },
            errorMessage: { $set: null },
          },
        },
      });

    case ActionTypes.EDIT_SUBSCRIPTION_ORDER_SUCCESS:
      return update(state, {
        editSubscriptions: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
        },
      });

    case ActionTypes.EDIT_SUBSCRIPTION_ORDER_FAILURE:
      return update(state, {
        editSubscriptions: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            error: { $set: action.error },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="키워드">
    case ActionTypes.DELETE_NOTIFY_KEYWORD_REQUEST:
    case ActionTypes.ADD_NOTIFY_KEYWORD_REQUEST:
      return update(state, {
        keywordRequest: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            urlKey: { $set: action.urlKey },
            keyword: { $set: action.keyword },
            error: { $set: null },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.DELETE_NOTIFY_KEYWORD_FAILURE:
    case ActionTypes.ADD_NOTIFY_KEYWORD_FAILURE:
      if (state.keywordRequest.state.urlKey !== action.urlKey && state.keywordRequest.state.keyword !== action.keyword) {
        return state;
      }

      return update(state, {
        keywordRequest: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            error: { $set: action.error },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });

    case ActionTypes.ADD_NOTIFY_KEYWORD_SUCCESS: {
      if (state.keywordRequest.state.urlKey !== action.urlKey || state.keywordRequest.state.keyword !== action.keyword) {
        return state;
      }

      const updateValues = {
        keywordRequest: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
        },
      };

      if (state.mySubscribes.dataSource) {
        const updateIndex = state.mySubscribes.dataSource.findIndex((a) => a.project.urlKey === action.urlKey);
        if (updateIndex >= 0) {
          updateValues.mySubscribes = {
            dataSource: {
              $splice: [[updateIndex, 1, action.data]],
            },
          };
        }
      }

      return update(state, updateValues);
    }

    case ActionTypes.DELETE_NOTIFY_KEYWORD_SUCCESS: {
      if (state.keywordRequest.state.urlKey !== action.urlKey || state.keywordRequest.state.keyword !== action.keyword) {
        return state;
      }

      const updateValues = {
        keywordRequest: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
        },
      };

      if (state.mySubscribes.dataSource) {
        const updateIndex = state.mySubscribes.dataSource.findIndex((a) => a.project.urlKey === action.urlKey);
        if (updateIndex >= 0) {
          if (state.mySubscribes.dataSource[updateIndex].keywords) {
            const keywordIndex = state.mySubscribes.dataSource[updateIndex].keywords.findIndex((a) => a.keyword === action.keyword);
            updateValues.mySubscribes = {
              dataSource: {
                $splice: [
                  [
                    updateIndex,
                    1,
                    update(state.mySubscribes.dataSource[updateIndex], {
                      keywords: { $splice: [[keywordIndex, 1]] },
                    }),
                  ],
                ],
              },
            };
          }
        }
      }

      return update(state, updateValues);
    }
    //</editor-fold>

    default:
      return state;
  }
}
