/**
 * Created by kimchangduk on 2017-06-10.
 */

import React from "react";
import { Consts, Urls } from "../constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import muiThemeable from "material-ui/styles/muiThemeable";
import SearchInput from "./SearchInput";
import { getAppHistory } from "../Utils";

class AppBar extends React.Component {
  static propTypes = {
    openDrawer: PropTypes.func,
    isLogin: PropTypes.bool,
    muiTheme: PropTypes.object,
    onClick: PropTypes.func,
    className: PropTypes.string,
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = { hideSearchInput: this.props.location.pathname === Urls.DISCOVER };
  }

  componentDidMount() {
    const history = getAppHistory();
    this.unlisten = history.listen((location) => {
      this.setState({ hideSearchInput: location.pathname === Urls.DISCOVER });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return (
      <div className={`appbar ${this.props.className ? this.props.className : ""}`} onClick={this.props.onClick}>
        <div>{this.state.hideSearchInput ? undefined : <SearchInput location={this.props.location} />}</div>
      </div>
    );
  }
}

const StateToProps = (state) => {
  return {
    isLogin: state.user.state.isLogin,
    timelineRequest: state.timeline.userTimeline.state.request,
  };
};

export default connect(StateToProps)(muiThemeable()(AppBar));
