import update from "immutability-helper";
import { Consts, ActionTypes } from "../constants";
import { getAppHistory } from "../Utils";

export function openFeedLayer(feeds, index = 0) {
  const history = getAppHistory();
  if (!history.location.state || history.location.state.feedLayerOpen !== true) {
    history.push(
      undefined,
      update(history.location.state ? history.location.state : {}, {
        feedLayerOpen: { $set: true },
      })
    );
  }

  return (dispatch) => {
    dispatch({ type: ActionTypes.OPEN_FEED_LAYER, feeds, index });
  };
}

export function closeFeedLayer() {
  return (dispatch) => {
    const closeDelayTime = 300;
    dispatch({ type: ActionTypes.CLOSE_FEED_LAYER_REQUEST });
    window.setTimeout(() => {
      dispatch({ type: ActionTypes.CLOSE_FEED_LAYER });
    }, closeDelayTime);
  };
}

export function setFeedLayerIndex(index) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_FEED_LAYER_INDEX, index });
  };
}

export function setFeedLayerFeeds(feeds, keepCurrentFeed = false) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_FEED_LAYER_FEEDS, feeds, keepCurrentFeed });
  };
}
