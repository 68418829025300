/**
 * Created by kimchangduk on 2018-02-20.
 */

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { extractContentFromHtml } from "../Utils";
import ImageView from "./ImageView";
import { Consts } from "../constants";
import "./FeedItem.scss";
import { getRegisteredTime } from "../constants/Strings";
import { Link } from "react-router-dom";
import * as Urls from "../constants/Urls";
import { openFeedLayer } from "../actions/feedContentLayer";

class FeedItem extends React.Component {
  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        openFeedLayer: () => {
          if (ownProps.feedLayerDataSource) {
            dispatch(
              openFeedLayer(
                ownProps.feedLayerDataSource,
                ownProps.feedLayerDataSource.findIndex((a) => a === ownProps.dataSource)
              )
            );
          } else {
            dispatch(openFeedLayer([ownProps.dataSource], 0));
          }
        },
      },
    };
  };

  static propTypes = {
    dataSource: PropTypes.object,
    viewMode: PropTypes.string,
    actions: PropTypes.object,
    feedLayerDataSource: PropTypes.array,
  };

  static defaultProps = {
    viewMode: Consts.FeedItemViewTypes.THUMBNAIL,
  };

  onTitleClick = () => {
    if (this.props.dataSource) {
      this.props.actions.openFeedLayer();
    }
  };

  render() {
    const dataSource = this.props.dataSource;
    const publishedDate = dataSource.published ? dataSource.published : dataSource.updated;
    switch (this.props.viewMode) {
      case Consts.FeedItemViewTypes.THUMBNAIL:
        return (
          <div className="feed-item thumbnail">
            <ImageView
              url={dataSource.image}
              width={Consts.IS_MOBILE ? 80 : 100}
              height={Consts.IS_MOBILE ? 80 : 100}
              className="thumbnail"
              onClick={this.onTitleClick}
            />
            <div>
              <div className="title" onClick={this.onTitleClick}>
                {dataSource.title}
              </div>
              <p className="by">
                <Link to={Urls.getProjectFeedPage(dataSource.project.urlKey)}>{dataSource.project.title}</Link>
                {dataSource.author ? ` by ${dataSource.author}` : ""} / <span>{getRegisteredTime(publishedDate)}</span>
              </p>
              <p className="summary" onClick={this.onTitleClick}>
                {extractContentFromHtml(dataSource.content)}
              </p>
            </div>
          </div>
        );

      case Consts.FeedItemViewTypes.TITLE_ONLY:
        if (Consts.IS_MOBILE) {
          return (
            <div className="feed-item mobile title-only">
              <div className="project">
                <Link to={Urls.getProjectFeedPage(dataSource.project.urlKey)}>{dataSource.project.title}</Link>
              </div>
              <div className="date">{getRegisteredTime(publishedDate)}</div>
              <div className="title" onClick={this.onTitleClick}>
                {dataSource.title}{" "}
                {dataSource.author ? <span className="description">{dataSource.content ? extractContentFromHtml(dataSource.content) : ""}</span> : undefined}
              </div>
            </div>
          );
        } else {
          return (
            <div className="feed-item title-only">
              <div className="project">
                <Link to={Urls.getProjectFeedPage(dataSource.project.urlKey)}>{dataSource.project.title}</Link>
              </div>
              <div className="title" onClick={this.onTitleClick}>
                {dataSource.title}{" "}
                {dataSource.author ? <span className="description">{dataSource.content ? extractContentFromHtml(dataSource.content) : ""}</span> : undefined}
              </div>
              <div className="date">{getRegisteredTime(publishedDate)}</div>
            </div>
          );
        }
      default:
        return null;
    }
  }
}

export default connect(undefined, FeedItem.DispatchToProps)(FeedItem);
