import React from 'react';
import PropTypes from 'prop-types';
import {Strings, Consts} from '../constants';
import {capitalizeFirstLetter} from "../Utils";

class RssFieldSelector extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        options: PropTypes.array,
        length: PropTypes.number,
        onChange: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.number,
        error: PropTypes.bool,
        selectedRssFieldIndexes: PropTypes.array
    };

    static defaultProps = {
        error: false
    };

    render() {
        const options = [];
        const value = this.props.value === null || this.props.value === undefined ? "" : this.props.value.toString();
        for (let i = 0; i < this.props.length; i++) {
            if (!this.props.selectedRssFieldIndexes || !this.props.selectedRssFieldIndexes.includes(i) || this.props.value === i) {
                const text = `{%${i + 1}}`;
                options.push(<option style={{color: Consts.FIELD_COLORS[i % Consts.FIELD_COLORS.length]}} key={i} value={i.toString()}>{text}</option>);
            }
        }

        const selectColorStyle = {};
        if (value) {
            selectColorStyle.color = Consts.FIELD_COLORS[this.props.value % Consts.FIELD_COLORS.length];
        }

        return <div className="rss-field-selector">
            <span className="rss-field-name">{capitalizeFirstLetter(this.props.label)}</span>
            <select style={selectColorStyle} className={this.props.error ? "error-box shake" : ""} name={this.props.name} value={value} onChange={this.props.onChange}>
                <option value={""} style={{color: "#000000"}}>{Strings.NONE}</option>
                {options}
            </select>
        </div>;
    }
}

export default RssFieldSelector;