/**
 * Created by kimchangduk on 2017-04-28.
 */

import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";
import ProjectListItem from "./ProjectListItem";

class ProjectList extends React.Component {
  static propTypes = {
    style: PropTypes.object,
    dataSource: PropTypes.array,
    title: PropTypes.string,
  };

  render() {
    return (
      <div style={[styles.container, this.props.style]}>
        {this.props.title ? <p style={styles.title}>{this.props.title}</p> : undefined}
        {this.props.dataSource
          ? this.props.dataSource.map((item, key) => {
              return <ProjectListItem key={key} data={item} style={styles.projectListItem} />;
            })
          : undefined}
      </div>
    );
  }
}

const styles = {
  container: {},
  title: {
    margin: 0,
    padding: 10,
    fontWeight: "bold",
    borderBottom: "1px solid rgb(221, 221, 221)",
    color: "#444",
  },
  projectListItem: {
    // maxWidth: 560
  },
};

export default Radium(ProjectList);
