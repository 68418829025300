import axios from 'axios';
import {ActionTypes, Strings, Consts} from '../constants';
import {callNotificationPermission, getMessageFromResponse} from "../Utils";
import queryString from "query-string";

export function getSubscribeList() {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_SUBSCRIBE_LIST_REQUEST});
        axios.get('/v1/account/subscribes').then(response => {
            dispatch({type: ActionTypes.GET_SUBSCRIBE_LIST_SUCCESS, data: response.data});
        }).catch(error => {
            dispatch({type: ActionTypes.GET_SUBSCRIBE_LIST_FAILURE, errorMessage: getMessageFromResponse(error.message, Strings.FAILED_TO_GET_SUBSCRIBE_LIST)});
        });
    };
}

export function editSubscriptionOrder(subscribeIds, folderIds, orders) {
    return (dispatch) => {
        dispatch({type: ActionTypes.EDIT_SUBSCRIPTION_ORDER_REQUEST});
        axios.put(`/v1/subscribe/order?${queryString.stringify({subscribeIds, folderIds, orders})}`).then(response => {
            getSubscribeList()(dispatch);
            dispatch({
                type: ActionTypes.EDIT_SUBSCRIPTION_ORDER_SUCCESS,
                data: response.data
            });
        }).catch(error => {
            dispatch({
                type: ActionTypes.EDIT_SUBSCRIPTION_ORDER_FAILURE,
                errorMessage: getMessageFromResponse(error.message, Strings.FAILED_TO_EDIT_SUBSCRIPTIONS)
            });
        });
    };
}

export function subscribe(urlKey, notifyMinute = 30, modify = false) {
    callNotificationPermission();
    return (dispatch) => {
        dispatch({type: ActionTypes.SUBSCRIBE_CHANGE_REQUEST, subscribing: true, urlKey, notifyMinute, modify});
        const request = modify ? axios.put('/v1/subscribe/project', {urlKey, notifyMinute}) : axios.post('/v1/subscribe/project', {urlKey, notifyMinute});
        request.then(response => {
            dispatch({type: ActionTypes.SUBSCRIBE_CHANGE_SUCCESS, subscribing: true, urlKey, notifyMinute, data: response.data, modify});
        }).catch(error => {
            dispatch({
                type: ActionTypes.SUBSCRIBE_CHANGE_FAILURE,
                error,
                errorMessage: getMessageFromResponse(error.message, Strings.FAILED_TO_SUBSCRIBE),
                subscribing: true,
                urlKey,
                notifyMinute,
                modify
            });
        });
    };
}

export function unsubscribe(urlKey) {
    return (dispatch) => {
        dispatch({type: ActionTypes.SUBSCRIBE_CHANGE_REQUEST, subscribing: false, urlKey});
        axios.delete('/v1/subscribe/project', {params: {urlKey}}).then(response => {
            dispatch({
                type: ActionTypes.SUBSCRIBE_CHANGE_SUCCESS,
                subscribing: false,
                urlKey
            });
        }).catch(error => {
            dispatch({
                type: ActionTypes.SUBSCRIBE_CHANGE_FAILURE,
                error,
                errorMessage: getMessageFromResponse(error.message, Strings.FAILED_TO_UNSUBSCRIBE),
                subscribing: false,
                urlKey
            });
        });
    };
}

export function addNotifyKeyword(urlKey, keyword) {
    return (dispatch) => {
        dispatch({type: ActionTypes.ADD_NOTIFY_KEYWORD_REQUEST, urlKey, keyword});
        axios.post('/v1/subscribe/project/keyword', {urlKey, keyword}).then(response => {
            dispatch({type: ActionTypes.ADD_NOTIFY_KEYWORD_SUCCESS, urlKey, keyword, data: response.data});
        }).catch(error => {
            dispatch({
                type: ActionTypes.ADD_NOTIFY_KEYWORD_FAILURE,
                urlKey,
                keyword,
                errorMessage: getMessageFromResponse(error.response, Strings.FAILED_TO_ADD_KEYWORD)
            });
        });
    };
}

export function deleteNotifyKeyword(urlKey, keyword) {
    return (dispatch) => {
        dispatch({type: ActionTypes.DELETE_NOTIFY_KEYWORD_REQUEST, urlKey, keyword});
        axios.delete('/v1/subscribe/project/keyword', {params: {urlKey, keyword}}).then(response => {
            dispatch({type: ActionTypes.DELETE_NOTIFY_KEYWORD_SUCCESS, urlKey, keyword});
        }).catch(error => {
            dispatch({
                type: ActionTypes.DELETE_NOTIFY_KEYWORD_FAILURE,
                urlKey,
                keyword,
                error,
                errorMessage: getMessageFromResponse(error.response, Strings.FAILED_TO_REMOVE_KEYWORD)
            });
        });
    };
}