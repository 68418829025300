/**
 * Created by kimchangduk on 2017-04-30.
 */

import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";

import "../loader.scss";

class Loader extends React.Component {
  static propTypes = {
    style: PropTypes.object,
  };

  render() {
    const loaderStyle = {
      display: "inline-block",
      width: "5rem",
      height: "5rem",
    };

    return (
      <div style={[loaderStyle, this.props.style]}>
        <div className="diamonds">
          <div className="diamond diamond-1" />
          <div className="diamond diamond-2" />
          <div className="diamond diamond-4" />
          <div className="diamond diamond-3" />
        </div>
      </div>
    );
  }
}

export default Radium(Loader);
