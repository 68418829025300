import {processHttpError} from './commons';
import axios from 'axios';
import {ActionTypes, Strings, Consts} from '../constants';
import {getMessageFromResponse} from "../Utils";

/**
 * /v1/search
 * @param urlKey
 * @param word
 * @param lastFeedId
 * @returns {Function}
 */
export function search(urlKey = null, word, lastFeedId = undefined) {
    return (dispatch) => {
        dispatch({type: ActionTypes.SEARCH_REQUEST, word, lastFeedId, urlKey});
        const params = {word, id: lastFeedId};
        if (urlKey) {
            params.urlKey = urlKey;
        }
        axios.get('/v1/search', {params}).then(response => {
            dispatch({type: ActionTypes.SEARCH_SUCCESS, word, lastFeedId, urlKey, data: response.data});
        }).catch(error => {
            dispatch({
                type: ActionTypes.SEARCH_FAILURE,
                word,
                lastFeedId,
                urlKey,
                error: error,
                errorMessage: getMessageFromResponse(error.response, Strings.NO_SEARCH_RESULT)
            });
        });
    };
}

/**
 * /v1/search/subscription
 * @param word
 * @param lastFeedId
 * @returns {Function}
 */
export function searchSubscription(word, lastFeedId = undefined) {
    return (dispatch) => {
        dispatch({type: ActionTypes.SEARCH_REQUEST, word, lastFeedId});
        const params = {word, id: lastFeedId};
        axios.get('/v1/search/subscription', {params}).then(response => {
            dispatch({type: ActionTypes.SEARCH_SUCCESS, word, lastFeedId, data: response.data});
        }).catch(error => {
            dispatch({
                type: ActionTypes.SEARCH_FAILURE,
                word,
                lastFeedId,
                error: error,
                errorMessage: getMessageFromResponse(error.response, Strings.NO_SEARCH_RESULT)
            });
        });
    };
}