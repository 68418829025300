/**
 * Created by kimchangduk on 6/8/2018.
 */

import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import { Consts, Urls } from "../constants";
import { Link } from "react-router-dom";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import { MenuItem } from "material-ui";
import ImageView from "./ImageView";
import DragHandle from "./DragHandle";
import DragHandleIcon from "material-ui/svg-icons/editor/drag-handle";

class SubscriptionSortableItem extends React.Component {
  static getThumbnail(image) {
    return <ImageView url={image} width={30} height={30} style={[styles.mobile.thumbnail, image ? styles.mobile.thumbnailBorder : undefined]} />;
  }

  static propTypes = {
    value: PropTypes.object,
    visible: PropTypes.bool,
  };

  render() {
    const item = this.props.value;
    const isNotificationOn = item.notifyMinute !== null;
    return (
      <li className="subscription-sortable" style={this.props.visible ? {} : { height: 0 }}>
        <Link to={Urls.getProjectFeedPage(item.project.urlKey)}>
          {Consts.IS_MOBILE ? (
            <div style={styles.mobile.listItem} className="vertical-align-all-child" tabIndex={0}>
              {SubscriptionSortableItem.getThumbnail(item.project.image)}
              {isNotificationOn ? <i style={styles.mobile.bellIcon} className="fas fa-bell" /> : undefined}
              <div style={styles.mobile.singleline}>{item.project.title}</div>
              <div style={[styles.mobile.description, styles.mobile.singleline]}>{item.project.description}</div>
              <DragHandle>
                <DragHandleIcon style={styles.handle} />
              </DragHandle>
            </div>
          ) : (
            <DragHandle>
              <MenuItem
                style={styles.drawerMenuItem}
                leftIcon={isNotificationOn ? <i style={{ top: -3 }} className="fas fa-bell" /> : undefined}
                innerDivStyle={isNotificationOn ? styles.subscribeMenuItemInnerDivWithIcon : styles.subscribeMenuItemInnerDiv}
              >
                <div style={styles.title}>{item.project.title}</div>
              </MenuItem>
            </DragHandle>
          )}
        </Link>
      </li>
    );
  }
}

const styles = {
  subscribeMenuItemInnerDiv: {
    paddingLeft: 20,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  subscribeMenuItemInnerDivWithIcon: {
    paddingLeft: 40,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  drawerMenuItem: {
    color: "rgba(0, 0, 0, 0.63)",
    lineHeight: "30px",
    minHeight: 30,
    fontSize: 13,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  mobile: {
    singleline: {
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    bellIcon: {
      top: 3,
      position: "relative",
      marginRight: 5,
      float: "left",
    },
    description: {
      fontSize: "0.8em",
      fontWeight: "normal",
      color: "#777",
      marginTop: "0.2em",
    },
    listItem: {
      backgroundColor: "#FFFFFF",
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 30,
      paddingRight: 40,
      fontSize: 16,
      borderBottom: "1px solid #efefef",
      fontWeight: "bold",
      transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
      position: "relative",
    },
    thumbnail: {
      marginLeft: 0,
      marginRight: 10,
      borderRadius: 3,
      float: "left",
    },
    thumbnailBorder: {
      border: "1px solid #dedede",
    },
  },
  handle: {
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translateY(-50%)",
  },
  title: {
    lineHeight: "1.8em",
  },
  description: {
    fontSize: "0.85em",
    lineHeight: "1.1em",
    color: "rgba(0,0,0,0.5)",
  },
};
const RadiumSubscriptionSortableItem = Radium(SubscriptionSortableItem);
export default SortableElement(({ value, visible }) => {
  return <RadiumSubscriptionSortableItem value={value} visible={visible} />;
});
