/**
 * Created by kimchangduk on 2018-04-02.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Consts} from "../constants";
import FeedItemList from "../components/FeedItemList";
import LoaderPage from "./LoaderPage";
import {getFeedsById} from "../actions/feed";
import {hasChangedRequestWaitingToSuccess} from "../constants/Consts";
import {getProjectWithUrlKey} from "../actions/project";
import ProjectDescription from "../components/ProjectDescription";

class NotificationFeeds extends React.Component {
    static StateToProps = (state, ownProps) => {
        return {
            request: state.feed.getFeedsByIds.state.request,
            dataSource: state.feed.getFeedsByIds.dataSource,
            errorMessage: state.feed.getFeedsByIds.state.errorMessage,

            projectDataSource: state.project.project.dataSource,
            projectState: state.project.project.state,
        };
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {
                getFeeds: (ids = undefined) => {
                    if (ids === undefined) {
                        ids = ownProps.match.params.feeds.split(',').filter(a => a).map(a => parseInt(a));
                    }
                    dispatch(getFeedsById(ids));
                },
                getProject: (urlKey = undefined) => {
                    if (urlKey === undefined) {
                        urlKey = decodeURIComponent(ownProps.match.params.urlKey);
                    }
                    dispatch(getProjectWithUrlKey(urlKey));
                }
            }
        };
    };

    static propTypes = {
        actions: PropTypes.object,
        match: PropTypes.object,
        request: PropTypes.string,
        dataSource: PropTypes.array,
        errorMessage: PropTypes.string,

        projectDataSource: PropTypes.object,
        projectState: PropTypes.object
    };

    componentDidMount() {
        this.props.actions.getProject();
        this.props.actions.getFeeds();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.feeds !== nextProps.match.params.feeds) {
            this.props.actions.getFeeds(nextProps.match.params.feeds.split(',').map(a => parseInt(a)));
        }
        if (this.props.match.params.urlKey !== nextProps.match.params.urlKey) {
            this.props.actions.getProject(nextProps.match.params.urlKey);
        }
    }

    render() {
        const urlKey = decodeURIComponent(this.props.match.params.urlKey);

        if (this.props.request === Consts.REQUEST_WAITING ||
            this.props.projectState.request === Consts.REQUEST_WAITING) {
            return <LoaderPage/>;
        }

        return <div style={styles.container}>
            <div className="center-align-page">
                {
                    this.props.projectDataSource && this.props.projectDataSource.urlKey === urlKey ?
                        <ProjectDescription data={this.props.projectDataSource}/> : undefined
                }
                {
                    this.props.request !== Consts.REQUEST_FAILURE ?
                        <div>
                            {
                                this.props.dataSource ?
                                    <FeedItemList onViewModeChangeRequest={this.onViewModeChangeRequest} feeds={this.props.dataSource}/> : undefined
                            }
                        </div> : undefined
                }
                {
                    this.props.request === Consts.REQUEST_FAILURE ?
                        <p className="error-message">{this.props.errorMessage}</p> : undefined
                }
            </div>
        </div>;
    }
}

const styles = {
    container: {
        height: '100%',
        overflowY: 'auto'
    },
    subscribeButton: {
        width: '100%'
    }
};

export default connect(NotificationFeeds.StateToProps, NotificationFeeds.DispatchToProps)(NotificationFeeds);