/**
 * Created by kimchangduk on 2017-06-19.
 */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showSnackbar } from "../actions/globalSnackbar";
import queryString from "query-string";
import { getAppHistory } from "../Utils";
import { Consts } from "../constants";

// https://notihub.net/link?notihub=%2Ftimeline%2F108418&target=http%3A%2F%2Fmarumaru.in%2Fmangaup%2F272498
class RedirectUrl extends React.Component {
  static DispatchToProp = (dispatch) => {
    return {
      actions: {
        alert: (message) => {
          dispatch(showSnackbar(message));
        },
      },
    };
  };

  static propTypes = {
    actions: PropTypes.object,
  };

  componentDidMount() {
    const { target, title } = queryString.parse(getAppHistory().location.search);
    getAppHistory().push("/", { target, title, [Consts.STATE_KEY_ALLOW_DOWNLOAD_POPUP]: false });
  }

  render() {
    return <div />;
  }
}

export default connect(null, RedirectUrl.DispatchToProp)(RedirectUrl);
